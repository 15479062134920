.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 380px;
  height: 100%;
  width: 100%;
  padding: 30px 30px 40px;

  &--clickable {
    cursor: pointer;
  }

  &__shade-overlay,
  &__background-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    &--shaded {
      background: $mainBannerOverlay no-repeat center;
    }
  }

  &__background-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -2;
  }

  &__title {
    font-weight: 800;
    font-size: 44px;
    line-height: 53px;
    color: $default;
    margin: 0 0 8px 50%;
    width: 40%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__text {
    margin-left: 50%;
    color: $default90;
    font-weight: 500;
    font-size: 18px;
    width: 40%;
    line-height: 28px;
  }

  .btn {
    margin-top: 32px;

    &__label {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
    }
  }

  @include maxWidth($M) {
    max-height: 230px;
  }

  @include maxWidth($S) {
    padding: 35px 15px 70px;
    height: 100%;
    max-height: 230px;

    &__title {
      font-size: 22px;
      line-height: 22px;
      margin: 0 0 6px;
      width: 75%;
    }

    &__text {
      margin-left: 0;
      font-size: 15px;
      line-height: 21px;
      width: 75%;
    }
  }
}
