.text-link {
  font-size: 15px;
  line-height: 21px;
  color: $secondary;
  cursor: pointer;
  display: inline-block;

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 0;
    background: transparent;
  }
  &:hover {
    &:after {
      background: $secondary;
      width: 100%;
      transition: all 0.2s linear;
    }
  }

  &__before-label {
    margin-right: 5px;
    vertical-align: middle;
  }

  &.gap-on-top {
    margin-top: 20px;
  }
}
