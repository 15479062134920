.sport-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 164px;
  background: $default;
  border: 1px solid $grey50;
  border-radius: $border-radius;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $grey20;
  }

  &__img {
    height: 46px;
    width: auto;
    margin-top: 8px;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
  }

  &:hover &__img {
    height: 36px;
  }

  &__name {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    height: 0;
    margin-top: 10px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $darkBlue;
    background: $default;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: $border-radius;
  }

  &:hover &__button {
    height: 32px;
    opacity: 1;
  }
}
