.cashbox-bonus-section {
  &__title {
    margin: 0 0 6px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $default;
  }
  &__content-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(223px, 1fr));
    grid-gap: 6px;
    justify-items: center;
  }
  &__slider-wrap {
    margin: 10px 0 20px
  }
  .slick-slide {
    > div {
      margin: 0 5px;
    }
  }
  .radio {
    margin-top: 10px;
    padding: 7px;
    border: 1px dashed $secondaryLight;
    border-radius: 17px;
    width: 100%;
  }
}
