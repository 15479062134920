.slider {
  &__item-wrap {
    display: inline-block;
    width: 100%;

    > * {
      margin: 0 auto;
    }
  }
}

.slick-list,
.slick-track,
.slick-slide > div {
  height: 100%;
  *:focus {
    outline: none;
  }
}

ul.slick-dots {
  bottom: 6px;

  li {
    width: auto;
    height: auto;
    padding: 3px;
    margin: 0;

    a {
      position: relative;
      bottom: 0;
      overflow: visible;
      width: 148px;
      height: 5px;
      border-radius: 5px;
      background-color: rgba($default, 0.5);
      overflow: hidden;

      @include maxWidth($M) {
        width: 40px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $primary;
      }

      .slider-loader {
        display: none;
      }
    }

    &.slick-active a {
      background-color: $default;

      &::before {
        width: 100%;
        transition: width 7s linear;
      }
    }
  }
}

.placeholder-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $grey50;
  text-align: center;

  &__title {
    color: $darkBlue;
  }

  @include maxWidth($S) {
    &__title {
      font-size: 24px;
      line-height: 29px;
    }
  }
}
