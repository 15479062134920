.amount-input {
  display: flex;
  flex-direction: column;

  &--top {
    .button-group {
      order: 0;
      margin-bottom: 10px;
    }
  }

  &--bottom {
    .button-group {
      order: 1;
      margin-top: 10px;
    }
  }

  .custom-input {
    &__input-field {
      text-align: center;
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      padding-top: 10px;
      @include maxWidth($M) {
        text-align: left;
      }
    }

    &__currency {
      @include maxWidth($M) {
        left: 0 !important;
        width: 18px;
      }
    }

    &__wrap {
      @include maxWidth($M) {
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $primary;
        &:active, &:hover {
          border-color: $primaryActive;
        }
      }
    }
  }
}