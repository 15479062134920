.footer-menu {
  &__title {
    margin: 0 0 9px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $default50;
    opacity: 0.5;
    text-transform: capitalize;
  }
  &__list {
    display: block;
    column-gap: 50px;
    max-height: 175px;
    width: fit-content;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    display: inline-block;
    width: 100%;
    max-width: 250px;
    &:not(:last-child) {
      margin: 0 0 4px;
    }
    white-space: nowrap;
    a {
      display: block;
      color: $grey50;
      font-size: 15px;
      line-height: 21px;
      font-weight: normal;
      text-decoration: none;
      transition: 250ms;
      &:hover {
        color: $default;
        text-decoration: underline;
        transition: 250ms;
      }
    }
  }
  &__link {
    height: auto;
  }
  .nav-item {
    &:hover {
      &:after {
        display: none;
      }
    }
  }
}