.confirm-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $primary;
  }

  &__desc {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: $secondaryLight;
  }

  &__buttons {
    display: flex;
    margin-top: 32px;
  }

  &__cancel {
    margin-right: 20px;
  }
}
