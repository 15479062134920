.play-tile {
  display: flex;
  flex-direction: column;
  max-width: 317px;
  width: 100%;
  height: 450px;
  padding: 28px 24px;
  color: $default;
  border-radius: 17px;
  cursor: pointer;
  transition: 200ms;
  backface-visibility: hidden;
  text-decoration: none;
  &:hover {
    transform: scale(1.01) perspective(1px);
    transition: 200ms;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur (-0.1px);
  }
  &--dark {
    .play-tile__text, .play-tile__title {
      color: $secondary;
    }
  }
  &__text {
    margin: 0 0 3px;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }
  &__title {
    margin: 0 0 22px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
  }
  &__btn {
    min-width: 108px;
  }
}

@include maxWidth($L) {
  .play-tile {
    max-width: 390px;
    height: 130px;
    padding: 22px 16px;

    &__text {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
    }
    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      margin: 0 0 9px;
    }
  }
}

@include maxWidth($M) {
  .play-tile {
    max-width: 290px;
    height: 130px;
    padding: 22px 16px;
  }
}
