@include modal-custom("deposit-modal") {
  .modal {
    &__overlay {
      z-index: 999;
    }
    &-body {
      margin: 10px 0 0;
      @include maxWidth($M) {
        padding: 0 10px;
      }
    }

    &-title {
      text-align: left;
    }

    &-content {
      padding: 40px 0;
    }

    &__content {
      overflow: initial;
      width: 980px;
    }

    @include maxWidth($M) {
      &-header, &-body {
        padding: 0;
      }
      &__content {
        width: calc(100vw - 8px);
      }
      &-content {
        padding: 20px 15px 20px;
        border-radius: 17px;
      }
    }
  }
  .form {
    &__date-of-birth__label, &__content, &--in-modal__title {
      color: $darkBlue;
    }
  }
  .fillout-profile {
    width: 90%;
    margin: 0 auto;
  }
}
