.cashbox-methods-list {
  display: grid;
  grid-gap: 0;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  background: $grey20;
  padding: 24px 14px 5px;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;

  @include maxWidth($M) {
    padding: 0;
    background: transparent;
  }

  &__title {
    color: $secondary;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__list {
    max-height: 2000px;
    overflow: auto;
    padding-right: 3px;

    @include maxWidth($M) {
      max-height: initial;
    }
  }

  &__item {
    &:first-child {
      .cashbox-method {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

    &:last-child {
      .cashbox-method {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}

.deposit-modal {
  .cashbox-methods-list {
    grid-gap: 0px;
    @include maxWidth($M) {
      grid-gap: 8px;
    }
    &__list {
      max-height: initial;
      /*   max-height: calc(100vh - 185px);*/
    }
  }
}
