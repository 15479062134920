.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto_Condensed, sans-serif;

  &:focus {
    outline: none;
  }
  &:hover {
    label {
      transition: 250ms;
      &:before {
        border-color: $primary50;
        transition: 250ms;
      }
    }
  }

  input {
    position: absolute;
    margin: 0;
    top: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    cursor: pointer;
    outline: none;
    z-index: 1;
  }
  input:checked {
    &:before {
      position: absolute;
      top: 6px;
      left: 4px;
      content: '';
      width: 12px;
      height: 9px;
      background: url("../../../images/icons/checkmark_new.svg");
    }
    ~label {
      color: $primary;
      &:before {
        background: $primary;
        border-color: $primary;
        transition: 250ms;
      }
      &:after {

      }
    }
    &:hover {
      ~label {
        &:before {
          background: $primary50;
          border-color: $primary50;
          transition: 250ms;
        }
      }
    }
  }

  label {
    position: relative;
    margin-left: 20px;
    line-height: 20px;
    padding-left: 12px;
    color: $darkBlue;
    transition: 250ms;
    cursor: pointer;
    &:before {
      box-sizing: border-box;
      position: absolute;
      left: -20px;
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid $checkboxBorder;
      background: $default;
      border-radius: 4px;
      transition: 250ms;
    }
  }

  &--error {
    label {
      &:before {
        border: 1px solid $statusError;
      }
    }
  }
  &--disabled {
    opacity: 0.8;
    label {
      pointer-events: none;
      &:before {
        opacity: 0.7;
      }
    }
  }
}

.custom-checkbox input[disabled] {
  cursor: default;
  &~label, {
    color: rgba($grey, 0.5);
    &:before {
      border-radius: 4px;
      border: 1px solid $grey50;
    }
  }
}
