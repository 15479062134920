.casino-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary60;
  border-radius: 17px;
  overflow: hidden;

  &__categories {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      border-radius: 0;
    }
  }

  &__controls {
    padding-right: 24px;
    display: flex;
    box-shadow: 0 0 10px 10px $secondary60;
    align-self: normal;
    align-items: center;
    z-index: 1;
  }

  @include maxWidth($M) {
    flex-wrap: wrap;
    background-color: transparent;
    border-radius: 0;

    &__categories {
      background-color: $default;
    }

    &__controls {
      box-shadow: none;
      margin: 20px 20px 0;
      padding-right: 0;
    }
  }

  @include maxWidth($S) {
    &__controls {
      margin-left: 13px;
      margin-right: 13px;
    }
  }
}

.casino-controls {
  &__search-input {
    width: 276px;
  }

  &__platforms-toggle {
    display: none;
  }

  @include maxWidth($M) {
    display: flex;
    width: 100%;

    &__search-input {
      width: 100%;
    }

    &__platforms-toggle {
      display: flex;
    }
  }
}

.casino-platforms-toggle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid $secondary;
  border-radius: 12px;
  outline: none;
  cursor: pointer;

  &__icon {
    display: flex;
  }

  &__count {
    position: absolute;
    top: calc(50% - 22px / 2);
    left: calc(50% - 22px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $default;
    background: $secondary;
    border-radius: $border-radius;
  }

  &--with-count &__icon {
    visibility: hidden;
  }
}
