.form {

  &--in-modal {
    display: grid;
    grid-row-gap: 10px;
    grid-auto-columns: 1fr;
    justify-items: center;
    justify-content: center;
  }

  &--in-modal &__submit {
    margin-top: 10px;
  }
}

.form.form--in-modal {
  @include maxWidth($S) {
    grid-auto-columns: 100% ;
  }
}