.casino-page {
  overflow: hidden;
  display: flex;

  &__sidebar {
    width: 224px;
    flex-shrink: 0;
  }

  &__main {
    width: 100%;
    padding: 20px;
    overflow: hidden;
  }

  &__providers {
    margin-top: 10px;
  }

  &__filter {
    display: none;
  }

  &__body {
    margin-top: 20px;
  }

  &__winners {
    display: none;
  }

  @include maxWidth($L) {
    &__winners {
      display: block;
    }
  }

  @include maxWidth($M) {
    padding-top: 0;
    overflow: hidden;

    &__sidebar,
    &__search-input,
    &__providers {
      display: none;
    }

    &__main {
      padding: 0;
    }

    &__body {
      margin-left: 20px;
      padding-right: 20px;
    }

    &__filter {
      display: block;
      margin-top: 0;
    }
  }

  @include maxWidth($S) {
    &__body {
      margin-left: 13px;
      padding-right: 13px;
    }

    .slick-arrow {
      display: none;
    }
  }
}

.casino-slider {
  min-height: 280px;
}

.casino-sliders {
  &__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $secondary;
    &--widget {
      margin-bottom: 2px;
    }
    &--thematic {
      margin-bottom: -20px;
    }
  }

  &__slider {
    margin: 25px 0 20px;
  }

  .casino-games {
    margin-top: -15px;
  }

  @include maxWidth($M) {
    width: calc(100% + 20px);

    &__title {
      &--thematic {
        margin-bottom: 5px;
      }
    }

    &__scroll-container {
      margin: -24px 0 -24px -20px;
      padding: 24px 20px 24px 20px;
      overflow: scroll;
      &--thematic {
        padding: 0;
        margin: 0;
        overflow: visible;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        border-radius: 0;
      }
    }
  }

  @include maxWidth($S) {
    width: calc(100% + 13px);

    &__scroll-container {
      margin: -24px 0 -24px -13px;
      padding: 24px 13px 24px 13px;
      &--thematic {
        padding: 0;
        margin: 0;
      }
    }
  }
}
