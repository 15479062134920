.nav-item {
  color: $secondary;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 25px;
  font-size: 15px;
  line-height: 21px;
  justify-content: center;
  @include svgFill($default);

  &:hover,
  &--link_active {
    .lazy-img {
      svg path {
        fill: $primaryActive;
        transition: 150ms;
      }
    }
    color: $primaryActive;
    transition: 150ms color;
  }

  &--link_active.full-underscore:after,
  &.full-underscore:hover:after {
    width: 100%;
  }

  & + .nav-item {
    margin-left: 26px;
  }

  &--default {
    flex-direction: column;
    width: fit-content;
    height: 72px;
  }

  &--with-icon {
    .nav-item + .nav-item {
      margin-left: 32px;
    }
  }

  &--left {
    flex-direction: row;
    width: fit-content;
    .nav-item__title {
      margin-left: 12px;
    }
  }

  &--selected {
    color: $primary;
    @include svgFill($primary);
  }
}