.casino-search-input {
  position: relative;

  &__input {
    display: block;
    width: 100%;
    padding: 11px 42px 10px;
    border: 1px solid $grey50;
    border-radius: 12px;
    font-size: 15px;
    line-height: 21px;
    outline: none;
    color: $darkBlue;
    background-color: $searchBoxGrey;
    border-color: $grey50;

    &:focus {
      border-color: $secondary;
    }

    &::placeholder {
      color: rgba($darkBlue, 0.8);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    svg {
      fill: $secondary;
    }
  }

  &__clear-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 0;
    border: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    outline: none;
    cursor: pointer;
  }

  &__input:focus {
    border-color: $secondary50;
  }

  &__input:focus ~ &__icon {
    svg {
      fill: $primary50;
    }
  }

  @include maxWidth($M) {
    &__input {
      padding: 9px 42px 8px;
    }
  }
}
