.game-controls {
  display: flex;
  padding: 3px;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;

    &--settings {
      margin-right: auto;
    }
  }

  &__deposit {
    margin: 0 2px;
  }

  @include maxWidth($M) {
    width: 100%;
  }

  &-additional {
    @include maxWidth($M) {
      width: inherit;
    }
  }

}
