.seo-panel {
  width: 100%;
  background: $grey20;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 48px;
    right: 0;
    left: 0;
    height: 25px;
    background: linear-gradient(0deg, #F0F5F8 0%, rgba(240, 245, 248, 0) 100%);
    pointer-events: none;
    transition: 200ms;
  }

  &__separator {
    position: relative;
    top: 38px;
    margin: 0 auto;
    width: 97%;
    max-width: 1326px;
    height: 17px;
    background: url("../../../images/bg/seo-panel-bg.svg") no-repeat center;
  }
  &__scroll-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 93px auto 48px;
    padding: 0;
    max-height: 276px;
    width: 89%;
    max-width: 1222px;
    font-family: $fontPrimary, sans-serif;
    overflow-y: scroll;
  }

  &__content-wrap {
    position: relative;
    margin: 0 8.5%;
    overflow-wrap: break-word;
  }
  &__title {
    margin: 0 0 20px;
    font-size: 26px;
    text-align: center;
    line-height: 31px;
    font-weight: bold;
    color: $secondary;
  }
  &__sub-title {
    margin: 0 0 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
  }
  &__text {
    margin: 0 0 25px;
    padding-right: 16px;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    &:last-child{
      margin: 0;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  ::-webkit-scrollbar-track {
    background: $grey50;
    border-radius: 3px;
  }
}

@include maxWidth($L) {
  .seo-panel {
    &:before {
      top: 70px;
    }
    &__separator {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
    &__scroll-wrap {
      margin-top: 70px;
      width: calc(100% - 40px);
    }
    &__content-wrap {
      margin: 0 20px 0 0;
    }
  }
}

@include maxWidth($M) {
  .seo-panel {
    &:after {
      bottom: 28px;
    }
    &__separator {
      top: 22px;
      margin: 0 16px;
      height: 8px;
      width: calc(100% - 32px);
      background-size: cover;
    }
    &__scroll-wrap {
      width: calc(100% - 32px);
      max-height: 200px;
      margin: 46px auto 28px ;
    }
    &__content-wrap {
      margin: 0 20px 0 0;
    }
    &__wrap {
      margin: 0 7px 0 15px;
    }
    &__text {
      padding-right: 5px;
    }
  }
}

@include maxWidth($S) {
  .seo-panel {
    &__scroll-wrap {
      max-height: 140px;
    }
    &__content-wrap {
      margin: 0 10px 0 0;
    }
    &__title {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin: 0 0 16px;
    }
    &__sub-title {
      font-size: 15px;
      line-height: 21px;
      font-weight: 600;
      margin: 0 0 12px;
    }
    &__text {
      font-size: 13px;
      line-height: 19px;
    }
  }
}
