.slider-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__slider {
    .slick-slider {
      height: 380px;
    }
  }

  &__slider-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // max-width: calc(100% - 336px);
    width: 100%;
    // margin: 0 20px 0 0;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    border-radius: $border-radius;
    -webkit-mask-image: -webkit-radial-gradient($default, $black);
  }

  &__winners {
    overflow: hidden;
    position: relative;
    height: 51px;
    background: $secondary20;

    .ticker {
      width: 100%;
    }
  }

  @include maxWidth($L) {
    &__slider-wrap {
      max-width: 100%;
      margin: 0;
      overflow: auto;
    }

    &__casino-links {
      display: none;
    }
  }

  @include maxWidth($M) {
    margin-bottom: 0;

    .slick-slider {
      height: 230px;
    }
  }
}
