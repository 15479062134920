.expired-tourney {
  max-width: 428px;
  width: 100%;
  padding: 36px 32px 32px;
  background-clip: padding-box;
  background-position: 0, center;
  background-repeat: no-repeat;
  border-radius: 17px;
  box-sizing: border-box;

  &__icon-cont {
    margin: 0 auto 50px;
    text-align: center;
    img {
      margin: 0 auto 17px;
    }
    div {
      color: $default90;
      font-size: 13px;
      line-height: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &__dates {
    margin: 0 0 6px;
    color: $default90;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  &__name {
    color: $default;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
  }
}

@include maxWidth($S) {
  .expired-tourney {
    max-width: 290px;
    padding: 24px 15px;
    &__icon-cont {
      margin: 0 auto 29px;
      img {
        width: 36px;
        margin: 0 auto 11px;
      }
      div {
        font-size: 11px;
        line-height: 13px;
      }
    }
    &__name {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
