.transactions-page {

  &__section {
    margin-bottom: 26px;
  }
  
  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__pending-withdrawal-item {
    margin-bottom: 16px;
  }
}