.progress-bar {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &--default {
    height: 2px;
    background: $grey50;
    border-radius: 4px;
  }

  &--default &__bar {
    background: $primaryBgGradient;
  }

  &--user-level {
    width: 280px;
    height: 8px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      mask-image: url("../../../images/elements/progress-bar.svg");
      background: rgba($grey50, 0.3);
    }
  }

  &--user-level &__bar {
    background: $mainBgGradient;
    mask: url("../../../images/elements/progress-bar.svg");
  }
}