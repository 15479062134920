.game-search-item {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;

  &__img {
    width: 90px;
    height: auto;
    flex-shrink: 0;
    margin-right: 16px;
    border-radius: 45%;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__name {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__desc {
    font-size: 13px;
    line-height: 17px;
    color: $grey;
  }
}
