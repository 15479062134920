.kyc-page {
  &__status {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 17px;
    color: $grey;
  }
}

.kyc-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: flex-start;

  @include maxWidth($L) {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}

.kyc-form {
  &__row {
    width: 100%;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__note {
    padding: 0 20px;
    font-size: 11px;
    line-height: 1.3;
    text-align: center;
    color: $grey;
  }

  @include maxWidth($L) {
    &__submit {
      width: 100%;
      margin-top: 20px;
    }
  }

  @include maxWidth($M) {
    &__row {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
    }
    .radio-group {
      margin-bottom: 16px;
      .radio {
        align-items: center;
        height: 40px;
        margin: 0;
      }
    }
  }
}
