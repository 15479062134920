.btn {
  display: inline-flex;
  appearance: none;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 12px;
  outline: none;
  cursor: pointer;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    font-weight: normal;
  }

  &__loader {
    position: absolute;
    top: calc(50% - 26px / 2);
    left: calc(50% - 26px / 2);
    width: 26px;
    height: 26px;
    animation: rotate 2s linear infinite;
    z-index: 2;

    & .path {
      stroke: hsl(100, 100, 100%);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.is-loading {
    position: relative;
    cursor: default;
  }

  &.is-loading &__icon,
  &.is-loading &__label {
    opacity: 0;
  }
}

.btn-xs {
  padding: 8px 16px;
  font-size: 13px;
}

.btn-sm {
  padding: 9px 15px;
  font-size: 13px;
  line-height: 16px;
  width: fit-content;
}

.btn-md {
  padding: 10px 19px;
  font-size: 15px;
  line-height: 18px;
  width: fit-content;
}

.btn-lg {
  padding: 12px 19px;
  font-size: 15px;
  line-height: 18px;
  width: fit-content;

  @include maxWidth($M) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.btn-block {
  display: flex;
  width: 100%;
}

.btn-round {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

@mixin btn-color-palettes($textColor: $main, $bgColor: $default) {
  color: $textColor;
  background: $bgColor;
  border-color: $bgColor;
  @content;
}

@mixin btn-effects($hoverBg, $hoverBorder, $activeBg, $activeBorder) {
  &:hover {
    background: $hoverBg;
    border-color: $hoverBorder;
  }

  &:active {
    background: $activeBg;
    border-color: $activeBorder;
  }

  @content;
}

.btn-primary,
.btn-action {
  @extend .btn;
  @include btn-color-palettes($default, $primaryBgGradient);
  @include btn-effects($primaryBgGradientHover, $primaryBgGradientHover, $primaryActive, $primaryActive);
  .btn__label {
    font-weight: 700;
  }
  &:disabled:hover {
    background: $primaryBgGradient;
  }

  .loader__animation {
    &:before {
      border-top-color: $secondary;
    }

    &:after {
      border-color: rgba($secondary, 0.4);
    }
  }
}

.btn-primary-transparent {
  @extend .btn;
  @include btn-color-palettes($primary, transparent);
  @include btn-effects($primary, $primary, $primaryHover, $primaryHover);

  &:hover,
  &:active {
    color: $secondary;
    .btn__label {
      font-weight: bold;
    }
  }
}

.btn-secondary {
  @extend .btn;
  @include btn-color-palettes($default, $teal);
  @include btn-effects($secondary, $secondary, $secondary, $dark100);

  &:disabled:hover {
    background: $teal;
    border-color: $teal;
  }
}

.btn-transparent-bordered {
  @extend .btn;
  @include btn-color-palettes($secondary, transparent);
  @include btn-effects($secondary, $secondary, $secondary50, $secondary50);

  border-color: $secondary;
  .btn__label {
    font-weight: 600;
  }

  &:hover, &:active {
    color: $default;
  }
  &:disabled {
    color: $grey;
    border-color: $grey;
    background: transparent;
  }
}

.btn-inverted {
  @extend .btn;
  @include btn-color-palettes($secondary, $secondary10);
  @include btn-effects($secondary20, $secondary20, $secondary20, $secondary50);
  .btn__label {
    font-weight: 600;
  }

  &:disabled {
    color: $grey;
    border-color: $secondary10;
    background: $secondary10;
  }
}

.btn-default {
  font-weight: 600;
  @extend .btn;
  @include btn-color-palettes($darkBlue, $secondary10);
  @include btn-effects($transparentColor, $primary, $transparentColor, $primary);

  &:disabled {
    color: $secondaryLight;
    border-color: $secondaryLight;
    background: transparent;
  }

  &--selected {
    border-color: $primary;
    background: transparent;
    .btn__label {
      font-weight: 600;
    }
  }


  &--round {
    width: 48px;
    height: 48px;
    font-size: 13px;
    border-radius: 24px;
  }
}

.btn-outline {
  @extend .btn;
  @include btn-color-palettes($secondary0, $transparentColor);
  @include btn-effects($transparentColor, $main, $secondary60, $main);
  border-color: $secondary30;

  &:disabled {
    color: $secondaryLight;
    border-color: $secondaryLight;
    background: transparent;
  }

  &--selected {
    border-color: $main;
  }
}

.btn-transparent {
  @extend .btn;
  @include btn-color-palettes($default, $transparentColor);
  border-color: transparent;
}

.btn-play {
  @extend .btn;
  @include btn-color-palettes($default, $default);
  @include btn-effects(
    $grey20,
    transparent,
    $grey20,
    transparent
  );
  padding: 0;

  .btn__icon {
    margin-left: 4px;
  }
}

.btn-dark {
  @extend .btn;
  @include btn-color-palettes($default, $darkBackground);
  @include btn-effects(
    $darkBackground,
    $darkBackground,
    $darkBackground,
    $darkBackground
  );
}

.btn-demo {
  @extend .btn;
  border: none;
  padding: 10px 30px;
  text-transform: capitalize;
  color: $default;
  border-radius: 44px;
  background-color: $demoBgGradient;
  transition: 0.3s background-color;

  @include btn-effects(
    $demoBgGradientHover,
    transparent,
    $demoBgGradientHover,
    transparent
  );
}

.btn-star {
  @extend .btn;
  border: none;
  background: $secondary60Transparent;
}

.btn-logout {
  @extend .btn;
  border: none;
  color: $default;
}

.btn-close-circle {
  position: fixed;
  width: 48px;
  height: 48px;
  top: 16px;
  right: 18px;
  padding: 0;
  border: none;
  border-radius: 12px;
  background-color: $secondary20;
  z-index: 1;
  cursor: pointer;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: url("../../../images/elements/close.svg") no-repeat;
    background-size: cover;
  }
  &:focus,
  &:active {
    outline: none;
  }
  @include maxWidth($M) {
    width: 30px;
    height: 30px;
    &:before {
      width: 12px;
      height: 12px;
    }
  }
}

.btn-back-circle {
  width: 28px;
  height: 28px;
  padding: 0;
  background: transparent;
  border: 1px solid $secondary;

  svg {
    margin-left: -1px;
    fill: transparent;
    stroke: $secondary;
  }
}
