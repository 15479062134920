@include modal-custom("promo-item-modal") {
  .modal__content {
    width: calc(100vw - 20px);
    max-width: 940px;
  }

  .modal-content,
  .modal-header {
    padding: 0;
    margin: 0;
  }

  .modal-body {
    padding: 32px 8px 22px 32px;
    margin: 0;
  }

  @include maxWidth($M) {
    .modal__content {
      width: 100%;
    }
    .modal-body {
      padding: 15px;
    }
  }
}
