.promo-block {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  @include maxWidth($M) {
    grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
    grid-gap: 12px;
  }
}
