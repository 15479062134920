.form {
  &--in-modal {
    grid-auto-columns: 1fr;
    .dropdown {
      width: 100%;
    }
    &__title {
      margin: 0;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      color: $secondary;
      text-align: left;
      width: 100%;
    }
    .btn-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      grid-gap: 20px;
    }
  }
  &__content {
    margin: 6px 0 10px 0;
    max-width: unset;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    width: 100%;
    text-align: left;
  }
  .btn {
    height: 44px;
    margin-top: 10px;
  }
  &__date-of-birth {
    margin-top: 4px;
    width: 100%;
    &__label {
      text-align: left;
      margin-bottom: 6px;
      color: $darkBlue;
      font-weight: 600;
      font-size: 15px;
      display: block;
    }
  }

  &.fillout-profile {
    max-width: 600px;
    margin: 0 auto;

    &--in-payment-access {
      @extend .cashbox-fields-wrap;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}