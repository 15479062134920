.tournament-page {

  > h3 {
    margin: 30px 0 16px;
    color: $default;
  }

  &__navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    padding: 0 0 11px;
    margin: 0 0 20px;
    color: $secondary0;
    &:after {
      position: absolute;
      bottom: 1px;
      height: 0;
      border: 1px solid $secondary60;
      border-right: none;
      border-left: none;
      content: '';
      width: 100%;
    }

    >div {
      @extend .nav-item;
      &:hover:after {
        z-index: 1;
        bottom: -8px;
      }
    }
  }
}

@include maxWidth($S) {
  .tournament-page {
    &__navigation {
      >div {
        &:hover:after {
          width: 100%;
        }
      }
    }
  };
}