@include modal-custom("disable-popup-blocker-modal") {
  .modal {
    &__content {
      max-width: 320px;
    }
  }

  .modal-body {
    margin-top: 0;
  }

  .modal-content {
    padding-bottom: 20px;
  }

  .notification-block {
    &__wrap {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(#525a6c, 0.5);
    }

    &__title {
      margin-bottom: 0;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $secondary;
    }

    &__btn {
    }
  }
}

.disable-popup-blocker {
  &__title {
    margin-bottom: 9px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary;
  }

  &__desc {
    font-size: 13px;
    color: $darkBlue;
    text-align: center;
  }

  &__btn-link {
    @extend .btn;
    @extend .btn-secondary;
    text-decoration: none;
    padding: 10px 20px;
    width: 100%;
    margin-top: 20px;
  }

  &__tabs {
  }

  &__tutorial {
  }

  &__footer {
    display: flex;
    justify-content: center;

    .btn {
      min-width: 90px;
    }
  }
}

.disable-popup-blocker-tabs {
  display: flex;
  justify-content: center;
  margin: 0 auto 26px;
  border-bottom: 1px solid rgba($grey50, 0.15);

  &__item {
    position: relative;
    padding: 10px 0 12px;
    margin-right: 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $secondary;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 3px;
      background: $primaryBgGradient;
      border-radius: 6px;
      visibility: hidden;
    }

    &.is-active {
      color: $primary;

      &:after {
        visibility: visible;
      }
    }
  }
}

.disable-popup-blocker-nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  margin-bottom: 7px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: $primaryShadow;
  border: 1px solid $secondary50;

  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 6px;
    background-image: url("../../../../images/icons/arrow-down.svg");
    transform: rotate(-90deg);
    justify-self: flex-end;
  }

  &__img {
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }

  &__text {
    margin-right: auto;
  }
}

.disable-popup-blocker-tutorial-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__text {
    font-size: 15px;
    line-height: 21px;
    color: $secondary;
  }

  &:last-child {
    align-items: flex-start;
  }

  &:last-child &__img {
    margin-top: 10px;
  }
}
