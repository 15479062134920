.radio {
  display: inline-flex;

  &__input {
    display: none;
  }

  &__box {
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: $default;
    border: 1px solid $radioBoxGrey;
    border-radius: 50%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 12px / 2);
      left: calc(50% - 12px / 2);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $primaryBgGradient;
      opacity: 0;
    }
  }

  &__label {
    margin-left: 12px;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__input:disabled ~ &__label {
    opacity: 0.5;
  }

  &__input:checked ~ &__box {
    border: 2px solid $primary50;
    background: transparent;

    &:before {
      opacity: 1;
    }
  }

  &__input:checked ~ &__label {
    font-weight: 500;
    color: $primary50;
  }

  &.has-error &__box {
    border: 2px solid $statusError;
  }
}

.radio-group {
  &__error {
    color: $statusError;
  }
}
