.game-page {
  display: flex;
  padding: 20px 20px 100px;
  width: 100%;
  height: calc(100vh - 68px); // 68 - header height
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &--with-game-nav {
    padding: 20px 10px 20px 0;
    .game-page {
      &__inner {
        gap: 10px;
        .collapsed-nav-menu {
          flex-shrink: 0;
          &__content {
            overflow: hidden;
          }
          .cashbox-panel {
            &__methods {
              .cashbox-methods-list {
                padding: 15px 15px 5px;
                border-radius: 0;
                &__title {
                  display: none;
                }
                &__item {
                  max-height: calc(100vh - 200px);
                  overflow-x: hidden;
                }
                &__list {
                  max-height: 75vh;
                }
              }
              .cashbox-fields-wrap {
                padding: 24px 15px;
                border: unset;
                max-height: calc(100vh - 270px);
                .cashbox-fields {
                  background: $default;
                  padding: 10px;
                  border-radius: 16px;
                  max-height: inherit;
                  overflow: auto;
                  &__top {
                    display: none;
                  }
                  .cashbox-method-wrap {
                    &:first-child {
                      margin-top: 0;
                    }
                    .limits-info__value {
                      font-size: 11px;
                      white-space: nowrap;
                    }
                    .amount-input {
                      .custom-input {
                        &__input-field {
                          text-align: left;
                        }
                        &__currency {
                          left: 0;
                          width: 18px;
                        }
                        &__wrap {
                          background: transparent;
                          border: none;
                          border-radius: 0;
                          border-bottom: 2px solid $primary;
                          &:active, &:hover {
                            border-color: $primaryActive;
                          }
                        }
                      }
                      .btn-default--round {
                        border-radius: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .game-box {
          width: 100%;
          height: 100%;
          transition: width 1s;
          &__frame {
            height: 100%;
            .game-frame {
              height: inherit;
            }
          }
          .game-frame__iframe {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  @include maxWidth($M) {
    padding: 0;
    height: 100vh;
    width: 100vw;
  }

  &--mob {
    padding: 0;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 999;
    .game-box {
      width: 100%;
      border-radius: 0;

      &__frame {
        height: 100%;

        .game-frame {
          padding: 0;
        }
      }
    }
    .game-header {
      justify-content: flex-end;
    }
    /* 1.25 dpr for retina display in landscape position*/
    @include retinaDisplayLandscape {
      height: calc(100% - 20px);
    }
  }

  &--full-width {
    padding-bottom: 20px;
  }
}

.game-box {
  display: inline-flex;
  flex-direction: column;
  background-color: $darkBlue;
  border-radius: 12px;
  z-index: 1;

  &__header {
    height: 48px;
    margin: 0 10px 0 16px;
  }

  &__frame {
    .game-frame {
      padding: 0 6px 6px 6px;

      &__iframe {
        border-radius: 0 0 6px 6px;
      }
    }

    &--not-logged {
      background: $default;
      padding: 20px;

      .form {
        max-width: 300px;
        margin: auto;
      }
    }
  }
}

@keyframes show-game-mode-mobile {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.game-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    color: $grey50;
  }

  &__mode {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @include maxWidth($M) {
    &__mode {
      position: fixed;
      top: 58px;
      left: 24px;
      right: 24px;
      bottom: auto;
      transform: none;
      z-index: 1;
      animation: 0.2s ease-in-out show-game-mode-mobile;
    }
  }
}

.game-mode {
  display: flex;

  &__item {
    position: relative;
    height: 100%;
    min-width: 130px;
    line-height: 26px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -1px;
      display: none;
      height: 3px;
      border-radius: 4px;
      background: $primary50;

      @include maxWidth($M) {
        content: none;
      }
    }

    &:hover {
      color: $primary;
    }

    &--active {
      color: $primary50;

      &:after {
        display: block;
      }
    }
  }
}

.game-frame {
  display: flex;
  position: relative;

  &__iframe {
    position: relative;
    border: none;
  }

  &--mob {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
  }
}
