.custom-input {
  &__currency {
    font-size: 24px;
    bottom: 10px;
    color: $primary;
  }
  &__placeholder {
    position: absolute;
    top: 16px;
    left: 16px;
    color: $darkBlue;
    font-size: 15px;
    line-height: 21px;
    transition: 200ms;
    pointer-events: none;
  }
  &__container {
    position: relative;
    padding: 0;
    width: 260px;
    background: transparent;
    font-family: Roboto_Condensed, sans-serif;
    transition: 250ms;
    &--has-error {
      transition: 250ms;
      .custom-input__wrap {
        background: rgba($statusError, 0.1);
        border-color: transparent;
        &--input-focused {
          border-color: $statusError;
          box-shadow: 0 0 0 4px rgba($statusError, 0.1);
        }
        &:before,
        &:after {
          background: $statusError;
        }
      }
      .custom-input__placeholder {
        color: $statusError !important;
      }
    }
    &--mobile {
      .custom-input__placeholder {
        top: 18.5px;
      }
      .custom-input__wrap {
        height: 54px;
      }
      .custom-input__input-field {
        &:not(.empty),
        &:focus {
          & + .custom-input__placeholder {
            transform: translateY(-8px);
          }
        }
      }
    }
    &--disabled {
      .custom-input__wrap {
        cursor: not-allowed;
        input {
          cursor: not-allowed;
        }
        opacity: 0.6;
      }
      .custom-input__placeholder {
        // color: $secondary30 !important;
      }
    }
    &--white {
      .custom-input {
        &__wrap {
          background: $default;
        }
        &__placeholder {
          color: rgba($darkBlue, 0.7);
        }
      }
    }

    &--responsive {
      width: 100%;
    }
  }

  &__wrap {
    display: flex;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 0 2px;
    width: 100%;
    background: $grey50;
    border-radius: 8px;
    border: 1px solid $grey50;

    &--input-focused {
      background: $default;
      border: 1px solid $secondary;
      box-shadow: 0 0 0 4px $grey50;
      transition: 200ms;
    }
    &--input-focused {
      background: $default;
      border: 1px solid $secondary;
      box-shadow: 0 0 0 4px $grey50;
      transition: 200ms;
    }
  }
  &__input-field {
    display: block;
    padding: 23.4px 0 7.6px 16px;
    background: transparent;
    flex-grow: 1;
    color: $darkBlue;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    border: none;
    height: 100%;
    transition: 250ms;
    border-radius: 8px 8px 0 0;
    caret-color: $darkBlue !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: transparent;
      transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s, font-size 5000s ease-in-out 0s;
      border-radius: 8px 8px 0 0;
    }
    &:-webkit-autofill::first-line {
      font-size: 15px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:focus {
      outline: none;
      transition: 250ms;
      caret-color: $darkBlue !important;
      & + .custom-input__placeholder {
        color: $teal2;
        transition: 250ms;
      }
    }
    &:not(.empty),
    &:focus {
      & + .custom-input__placeholder {
        transform: translateY(-10px);
        font-size: 11px;
        line-height: 16px;
        transition: 250ms;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-shrink: 0;
    min-width: 30px;
    background: transparent;
    img {
      padding: 0 14px 0 0;
    }
    svg {
      margin-right: 13px;
    }
    &.password {
      cursor: pointer;
    }
    &.warning-icon {
      position: absolute;
      right: 0;
    }
  }
  &__error {
    display: block;
    margin: 5px 10px 0 16px;
    font-size: 13px;
    line-height: 16px;
    color: $statusError;
  }
}
