.expired-tourney-section {
  position: relative;
  margin: 0 auto;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(428px, 1fr));
    grid-gap: 18px;
    justify-items: center;
    margin: 0 0 25px;
  }
  .load-more-btn {
    margin: 0 auto;
  }
}

@include maxWidth($S) {
  .expired-tourney-section {
    padding: 0;
    &__grid {
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      justify-items: center;
      margin: 0 0 20px;
    }
  }
}
