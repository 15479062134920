.button-group {
  text-align: center;

  .btn {
    margin: 0px 2px;
  }
  &__amount {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .btn {
      padding: 10px 4px;
    }
    @include minWidth($M) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 2px;
      .btn {
        border-radius: 0;
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
  &__content-switch-wrap {
    display: flex;
    width: 100%;
    border-radius: 14px;
    background-color: $grey50;
    margin: 16px 0;
    .btn-default {
      width: 100%;
      margin: 2px;
      background-color: $transparentColor;
      border-color: $transparentColor;
      overflow: hidden;
      .btn__label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: inherit;
      }
      &--selected {
        background: $default;
        border-radius: 12px;
        box-shadow: $boxShadowBtnGroup;
        border-color: $transparentColor;
      }
      &:hover {
        border-color: $transparentColor;
      }
    }
  }
}