.table {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey50;
  border-radius: 17px;
}

.table-head {
  display: flex;
  border-radius: $border-radius $border-radius 0 0;
  padding: 11px 20px 12px;
  background-color: $teal2;

  &__column {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: rgba($default, 0.9);
  }

  &__action-space {
    flex-shrink: 0;
    width: 100px;
  }

  @include maxWidth($M) {
    display: none;
  }
}

.table-item {
  background-color: $default;
  border: 1px solid transparent;
  margin-bottom: 1px;

  &:last-child {
    border-radius: 0 0 $border-radius $border-radius;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    min-height: 56px;
    color: $darkBlue;
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__column-name {
    display: none;
    font-size: 13px;
    line-height: 1.3;
    color: $darkBlue;
  }

  &__column-value {
    display: inline-flex;
    font-size: 15px;
    line-height: 21px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__action-button {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    width: 100px;

    &--mobile {
      display: none;
    }
  }

  &__details {
    padding: 18px 20px;
    border-top: 1px solid $secondary20;
  }

  &.is-open {
    background-color: $default;
    border: 1px solid $secondary;
  }

  @include maxWidth($M) {
    margin-bottom: 16px;
    padding: 16px;

    &,
    &:last-child {
      border-radius: $border-radius;
    }

    &__row {
      flex-direction: column;
      min-height: auto;
      padding: 0;
      margin-bottom: -12px;
    }

    &__column {
      margin-bottom: 12px;
    }

    &__column-name {
      display: block;
    }

    &__action-button {
      display: flex;
      width: 100%;

      .btn {
        margin-top: 16px;
      }

      &--desktop {
        display: none;
      }
    }

    &__details {
      padding: 0;
      margin-top: 12px;
      border: none;
    }
  }
}

.table-details {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -14px;
  padding: 0;

  &__item {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin-right: 35px;
    margin-bottom: 14px;
  }

  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__value {
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__empty {
    list-style: none;
    color: $darkBlue;
    right: 0;
    left: 0;
    margin: 10px auto 20px;
  }


  @include maxWidth($M) {
    flex-direction: column;
  }
}

.play-history-table {
  .table-head__column,
  .table-item__column {
    &:nth-child(1) {
      width: 120%;
    }

    @include maxWidth($M) {
      &:nth-child(1) {
        width: 100%;
      }
    }
  }

  .lost-status {
    color: $statusError;
  }
}

.transactions-history-table {
  .table-head__column,
  .table-item__column {
    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 80%;
    }

    &:nth-child(4) {
      width: 120%;
    }

    &:nth-child(5) {
      width: 90%;
    }

    &:nth-child(6) {
      width: 80%;
      font-weight: 600;
    }

    @include maxWidth($M) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: 100%;
      }
    }
  }
}
