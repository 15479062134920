.cryptopay-widget {
  display: inline-flex;
  align-items: center;
  
  &__logo {
    margin-right: 7px;
  }

  &__bitcoin-img {
    margin-right: 5px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }
  
  &__text-item {
    font-weight: bold;
    font-size: 6px;
    line-height: 8px;
    white-space: nowrap;
    color: #5B6376;
  }
}