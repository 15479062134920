.tournament-info-page {

  .tournament-info-banner {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin: 0 -20px 0 -20px;
    padding: 33px 40px 30px;

    height: 363px;
    background-repeat: no-repeat;
    background-position: center;
    color: $default;

    &__live {
      position: absolute;
      top: 33px;
      right: 40px;
      width: 65px;
      height: 25px;
      padding: 4px 10px 4px 25px;
      border-radius: 23px;
      background: $secondary60;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 17px;
      font-weight: 600;
      &:before {
        position: absolute;
        top: 6px;
        left: 6px;
        display: block;
        content: "";
        width: 13px; height: 13px;
        background: $liveRed;
        border-radius: 50%;
      }
    }
    &__title-wrap {
      max-width: 400px;
    }
    &__type {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      margin: 0 0 8px;
    }
    &__name {
      font-weight: 800;
      font-size: 44px;
      line-height: 53px;
    }
  }

  &__main-section {
    display: flex;
    align-items: center;
    padding: 30px 0;
  }
  &__left-content {
    margin: 0 15px 0 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__back-btn {
    @extend .btn-transparent;
    position: relative;
    margin: 0 0 8px;
    color: $primary;
    text-transform: capitalize;
    width: 80px;
    height: 43px;
    font-size: 15px;
    line-height: 21px;
    &:before {
      position: absolute;
      display: block;
      content: '';
      left: 7px;
      width: 6px;
      height: 12px;
      background: url("../../images/icons/yellow-arrow.svg") no-repeat center;
    }
    &--low-res {
      display: none;
    }
  }
  &__description {
    margin: 0 0 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $secondaryLight;
  }

  .rules-and-conditions {
    &__title {
      margin: 0 0 15px;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      color: $default;
    }
  }
  &__right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    align-self: flex-start;
    max-width: 394px;
  }
  &__timer-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 24px;
    &--low-res {
      display: none;
    }
  }
  &__timer-title {
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    color: $default;
    width: 100%;
    text-transform: capitalize;
  }
  &__how-to-play {
    @extend .btn-transparent-bordered;
    width: 118px;
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
  }
  .countdown {
    margin-right: 15px;
  }
}

@include maxWidth($M) {
  .tournament-info-page {
    .tournament-info-banner {
      &__name {
        font-size: 32px;
        line-height: 36px;
      }
      &__type {
        font-size: 17px;
        line-height: 20px;
        margin: 0 0 6px;
      }
    }

    &__main-section {
      flex-direction: column;
      justify-content: center;
      padding: 20px 0;
    }
    &__right-content {
      max-width: unset;
      width: 100%;
      align-items: center;
    }
    &__back-btn {
      display: none;
      &--low-res {
        display: inline-flex;
        margin: 15px 0 8px;
        width: 76px;
        height: 40px;
      }
    }
    &__description {
      margin: 0 0 16px;
    }
    &__timer-wrap {
      display: none;
      &--low-res {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 16px;
      }
    }
    .rules-and-conditions {
      margin: 0 0 16px;
    }
  }
}

@include maxWidth($S) {
  .tournament-info-page {
    .tournament-info-banner {
      margin: 0;
      padding: 10px 15px 24px;
      border-radius: 17px;
      height: 230px;

      &__live {
        top: 10px;
        right: 7px;
      }
      &__name {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
      }
      &__type {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        margin: 0 0 6px;
      }
    }

    &__description {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
    }
    &__timer-title {
      margin: 0 0 11px;
    }
    &__timer-wrap--low-res {
      justify-content: center;
      flex-direction: column;
      .countdown {
        margin: 0 0 16px;
        align-self: flex-start;
      }
    }
    &__how-to-play {
      width: 100%;
      max-width: 240px;
    }
  }
}


@include maxWidth(360px) {
  .tournament-info-page {
    &__how-to-play {
      width: 100%;
      max-width: unset;
    }
  }
}
