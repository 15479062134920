.user-profile {
  display: flex;
  min-height: calc(100vh - 68px);

  .user-tabs {
    margin: 20px 0 0 20px;
    padding: 32px 52px;
    border-radius: 17px 17px 0 0;
    width: 100%;
    height: inherit;
    background: $default;

    .tab-title {
      color: $secondary;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;

      @include maxWidth($M) {
       font-size: 18px;
      }
    }
    .tab__nav-bar-wrap {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .nav-bar.tabs-navigation {
    width: fit-content;
    overflow-y: visible;
  }

  @include maxWidth($XXL) {
    margin-left: -20px;
  }

  @include maxWidth($L) {
    .user-tabs {
      padding: 20px;
    }
  }

  @include maxWidth($M) {
    display: block;
    margin: 0;
    .user-tabs {
      margin: 0;
      padding: 27px 8px 38px;
      border-radius: 0;
      background: $greyEC;

      .nav-bar.tabs-navigation {
        margin: 12px 0 21px;
        border-bottom: 2px solid $grey50;
        overflow-y: visible;
        .nav-item {
          justify-content: flex-start;
          height: 31px;
        }
        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
      }
    }

    .user-navigation-mobile {
      display: block;
    }
  }
}
