.live-games-page {
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  &__nav {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  &__body {
    display: flex;
    border-radius: $border-radius;
    overflow: hidden;
  }

  .game-page {
    height: calc(100vh - 160px);
    padding: 0;
  }

  .game-frame {
    padding: 0;
  }

  @include maxWidth($M) {
    margin: 10px 0 0;

    &__body {
      border-radius: 0;
    }

    &__nav {
      margin-bottom: 10px;
    }

    .game-frame {
      padding: 0;

      &__iframe {
        border-radius: 0;
      }
    }
  }
}

.bet-games {
  display: flex;
  width: 100%;

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.kiron-live-games-page,
.tvbet-live-games-page,
.bet-games-live-games-page {
  width: 100%;
}
