.providers-section {
  margin: 48px auto 0;
  max-width: 1323px;

  &__title {
    margin: 0 0 21px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: $secondary;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
    grid-gap: 9px;
  }

  .provider-btn {
    height: 80px;
    width: 100%;
    background: $default;
    border-radius: $border-radius;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $teal2;
    cursor: default;
    transition: 150ms border;

    img {
      max-width: 150px;
    }
  }
  .show-more-btn {
    cursor: pointer;
  }

  @include maxWidth($S) {
    &__title {
      padding: 0 32px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
    &__content {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      grid-gap: 8px;
    }
    .provider-btn {
      height: 62px;
      font-size: 13px;
      line-height: 17px;

      img {
        max-width: 100px;
      }
    }
  }
}