.game-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1108px;
  transition: transform 0.4s cubic-bezier(0, 0.48, 0.65, 1);
  transform: translateY(calc(100% - 56px));

  &__header {
    display: flex;
    height: 56px;
  }

  &-body {
    padding: 20px 0;
    overflow: hidden;
  }

  &__body {
    background-color: $grey20;
  }

  &--open {
    transform: translateY(0%);
  }

  .slick-slider {
    padding: 0 40px;
    .slick-list {
      overflow: visible;
    }
    .slick-arrow {
      top: -8px;
      width: 80px;
      height: 180px;
      @include bigSliderArrows();
    }
    .slick-prev {
      left: 0;
      border-radius: 0 17px 17px 0;
    }
    .slick-next {
      right: 0;
      border-radius: 17px 0 0 17px;
      &:before {
        transform: rotate(180deg);
      }
      &:hover {
        &:before {
          transform: rotate(180deg) scale(1.1);
        }
      }
    }
  }
}

.game-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  background-color: $default;
  border-radius: $border-radius $border-radius 0 0;

  &__tabs {
    margin: 0 auto 0 22px;
    padding: 0;
  }

  &__search {
    position: relative;
    justify-self: flex-end;
  }

  &__search-input {
    width: 290px;
  }

  &__search-result {
    position: absolute;
    top: -8px;
    left: calc(50% - 320px / 2); // 320px - GameSearchResult width
    transform: translateY(-100%);
  }

  &__time {
    margin: 0 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    text-align: right;
    color: $darkBlue;
  }
}

