.winners-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__img-link {
    display: flex;
  }

  &__img {
    flex-shrink: 0;
    width: 56px;
    height: auto;
    margin-right: 14px;
    border-radius: 6px;
    filter: drop-shadow(2px 2px 0px rgba(36, 91, 102, 0.2));
  }

  &__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 1.3;
    color: $darkBlue;
    * {
      word-break: break-all;
    }
  }

  &__player {
    display: inline;
  }

  &__amount {
    font-weight: bold;
  }

  &__link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    color: $darkBlue;

    &:hover {
      color: $darkBlue;
    }
  }

  .winners--line & {
    margin-bottom: 0;
  }

  .winners--line &__player {
    display: inline;
  }
}
