$border-radius: 12px;
$simple-select-custom-bg: $grey50;

.simple-select {
  position: relative;

  &.full-width {
    width: 100%;
  }

  .dropdown-arrow {
    position: absolute;
    right: 20px;
    top: 22px;
    transition: 250ms;
  }
  .flipped {
    transition: 250ms;
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  input[value] {
    font-size: 0;
  }

  &__wrapper.is-open,
  &__wrapper:hover {
      .simple-select__input {
        background: $default;
        border: 1px solid $secondary;
        outline: none;
        box-shadow: 0 0 0 4px $boxShadowDropdownFocused;
        transition: 100ms border;
      }
  }

  label {
    cursor: pointer;
    color: $darkBlue;
    text-transform: capitalize;
    top: 25px;
    left: 15px;
    position: absolute;
  }

  &.no-placeholder {
    label {
      top: 17px;
    }
  }

  &-options {
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: $border-radius;
    background: white;
    cursor: pointer;
    padding: 20px 15px;
    box-shadow: 0 12px 30px rgba(28, 51, 56, .1);
    z-index: 1;
    
    &.relative-menu {
      position: relative;
    }

    &.top {
      transform: translateY(-160px);
    }

    &__wrap {
      overflow-y: auto;
      overflow-x: hidden;
      height: fit-content;
      max-height: 160px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .simple-option {
        border-radius: $border-radius;
        &.current-selected {
          background-color: $secondary20;
        }
        &__label {
          display: block;
          padding: 8px 10px;
        }
        &.with-img {
          position: relative;
          .simple-option__label {
            display: block;
            text-align: center;
            line-height: 35px;
            padding: 0;
          }
          .simple-select__icon-img {
            position: absolute;
            top: 8px;
            left: 15px;
            pointer-events: none;
          }
        }
        &:hover {
          background-color: $secondary20Transparent;
        }
      }
    }
  }

  &__input {
    padding: 0 0 0 1rem;
    width: 100%;
    top: 16px;
    font-size: 15px;
    height: 52px;
    background: $default;
    color: $darkBlue;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;

    &.custom-bg {
      background-color: $simple-select-custom-bg;
    }
  }

  &__placeholder {
    position: absolute;
    font-size: 11px;
    top: 10px;
    left: 15px;
    transition: 250ms;

    &.centered {
      margin: 0;
      padding: 0;
      position: absolute;
      font-size: 16px;
      transform: translate(5%, 30%);
      transition: 250ms;
    }
  }

  &__error {
    font-size: 13px;
    line-height: 16px;
    color: $statusError;
    padding: 4.5px 0 0 16px;
  }

  &.lang-select-container {
    grid-area: bot;
    width: 180px;
    justify-self: center;
    align-items: center;

    &.full-width {
      width: 100%;
    }

    .simple-select {
      &__input {
        height: 40px;
      }
    }

    .dropdown-arrow {
      top: 18px;
    }

    label {
      top: 10px;
      font-weight: 600;
      gap: 10px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
}
