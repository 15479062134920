.casino-category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0 10px;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 42px / 2);
    bottom: 0;
    display: none;
    width: 42px;
    height: 3px;
    border-radius: 4px;
    background: $primaryBgGradient;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    height: 32px;
    margin-bottom: 4px;
  }

  &__icon-img {
    display: block;
  }

  &__icon-img-active {
    display: none;
  }

  &__icon-count {
    position: absolute;
    top: calc(50% - 30px / 2);
    left: calc(50% - 32px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBackground;
    background: $primaryBgGradient;
    border-radius: $border-radius;
  }

  &__arrow {
    display: flex;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% + 20px);
    transition: 0.2s ease-in;

    svg {
      stroke: $secondaryLight;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $darkBlue;
    text-align: center;
    white-space: nowrap;
  }

  &--active:after {
    display: block;
  }

  &--providers:after {
    display: none;
  }

  &--active &__arrow {
    transform: rotate(-180deg);

    svg {
      stroke: $primary;
    }
  }

  &:hover &__icon-img,
  &--active &__icon-img {
    display: none;
  }

  &:hover &__icon-img-active,
  &--active &__icon-img-active {
    display: block;
  }

  &:hover &__name,
  &--active &__name {
    color: $primaryActive;
  }

  &--with-count &__icon-img,
  &--with-count &__icon-img-active {
    visibility: hidden;
  }

  @include maxWidth($M) {
    &--providers {
      display: none;
    }
  }
}
