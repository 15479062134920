@mixin dividerDottedLine($orientation) {
  border-#{$orientation}: 2px dotted $secondary50;
}

@mixin modalFooterArrow() {
  position: absolute;
  top: 6px;
  right: 10px;
  content: "";
  width: 6px;
  height: 10px;
  background: url("../../images/elements/modal-arror-right.svg") no-repeat center;
}

@mixin bigSliderArrows() {
  background: $gameSliderBtnBG;
  backdrop-filter: blur(14px);
  transform: none;
  opacity: 1;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 69px;
    left: 19px;
    width: 42px;
    height: 42px;
    background: url("../../images/icons/game-slider-button.svg") no-repeat center;
    transition: 200ms;
  }
  &:hover {
    transform: none;
    &:before {
      transform: scale(1.1);
      transition: 250ms scale;
    }
  }
  &.slick-disabled, &.slick-next, &.slick-prev {
    &:before {
      opacity: 1;
    }
  }
  &.slick-disabled {
    display: none;
  }
}
