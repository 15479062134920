.sidebar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  overflow: hidden;
  z-index: 9999;
  transform: translate3d(0,0,0);
  transition-delay: 0.5s;
  &.visible {
    width: 100%;
    transition-delay: 0s;
    .sidebar__overlay {
      display: inline-block;
      &.dimmed {
        background: $sidebarOverlay;
        transition: background 0.5s;
        opacity: 0.4;
      }
    }
    .sidebar__content {
      transform: translate3d(0,0,0) !important;
      transition: 0.5s;
    }
  }
  &.sidebarRight {
    .sidebar__content {
      right: 0;
      transform: translate3d(100%,0,0);
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: -1;
    transition: transform .5s ease,-webkit-transform .5s ease;
  }
  &__content {
    position: absolute;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px 35px;
    height: 100%;
    width: 320px;
    background: $default;
    color: $darkBlue;
    transform: translate3d(-100%,0,0);
    transition: transform .5s ease,-webkit-transform .5s ease;
    box-sizing: border-box;

    .get-app-btn {
      &__additional {
        display: flex;
        background: $secondary10;
        border-radius: 17px;
        padding: 8px 14px;
      }
      width: 100%;
      margin: 21px 0 0;
      padding: 16px 0;
      border-left: 0;
      border-right: 0;
      border-top: 1px solid $grey50;
      border-bottom: 1px solid $grey50;
      border-radius: 0;
      .label {
        color: $secondary;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        margin-left: 20px;
      }
      .additionalLabel {
        color: $darkBlue;
        display: block;
        font-size: 13px;
        width: 100%;
        line-height: 18px;
        font-weight: normal;
        margin-left: 20px;
      }
    }

    .close-sidebar {
      right: unset;
      top: 12px;
      left: 12px;
      padding: 10px;
      @extend .btn-close-circle;
    }

    .navigation-links {
      list-style-type: none;
      margin: 62px 0 12px;
      width: 100%;
      padding: 0 8px;

      a {
        cursor: pointer;
        text-decoration: unset;
        .nav-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 21px;
          color: $darkBlue;

          &__icon {
            display: flex;
            img {
              width: 24px;
              height: 24px;
            }
          }
          &__label {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            margin-left: 20px;
          }
        }
      }
    }

    .auth-section {
      position: relative;
      width: 100%;
      padding-bottom: 24px;
      margin: 12px 0 16px;
      &:after {
        position: absolute;
        display: block;
        content: "";
        bottom: 0;
        left: 8px;
        width: calc(100% - 16px);
        height: 0;
        border-top: 1px solid $grey50;
      }

      .btn {
        width: 100%;
        padding: 10px 0;
        font-size: 15px;
        margin-top: 12px;
        font-weight: bold;

        &:first-child {
          margin-top: 0;
        }

        &__label {
          font-weight: bold;
        }

      }
    }

    .lang-select-container {
      margin: 0 0 27px;
      padding: 0 10px;
      background: transparent;
      label {
        margin-left: 15px;
      }
      .simple-select-options {
        width: calc(100% + 30px);
        transform: translateX(-15px);
        position: relative;
        background: $grey20;
        border-radius: $border-radius;
        .simple-option {
          font-weight: normal;
          font-size: 15px;
          line-height: 21px;
          color: $darkBlue;
          margin: 5px 0;
          &.current-selected {
            background: transparent;
          }
        }
      }
    }

    &-winners {
      @extend .casino-sidebar__winners;
      &.winners-block {
        width: 100%;
        padding: 0 0 0 5px;
        align-self: flex-start;
      }
      .winners__title {
        margin: 0 0 10px;
      }

    }

    .sidebar-bottom-links {
      list-style-type: none;
      width: 100%;
      padding: 0 20px;
      a {
        text-decoration: unset;
        .nav-link {
          font-size: 15px;
          margin-top: 18px;
          color: $secondaryLight;
        }
      }
    }
  }
}
