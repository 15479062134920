.account-progress-info {
  margin-bottom: 20px;
  &__completed {
    font-size: 13px;
    margin: 15px 0 25px;
    color: $secondaryLight;
    &-value {
      color: $default;
      margin-right: 5px;
    }
    &__to-next-level {
      float: right;
    }
  }
  .progress-bar--user-level {
    margin: 15px auto;

    @include rangeWidth(411px, $M) {
      transform: scale(1.3);
    }
    @include maxWidth(410px) {
      transform: scale(1.25);
    }
    @include maxWidth(375px) {
      transform: scale(1.15);
    }
    @include maxWidth(360px) {
      transform: scale(1.1);
    }
    @include maxWidth(320px) {
      transform: scale(1);
    }
  }
  .level-container {
    display: flex;
    &__text-block {
      overflow: hidden;
      padding: 10px 15px;
      .account-progress-info__nickname,
      .account-progress-info__level {
        display: block;
      }
      .account-progress-info__level {
        color: $grey;
        font-size: 15px;
        line-height: 21px;
        white-space: nowrap;
        &-name {
          color: $darkBlue;
          font-weight: 600;
          margin-left: 5px;
        }
      }
      .account-progress-info__nickname {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $secondary;
        margin-bottom: 2px;
      }
    }
    .to-next-level {
      margin: 6px 0 0;
      &__text {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: $grey;
        &--accented {
          color: $secondary;
        }
      }
    }
  }
  .total-balance {
    padding: 0;
    margin: 0;
    flex-grow: 2;
    color: $secondaryLight;
    &__label {
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      display: block;
      @include maxWidth($M) {
        text-align: left;
      }
    }
    &__value {
      display: block;
      text-align: center;
      font-weight: bold;
      margin: 5px 0 13px;
      font-size: 26px;
      line-height: 31px;
      color: $primary;
    }
    &__deposit-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px;
      border-radius: 12px;
      width: 100%;
    }
    &__other-methods {
      margin: 13px 0 0;
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
    }
    .btn-action {
      width: 100%;
      height: 44px;
    }
    &__recent-method {
      margin: 0 0 0 30px;
      width: 100%;
      height: 50px;
      color: $grey;
      font-size: 11px;
      line-height: 14px;
    }
    @include maxWidth($M) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-left: unset;
      margin: 0;
      padding: 0;
      &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 0 8px;

        .total-balance__value {
          margin: 5px 0 0 0;
          font-size: 20px;
          line-height: 24px;
          @include maxWidth(360px) {
            max-width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .total-balance__label {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
  &__bonus-request {
    padding: 9px 3px;
    .btn {
      width: 100%;
    }
  }
}
