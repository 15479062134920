.countdown {
  &__display {
    position: relative;
    display: inline-block;
    width: 48px; height: 40px;
    border-radius: 17px;
    background: $default;
    color: $black;
    font-size: 15px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    &--days {
      margin-right: 8px;
    }
    &--hours, &--minutes {
      margin-right: 20px;
      &:before, &:after {
        position: absolute;
        content: "";
        width: 4px; height: 4px;
        background: $default;
        border-radius: 100%;
        right: -12px;
      }
      &:before {
        top: 14px;
      }
      &:after {
        bottom: 14px;
      }
    }
  }
}