.prizes {
  max-width: 394px;
  width: 100%;
  &--modal {
    margin: 0 0 25px;
    .prizes__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
    }
  }

  &__title {
    color: $default;
    margin: 0 0 11px;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
  }
  &__podium {
    display: flex;
    align-items: flex-end;
    .prizebox {
      margin: 0 0 38px;
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
        width: 58px; height: 58px;
        color: $dark100;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        border-radius: 17px;
        filter: drop-shadow(4.2px 4.2px 0px rgba(255, 255, 255, 0.18));
      }
      &__text {
        max-width: 78%;
        margin: 0 auto;
        color: rgba($secondaryLight, 0.9);
        font-size: 13px;
        line-height: 17px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
  &__first, &__second, &__third {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: 121px;
    &:not(:last-child) {
      margin: 0 15px 0 0;
    }
  }
  .podium-step {
    position: relative;
    width: 100%;
    background: linear-gradient($secondary30, $secondary);;
    color: $secondary;
    font-size: 35px;
    line-height: 42px;
    font-weight: 800;
    text-align: center;
    border-radius: 5px;
    &:before {
      display: block;
      position: absolute;
      top: -14px;
      left: 0;
      content: '';
      width: 100%;
      height: 22px;
      background: $secondary60;
      border-radius: 5px;
    }
  }
  &__first {
    .prizebox {
      &__icon {
        background: $primary;
      }
    }
    .podium-step {
      height: 111px;
      padding: 34.5px 0 0;
    }
  }
  &__second {
    .prizebox {
      &__icon {
        background: $lightPurple;
      }
    }
    .podium-step {
      height: 82px;
      padding: 22px 0 0;
    }
  }
  &__third {
    .prizebox {
      &__icon {
        background: $fadedOrange;
      }
    }
    .podium-step {
      height: 58px;
      padding: 11px 0 0;
    }
  }
}

@include maxWidth($S) {
  .prizes {
    max-width: 296px;
    &__podium {
      .prizebox {
        &__icon {
          margin: 0 auto 7px;
          width: 43px; height: 43px;
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
    &__first, &__second, &__third {
      max-width: 90.5px;
      &:not(:last-child) {
        margin: 0 11px 0 0;
      }
    }
    .podium-step {
      font-size: 26px;
      line-height: 32px;
      &:before {
        background: $dark100;
      }
    }
  }
}
