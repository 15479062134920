.Toastify {

  &__toast {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 21px;
    color: $default;
    
    &--success {
      background: $mainBgGradient;
      
      &:before {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin: 0 12px 0 6px;
        background-image: url("../../../images/icons/check.svg");
      }
    }

    &--error {
      background: $statusError;
    }
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: rgba($secondary, 0.5);
    border-radius: 50%;

    svg {
      width: 14px;
      height: 14px;
      transform: translateX(4%);
    }
  }

  &__progress-bar {
    height: 3px;
  }
}