.pending-withdrawal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 20px;
  border: 1px solid $grey50;
  border-radius: $border-radius;

  &__items {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item {
    position: relative;
    padding-right: 7%;
    margin-right: 7%;
    border-right: 1px solid $grey50;

    &:last-child {
      padding-right: 0;
      border: none;
    }
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    color: $grey;
  }

  &__value {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;

    .user-balance__value {
      font-weight: bold;
      color: $primaryActive;
    }
  }

  &__item--id {
    flex-basis: 250px;
  }

  &__item--sum {
    flex-basis: 130px;
    &__value {
      font-weight: bold;
      color: $primary;
    }
  }

  &__item--status &__value {
    margin-left: -1px;
    .status-label__text {
      text-transform: capitalize;
    }
  }

  &__action-button {
    flex-shrink: 0;
    width: 100%;
    max-width: 100px;
    .btn__label {
      text-transform: capitalize;
    }
  }

  @include maxWidth($M) {
    flex-direction: column;
    padding: 15px;
    background-color: $default;
    box-shadow: $boxShadowWhiteCards;
    border: none;

    &__items {
      flex-wrap: wrap;
    }

    &__item {
      width: 50%;
      padding: 0;
      margin: 0 0 10px;
      border: none;

      &--time {
        width: 60%;
        order: 0;
        .pending-withdrawal__label {
          display: none;
        }
      }
      &--status {
        width: 40%;
        order: 1;
        .pending-withdrawal__label {
          display: none;
        }
        .status-label {
          margin: 0 0 0 auto;
        }
      }
      &--id {
        width: 60%;
        order: 2;
      }
      &--sum {
        width: 40%;
        order: 3;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .pending-withdrawal__label {
          display: none;
        }
      }
    }

    &__action-button {
      max-width: none;
      margin-top: 6px;
    }
  }
}