.bonuses-page {
  .button-group {
    &__content-switch-wrap {
      height: 40px;
      width: 360px;
      @include maxWidth($M) {
        width: 100%;
      }
    }
  }
  .bonus-head-block {
    display: inline-block;
    width: 100%;

    .bonus-code {
      .btn {
        margin-top: 0;
      }
    }
    .bonus-code,
    .bonus-exchange {
      float: left;
      height: 117px;
      width: calc(50% - 10px);
      padding: 20px;
      border-radius: 17px;
      background: $secondary20;

      &__label {
        color: $darkBlue;
        font-weight: 500;
        font-size: 15px;
        text-transform: capitalize;
        margin-bottom: 10px;
        display: block;
      }

      &__content-mob {
        color: $grey;
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 16px;
        @include minWidth($M + 1) {
          display: none;
        }
      }

      @include maxWidth($M) {
        width: 100%;
        height: auto;
        margin-top: 16px;
        background: $default;
      }
    }
    .bonus-code,
    .bonus-exchange {
      @include minWidth($M + 1) {
        margin: 20px 10px 0 0;
      }
      .form {
        grid-template-areas: "input btn";
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 20px;
        grid-template-rows: auto;
        align-items: center;
        min-height: 52px;

        @include maxWidth($M) {
          grid-template-areas: "input" "btn";
          grid-column-gap: unset;
          grid-row-gap: 16px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }

        .custom-input {
          &__wrap {
            margin: 0;
            height: 52px;
          }
          &__container {
            grid-area: input;
          }
        }
        .btn {
          grid-area: btn;
          justify-self: end;
          width: 100%;
          max-width: 116px;
          @include maxWidth($M) {
            max-width: unset;
          }
        }
      }
    }
    .loyalty-points {
      @include minWidth($M + 1) {
        margin: 20px 0 0 10px;
      }
      &__block {
        display: inline-block;

        @include maxWidth($M) {
          display: block;
        }

        &-content {
          display: block;
          color: $secondaryLight;
          font-weight: normal;
          font-size: 13px;
          margin: 10px 0 5px;
          @include maxWidth($M) {
            display: inline-block;
            margin: 0;
          }
        }
        &-value {
          display: block;
          color: $primary;
          font-weight: bold;
          font-size: 15px;
          @include maxWidth($M) {
            display: inline-block;
            float: right;
          }
        }
      }
      .btn {
        float: right;
        height: 52px;
        @include maxWidth($M) {
          margin-top: 16px;
          width: 100%;
          height: initial;
        }
      }
    }
  }

  &__active-bonuses-section,
  &__available-bonuses-section,
  &__bonus-history-section {
    margin: 26px 0 12px;

    &-title {
      color: $default;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 12px;
      display: block;
      @include maxWidth($M) {
        color: $darkBlue;
      }
    }

    .active-bonuses-table,
    .available-bonuses-table,
    .bonus-history-table {
      &__empty {
        width: 100%;
        text-align: center;
        border-radius: 0 0 17px 17px;
        padding: 24px 0;
        font-size: 15px;
        color: $darkBlue;
        background: $default;
        @include maxWidth($M) {
          font-weight: 500;
          font-size: 13px;
          border-radius: 17px;
          background: $default;
        }
      }
    }
    @include maxWidth($M) {
      .bonus-history-table {
        margin-bottom: 25px;
      }
    }
  }

  @include maxWidth($M) {
    .bonus-request-history-table.table {
      .table-item {
        &__row {
          grid-gap: 0;
          grid-template-areas:
            "date date date status status"
            "type type type type type"
            "comment comment comment comment comment"
            "action action action action action";
        }
        &__column {
          &:nth-child(1) {
            grid-area: type;
          }
          &:nth-child(2) {
            grid-area: comment;
          }
          &:nth-child(3) {
            grid-area: status;
          }
          &:nth-child(4) {
            grid-area: date;
          }
          &:nth-child(5) {
            grid-area: action;
          }
        }
      }
    }
    .table {
      .table-item {
        &__row {
          flex-direction: unset;
          display: grid;
          grid-gap: 20px;
          align-items: self-start;
          grid-template-areas:
            "bonus bonus bonus bonus bonus bonus"
            "status status status status status status"
            "expire expire expire expire expire expire"
            "amount amount wagering_req wagering_req amount_wagered amount_wagered";
        }
        &__column {
          &:nth-child(1) {
            grid-area: bonus;
          }
          &:nth-child(2) {
            grid-area: status;
          }
          &:nth-child(3) {
            grid-area: expire;
          }
          &:nth-child(4) {
            grid-area: amount;
          }
          &:nth-child(5) {
            grid-area: wagering_req;
          }
          &:nth-child(6) {
            grid-area: amount_wagered;
          }
        }
      }
    }
  }
}

.bonus-exchange {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
  }

  &__info {
    margin-top: 8px;
    font-size: 13px;
    color: $darkBlue;
  }

  &__value {
    display: block;
    margin-top: 4px;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    color: $primaryActive;
  }

  .btn{
    height: 44px;
  }
}
