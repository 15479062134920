@include modal-custom("forgot-password-modal") {
  .modal-body {
    margin: 0;
  }
  .form {
    justify-items: left;
    &__title {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: $secondary;
    }
    &__desc {
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: 15px;
      line-height: 21px;
      text-align: left;
      color: $darkBlue;
    }
  }
  .forgot-password-modal__successful-title {
    color: $secondary;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }
  .forgot-password-modal__successful-content {
    text-align: center;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }
  .forgot-password-modal__successful-title {
    text-align: center;
  }

  @include maxWidth($M) {
    .modal__content {
      margin: 0 auto;
      width: calc(100% - 16px);
      max-width: 400px;
    }

    .modal-content {
      height: unset;
      padding: 32px 0;
      border-radius: 17px;
    }
  }
}
