.info-block {
  padding: 20px 0;
}
.info-wrap {
  max-width: 1014px;
  margin: 0 auto;
  text-align: left;

  &__title {
    margin: 30px 0;
    color: $default;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    text-align: center;
  }
  &__paragraph {
    margin: 0 0 15px;
    color: $secondaryLight;
    font-size: 15px;
    line-height: 21px;
    font-weight: normal;
  }
  strong {
    display: inline;
    font-weight: 600;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
