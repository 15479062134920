.history-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: $secondary20;
  border-radius: $border-radius;

  &__header {
    display: none;
    align-items: center;
  }

  &__icon {
    display: flex;
    margin-right: 10px;
  }

  &__title {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    margin-left: auto;
    border: none;
    border-radius: 0;
    outline: none;
    transition: 0.2s ease-in;
  }

  &__body {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__items {
    display: flex;
    width: 100%;
  }

  &__apply {
    flex-shrink: 0;
    min-width: 110px;
  }

  &.is-open &__arrow {
    transform: rotate(-180deg);
    path {
      stroke: $secondary;
    }
  }

  @include maxWidth($M) {
    background-color: $default;
    flex-direction: column;
    align-items: stretch;
    padding-top: 0;

    &__header {
      display: flex;
      padding: 20px;
      margin: 0 -20px -20px;
    }

    &__body {
      flex-direction: column;
      align-items: stretch;
      margin-top: 20px;
    }

    &__items {
      flex-direction: column;
    }

    &__apply {
      margin-top: 10px;
    }

    &:not(.is-open) &__body {
      display: none;
    }
  }
}

.history-filter-item {
  width: 100%;
  max-width: 200px;
  margin-right: 20px;

  .dropdown__control {
    background: $default;
  }

  @include maxWidth($M) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
