body.modal-is-open, body.sidebar-is-open {
  overflow: hidden;
}

.modal {
  &-wrapper-with-banner-right {
    display: flex;
    height: 100%;
    overflow-y: hidden !important;
    .modal-content {
      width: 30%;
      border-radius: 0;
      min-width: 487px;
    }
  }
  &-wrapper-with-banner-bottom {
    display: flex;
    flex-direction: column;
    height: 100%;
    .modal-content {
      flex-shrink: 0;
    }
  }

  &--with-banner &__overlay {
    padding: 0;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondaryOpacity90;
    overflow: auto;

    &:before,
    &:after {
      content: "";
      flex: 1;
    }
  }

  &__content {
    width: 100%;
    overflow: auto;
    outline: none;
  }

  &__description {
    &__img {
      height: auto;
      width: 100%;
      border-top-right-radius: 17px;
      border-top-left-radius: 17px;
    }
    &__text,
    &__subtext {
      color: $secondaryLight;
      max-height: calc(100vh - 375px);
      overflow: auto;

      @include rangeWidth($S, $M) {
        max-height: calc(90vh - 375px);
      }

      img {
        max-width: 100%;
      }
    }

    &__btn {
      font-size: 15px;
      display: block;
      margin: 0 auto;
    }
  }

  &-default {
    width: 524px;
  }

  &-xlg {
    width: 100%;
  }

  &-lg {
    width: 902px;
  }

  &-md {
    width: 600px;
  }

  &-md {
    width: 600px;
  }

  &-sm {
    width: 423px;
  }

  @include maxWidth($M) {
    &-default,
    &-xlg,
    &-lg,
    &-md,
    &-sm {
      max-width: 600px;
      width: 100%;
    }
  }
  .change-password-modal,
  .fill-out-profile-modal,
  .forgot-password-modal,
  .reset-password-success-modal {
    &__successful {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      .btn {
        width: 35%;
        margin: 20px 0 0;
      }
    }
    &__successful-title {
      color: $secondary;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      @include maxWidth($M) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    &__successful-content {
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
    }
    &__successful-subcontent {
      text-align: center;
      line-height: 21px;
      font-size: 13px;
      font-style: italic;
      color: $secondaryLight;
    }
  }
}

.modal-content {
  position: relative;
  padding: 48px 0;
  border-radius: $border-radius;
  background: $default;
  word-break: break-word;

  &--transparent-bg {
    background: transparent;
  }

  &--with-after-footer {
    padding-bottom: 0;
  }

  &__promo {
    padding: 0 0 16px;
    @include maxWidth($M) {
      width: calc(100% - 30px);
      margin: 0 auto;
    }
    .modal-header {
      padding: 0;
    }
    .modal-body {
      padding: 0;
      margin: 0;
    }
    .modal-header__close {
      @include maxWidth($M) {
        transform: scale(0.65);
        top: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  @include maxWidth($M) {
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 32px 0 24px;
  }
}

.modal-header {
  padding: 0 40px 0;

  &__close {
    @extend .btn-close-circle;
  }

  @include maxWidth($M) {
    padding: 0 16px;
    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}

.modal-body {
  margin: 20px 0 32px;
  padding: 0 40px;

  @include maxWidth($M) {
    padding: 0 16px;
  }
}

.modal-footer {
  display: block;
  padding: 0 40px;

  .modal-link {
    position: relative;
    justify-content: flex-start;
    &:not(:last-child) {
      margin: 0 0 26px;
      &:after {
        position: absolute;
        bottom: -16px;
        margin: 1.5px 0;
        content: '';
        height: 0;
        width: 100%;

        @include dividerDottedLine(top);
      }
    }
    &>span {
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
    }
  }

  .modal-link.forgot-pass-link {
    .text-link {
      font-size: 15px;
      line-height: 20px;
      color: $secondary;
      &:hover:after {
        background: $secondary;
      }
    }
  }

  @include maxWidth($M) {
    margin: auto 0 0 0;
    padding: 0 16px;
  }
}

.modal-after-footer {
  position: relative;
  min-height: 78px;
  margin: 0;
  padding: 37px 30px 26px;
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  background-image: url("../../../../images/bg/after-footer-bg.svg"),
    linear-gradient(
      to bottom,
      transparent,
      transparent 50%,
      $afterFooterGray 50%,
      $afterFooterGray 100%
    );
  background-position: 0 0, center bottom;
  background-size: 100% auto, auto auto;
  background-repeat: no-repeat, no-repeat;
  background-clip: padding-box, padding-box;
  border-radius: 0 0 17px 17px;

  .text-link {
    line-height: 21px;
  }
}

.modal-title {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: $secondary;
}

.modal-desc {
  margin: 11px 0 0;
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  color: $darkBlue;
}

.modal-banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__body {
    position: relative;
    width: 100%;
    max-width: 440px;
    margin-left: 10%;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 800;
    font-size: 44px;
    line-height: 53px;
    color: $default;
  }

  &__desc {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: $default;
    opacity: 0.9;
  }

  &__btn {
    margin-top: 40px;
    padding: 0;
    flex-direction: row-reverse;
    font-weight: 900;
    color: $primary;

    .btn__label {
      font-weight: 700;
    }

    .btn__icon {
      margin-left: 2px;

      svg path {
        stroke: $primary;
      }
    }
  }

  @include maxWidth($M) {
    padding: 30px 15px;
    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
    }
    &__title {
      margin: 0 0 6px 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
    &__desc {
      margin: 0 0 10px 0;
      color: $default90;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }
    &__btn {
      margin: 0;
    }
  }
}

@mixin modal-custom($additionalClassName) {
  .modal.#{$additionalClassName} {
    @content;
  }
}

@mixin mobile-modal-fullscreen {
  .modal__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .modal-content {
    flex: 1 0 auto;
  }
}