.term-cond {
  display: grid;
  width: 100%;
  justify-content: start;
  padding: 11px 0;
  grid-template-columns: auto auto;

  &__error {
    color: $statusError;
    bottom: 0;
    padding-left: 32px;
    font-size: 13px;
    line-height: 17px;
    padding-top: 5px;
  }

  input:checked ~ label {
    color: $darkBlue;
  }

  label {
    color: $darkBlue;
    font-size: 13px;
    line-height: 20px;
  }
  svg {
    width: 20px;
    cursor: pointer;
    height: 15px;
    margin-left: 3px;
    vertical-align: middle;
    &:hover {
      opacity: 0.8;
    }
  }
}