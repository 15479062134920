@include modal-custom("fill-out-profile-modal") {
  .modal {
    &-body {
      margin: 0;
      padding: 0;
    }
    &__content {
      overflow: unset;
      max-width: 423px;
    }
    &-content {
      padding: 40px
    }
    &-footer {
      display: none;
    }

    @include maxWidth($M) {
      &__content {
        width: calc(100vw - 8px);
      }
      &-content {
        padding: 20px 15px 20px;
        border-radius: 17px;
      }
    }
  }

  .fill-out-profile-modal__successful {
    .btn {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}
