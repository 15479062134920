$public-chat-header-height: 40px;

.public-chat {
  position: fixed;
  bottom: 0;
  left: 350px;
  width: 100%;
  max-width: 320px;
  background-color: rgba($default, 0.95);
  border-radius: $border-radius $border-radius 0px 0px;
  box-shadow: 0px 0px 28px rgba(28, 51, 56, 0.2);
  transition: all 0.25s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transform: translateY($public-chat-header-height);
  z-index: 9999;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    height: $public-chat-header-height;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    margin-right: 10px;
  }

  &__title {
    font-size: 13px;
    line-height: 1.3;
    color: $darkBlue;
  }

  &__close,
  &__arrow {
    display: flex;
    margin-left: auto;
  }

  &__arrow {
    display: none;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: $secondary20;
    border-radius: $border-radius;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &__body {
    display: none;
    height: 600px;
  }

  &__iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  &--opened {
    background-color: $default;
    transform: none;
  }

  &--opened &__body {
    display: flex;
  }

  &--opened &__arrow {
    transform: rotate(180deg);
  }

  @include maxWidth($M) {
    max-width: none;
    left: 0;
    z-index: 19;
    padding-bottom: calc(env(safe-area-inset-bottom) + 48px);

    &__header {
    }

    &__close {
      display: none;
    }

    &__arrow {
      display: flex;
    }

    &--shown {
      transform: translateY(0);
    }

    &--opened {
      top: 0;
      padding-bottom: 0;
      border-radius: 0;
      z-index: 9999;
    }

    &--opened &__body {
      height: calc(100% - #{$public-chat-header-height});
    }
  }
}
