.bonus-request {
  &-container {
    display: inline-block;
    height: auto;
    width: calc(50% - 10px);
    margin-top: 20px;
    padding: 20px;
    border-radius: 17px;
    background: $secondary20;
    @include maxWidth($M) {
      width: 100%;
      background-color: $default;
    }
  }
  &-history {
    margin-top: 26px;
    &__title {
      font-size: 15px;
      margin-bottom: 12px;
      font-weight: 500;
    }
    &-table {
      &.table {
        .table-item {
          &__column {
            &-name {
              @include maxWidth($M) {
                display: none;
              }
            }
            &-value {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              .btn {
                width: 100%;
              }
              @include maxWidth($M) {
                .date-cell {
                  display: inline-flex;
                  align-items: center;
                  gap: 4px;
                }
              }
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid $grey50;
          }
        }
        @include maxWidth($M) {
          border: none;
        }
      }
      &__empty {
        width: 100%;
        text-align: center;
        border-radius: 0 0 17px 17px;
        padding: 24px 0;
        font-size: 15px;
        color: #1c4f5b;
        background: #ffffff;
        @include maxWidth($M) {
          font-size: 13px;
          font-weight: 500;
          border-radius: 17px;
          background: #ffffff;
        }
      }
    }
  }
  &__label {
    font-size: 15px;
    color: $darkBlue;
    display: block;
  }
  &__info-message {
    font-size: 13px;
    color: $grey;
    margin-top: 4px;
    display: block;
  }
  &__form {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 16px;
    .dropdown {
      width: 100%;
      @include minWidth($M) {
        .dropdown__control {
          background-color: $default;
        }
      }
    }
    .btn-bonus-request {
      width: 144px;
      .btn__label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    @include maxWidth($M) {
      flex-direction: column;
      .btn-bonus-request {
        width: 100%;
      }
    }
  }
}