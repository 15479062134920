.casino-providers {
  position: relative;
  overflow: hidden;

  &__header {
    display: none;
  }

  &__body {
    padding: 10px 10px 14px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }

  &__more-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 36px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $secondary;
    background: $default;
    border-radius: 12px;
    border: none;
    outline: none;
    box-shadow: -10px 0 10px 5px $grey20;
    cursor: pointer;

    &--hidden {
      display: none;
    }
  }

  &__footer {
    display: none;
  }

  &.casino-providers-desktop {
  }

  &.casino-providers-desktop &__body {
    padding: 0;
  }

  @include maxWidth($M) {
    background-color: $default;
    border-radius: 0;
    padding: 16px;

    &__header {
      display: block;
      background-color: $default;
    }

    &__body {
      position: absolute;
      top: 60px;
      bottom: 120px;
      padding: 10px 0;
      overflow-y: auto;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
    }

    &__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      color: $secondary;
    }

    &__close-button {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 9px;
      border-radius: 50%;
      background-color: rgba($secondary, 0.2);
      border: none;
      z-index: 1;
      outline: none;
      cursor: pointer;

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-image: url("../../../images/elements/close.svg");
        background-position: center;
        background-size: cover;
      }
    }

    &__more-button {
      display: none;
    }

    &__show-button {
      width: 100%;
      margin-bottom: 12px;
    }

    &__select-all-button {
      width: calc(50% - 6px);
      margin-right: 6px;
    }

    &__clear-all-button {
      width: calc(50% - 6px);
      margin-left: 6px;
    }
  }
}

.casino-providers-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  &--more {
    flex-wrap: wrap;
    padding: 0 20px 0 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: 95px;
    max-width: 120px;
    word-break: break-word;
  }

  .casino-providers-desktop & {
    margin-bottom: -10px;
  }

  .casino-providers-desktop &__item {
    flex-shrink: 0;
    width: auto;
    min-width: auto;
    max-width: none;
    margin: 0 10px 10px 0;
  }

  @include maxWidth($M) {
    flex-wrap: wrap;

    &__item {
      width: calc(100% - 16px);
      margin-bottom: 4px;
      max-width: initial;

      &--all,
      &--less {
        display: none;
      }
    }
  }
}
