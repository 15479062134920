.game-slider {
  &__top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
  }

  &__header {
  }

  &__control {
    flex-shrink: 0;
  }

  &__title {
    color: $secondary;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  &__desc {
    margin-top: 4px;
    font-size: 13px;
    line-height: 18px;
    color: $secondaryLight;
  }

  .slick-list {
    overflow: visible;
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
  .slick-arrow {
    @include bigSliderArrows();
    display: block;
    transition: opacity 200ms;
    top: 0;
    width: 80px;
    height: 164px;
    opacity: 0;
    &:before {
      top: 55px;
    }
  }
  .slick-prev {
    left: -20px;
    border-radius: 0 17px 17px 0;
  }
  .slick-next {
    right: -20px;
    border-radius: 17px 0 0 17px;
    &:before {
      transform: rotate(180deg);
    }
    &:hover {
      &:before {
        transform: rotate(180deg) scale(1.1);
      }
    }
  }

  .slick-slide {
    position: relative;
    width: 230px;
    height: 172px;
    margin-right: 8px;
    transition: 0.4s cubic-bezier(0.5, 0, 0.1, 1);

    .game-card {
      position: absolute;
      left: 0;
      right: 0;
      transition: 0.4s cubic-bezier(0.5, 0, 0.1, 1);
    }

    &:hover {
      cursor: pointer;
      width: calc(230px + 21px);

      .game-card {
        transform: translateY(-8px);
      }
    }
  }

  &--big .slick-slide {
    width: 320px;
    height: 282px;
    margin-right: 10px;

    &:hover {
      width: calc(320px);

      .game-card {
        transform: translateY(0px);
      }
    }
  }

  &--big {
    .slick-arrow {
      @include bigSliderArrows();
      display: block;
      transition: opacity 200ms;
      top: 0;
      width: 80px;
      height: 282px;
      opacity: 0;
      &:before {
        top: 120px;
      }
    }
    .slick-next {
      &:before {
        transform: rotate(180deg);
      }
      &:hover {
        &:before {
          transform: rotate(180deg) scale(1.1);
        }
      }
    }
  }

  @include maxWidth($M) {
    .slick-arrow {
      display: none;
    }
    .slick-slide {
      width: 160px;
      height: 120px;

      &:hover {
        width: 160px;

        .game-card {
          transform: translateY(-3px);
        }
      }
    }

    &--big .slick-slide {
      width: calc(160px * 1.2);
      height: calc(190px);
      margin-right: 8px;

      &:hover {
        width: calc(160px * 1.2);

        .game-card {
          transform: none;
        }
      }
    }
  }
}
