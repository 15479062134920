.drop-zone {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  border: 1px dashed $primary50;
  border-radius: 20px;
  color: $darkBlue;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background: $default;

  &__text {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    margin: 0 0 1px;
  }

  &__button {
    color: $secondary;
    text-decoration: underline;
  }

  &__desc {
    margin-top: 2px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    color: $grey;
  }

  &__bold-note {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    margin-top: 16px;
    text-align: center;
  }

  &__note {
    font-size: 11px;
    line-height: 14px;
    color: $grey;
  }

  &__upload-btn {
    display: none;
  }

  &__error {
    margin-top: 2px;
    font-size: 11px;
    line-height: 1.3;
    color: $statusError;
  }

  &__files {
    margin: 10px 0;
    padding: 0;
  }

  &__file-item {
    list-style: none;
    font-size: 13px;
    line-height: 1.3;
  }

  &:hover,
  &:focus {
    border-color: $primary;
  }

  &--default {
    padding: 30px 0;
  }

  &--credit-card {
    padding: 16px 0 30px;
  }

  &--credit-card &__label {
    margin-bottom: 24px;
    padding: 8px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $secondary;
    background-color: rgba($secondary20, 0.5);
  }

  &--credit-card &__note {
    margin-top: 2px;
  }

  &.has-error {
    border: 1px solid $statusError;
  }

  @include maxWidth($M) {
    border-radius: 0;
    &__text {
      display: none;
    }

    &__upload-btn {
      display: block;
    }

    &__label {
      margin-bottom: 20px;
    }

    &__note {
      margin-top: 2px;
    }

    &__files {
      margin: 16px 0 0;
    }

    &--default {
      padding: 0;
      border: 0;

      &.has-error {
        border: none;
      }
    }

    &--default &__upload-btn {
      flex-shrink: 0;
      margin-bottom: 10px;
    }

    &--credit-card &__desc {
      display: none;
    }

    &--credit-card {
      padding: 24px 0 25px;
      border-radius: 20px;
      .drop-zone__upload-btn {
        margin: 0 20px;
      }
    }
  }
}
