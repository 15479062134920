.tooltip {

  &__container {
    padding: 8px 16px;
    background: $darkBlue;
    box-shadow: $secondaryShadow;
    border-radius: $border-radius;
    color: $default;
    font-size: 15px;
    line-height: 21px;
    z-index: 1;
  }

  &__arrow {
    &::before {
      content: "";
      transform: rotate(45deg);
      background: $darkBlue;
      border-radius: 3px;
      transition: transform 0.2s ease-out, visibility 0.2s ease-out;
      visibility: visible;
      transform: translateX(0) rotate(45deg);
      transform-origin: center;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      position: absolute;
      z-index: -1;
    }
  }

  [data-popper-placement^="right"] > [data-popper-arrow] {
    left: -4px;

    &::before {
      top: -7px;
    }
  }

  [data-popper-placement^="left"] > [data-popper-arrow] {
    right: 10px;

    &::before {
      top: -7px;
    }
  }

  [data-popper-placement^="bottom-end"] > [data-popper-arrow],
  [data-popper-placement^="bottom-start"] > [data-popper-arrow],
  [data-popper-placement^="bottom"] > [data-popper-arrow] {
    top: -4px;

    &::before {
      left: -7px;
    }
  }

  [data-popper-placement^="top"] > [data-popper-arrow] {
    bottom: 10px;

    &::before {
      left: -7px;
    }
  }

}
