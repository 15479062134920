@include modal-custom("pwa-add-to-home-modal") {
  .modal-content {
    padding: 16px 0;
    border-radius: $border-radius;
  }

  .pwa-add-to-home-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__img {
      margin-bottom: 20px;
      max-height: 34px;
    }

    &__title {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      font-weight: normal;
      color: $secondary;
    }

    &__desc {
      text-align: left;
      color: $darkBlue;
    }

    &__text {
      margin-top: 40px;
    }

    &__pointer {
      position: fixed;
      bottom: 5%;
      left: 25%;
      height: 18%;
    }
  }

  @include maxWidth($M) {
    .modal-content {
      margin: 0 10px;
    }
  }
}
