.currency-select {
  &-label {
    &__symbol-icon {
      font-size: 12px;
      margin-right: 5px;
      text-align: center;
      position: relative;
      background-color: $teal;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50%;
      line-height: 18px;
      color: $darkBlue;
    }

    &__value {
      font-size: 15px;
      color: $darkBlue;
      font-weight: 200;
    }
  }
}