.tournaments-block {
  margin: 0 auto;
  &__top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 9px;
    padding: 15px 0 0;
  }
  &__title {
    color: $default;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__show-all {
    color: $primary;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-transform: capitalize;
    text-decoration: none;
    transition: 250ms;
    &:hover {
      text-decoration: underline;
      transition: 250ms;
    }
  }
}

.tournament {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 258px;
  margin: 0 auto;
  padding: 32px 32px 35px;
  border-radius: 17px;
  background-repeat: no-repeat;
  background-position: center;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;
  }
  &__right {
    flex-basis: 50%;

    .btn {
      margin: 0 0 0 auto;
      min-width: 118px;
    }
  }
  &__title-wrap {
    div {
      color: $default90;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 6px;
    }
    h3 {
      color: $default;
    }
  }
  &__timers-title {
    margin-bottom: 11px;
    color: $default90;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
  }
  &__clock-wrap {
    display: flex;

    .countdown {
      margin-right: 20px;
    }
    .btn {
      font-weight: bold;
      min-width: 106px;
    }
  }
}

@include maxWidth($L) {
  .tournament {
    &__left {
      flex-basis: 40%;
    }
    &__rigth {
      flex-basis: 60%;
    }
    &__clock-wrap {
      flex-direction: column;

      .countdown {
        margin: 0 0 21px;
      }
      .btn {
        min-width: 240px;
      }
    }
  }
}

@include maxWidth($M) {
  .tournament {
    flex-direction: column;
    align-items: center;
    padding: 29px 16px 21px;

    &__left {
      flex-basis: auto;
      width: 100%;
      margin: 0 0 20px;
    }
    &__right {
      flex-basis: auto;
      width: 100%;
    }
    &__clock-wrap {
      flex-direction: row;
      .countdown {
        margin: 0 20px 0 0;
      }
      .btn {
        min-width: 110px;
      }
    }
  }
}

@include maxWidth($S) {
  .tournament {
    &__title-wrap {
      margin: 0 0 38px;
      div {
        font-size: 14px;
        line-height: 17px;
      }
      h3 {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__timers-title {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
    }
    &__clock-wrap {
      flex-direction: column;
      .countdown {
        margin: 0 0 21px 0;
      }
      .btn {
        max-width: 240px;
        min-width: 100%;
      }
    }
    .view-prizes-btn {
      position: relative;
      border: none;
      padding: 12px 21px;
      margin-right: 10px;
      &:after {
        position: absolute;
        top: 14px;
        right: 3px;
        width: 6px;
        height: 14px;
        content: '';
        background: url("../../../images/elements/btn-arrow.svg") center no-repeat;
      }
      &:hover {
        background: transparent;
      }
    }
  }
}

@include maxWidth($XS) {
  .tournament {
    &__clock-wrap {
      .btn {
        min-width: 100%;
        max-width: unset;
      }
    }
  }
}
