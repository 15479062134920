.sign-in {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
  grid-auto-columns: 423px;
  background: url("../../images/bg/bg-sign-in.jpg") center center no-repeat;
  background-size: cover;

  @include maxWidth($S) {
    grid-auto-columns: minmax($XS, 1fr);
    background: $default;
  }

  &__form {
    padding: 16px;
    border-radius: $border-radius;
    background: $default;

    @include minWidth($S) {
      padding: 40px;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $secondary;

      @include maxWidth($S) {
        margin-bottom: 4px;
        font-size: 18px;
        line-height: 24px;
      }

      &:after {
        flex-shrink: 0;
        display: inline-block;
        margin: 0 0 0 13px;
        content: '';
        width: 21px;
        height: 23px;
        background: url("../../images/icons/waving-hand.svg") no-repeat;

        @include maxWidth($S) {
          transform: scale(0.8);
        }
      }
    }

    &__description {
      margin-bottom: 32px;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;

      @include maxWidth($S) {
        margin-bottom: 20px;
      }
    }

    &__forgot-password {
      margin-top: 32px;

      @include maxWidth($S) {
        margin-top: 16px;
      }
    }
  }
}
