.casino-load-more {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    text-align: center;
    color: $darkBlue;
  }

  &__progress-bar {
    margin-bottom: 20px;
  }

  &__button {

  }
}