.game-search-result {
  background: $default;
  width: 320px;
  border-radius: $border-radius;

  &__body {
    padding: 15px;
    margin-right: 4px;
    max-height: 450px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      border-top: 15px transparent solid;
      border-bottom: 15px transparent solid;
      background-clip: padding-box;
    }
  }

  &__not-found {
    margin: 20px 0;
  }

  &__item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
