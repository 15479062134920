.sign-up {
  overflow: hidden;
  background: $grey20;

  @include maxWidth($S) {
    grid-auto-columns: minmax($XS, 1fr);
    .reg-section {
      &__form-background {
        margin: 0 auto;
      }
    }
  }

  &__form {
    padding: 20px;
    border-radius: $border-radius;
    background: $default;

    @include minWidth($S) {
      padding: 40px;
    }
  }
}
