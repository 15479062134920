$switch-transition: all 0.25s cubic-bezier(0.78, 0.14, 0.15, 0.86);

.switch {
  display: flex;

  &__input {
    display: none;
  }

  &__box {
    position: relative;
    display: inline-flex;
    width: 55px;
    height: 31px;
    flex-shrink: 0;
    background-color: $grey;
    border-radius: 30px;
    transition: $switch-transition;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 23px;
      height: 23px;
      top: 4px;
      left: 4px;
      border-radius: 14px;
      background-color: $default;
      box-shadow: 0px 0px 6.2px rgba(80, 78, 78, 0.25);
      transition: $switch-transition;
    }
  }

  &__label {
    margin: 5px 0 0 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $default;
  }

  &__input:checked + &__box {
    background-color: $statusSuccess;

    &:after {
      left: calc(100% - 4px);
      transform: translateX(-100%);
    }
  }

  &__input:disabled + &__box {
    opacity: 0.8;
  }
}
