$border: 1px;

div.dropdown {
  position: relative;
  &.isClearable {
    .dropdown__value-container--has-value {
      & + .dropdown__indicators {
        .dropdown__indicator-separator {
          display: flex;
        }
      }
    }
  }
  &.isSearchable {
    .dropdown__single-value {
      top: 21.4px;
    }
    .dropdown__input {
      position: relative;
      top: 11px;
      left: -2px;
      margin: 0;
      color: $default;
      font-size: 15px;
      line-height: 21px;
    }
  }
  &.hasError {
    .dropdown__control,
    .dropdown__control:hover {
      .dropdown__floating-placeholder {
        color: $statusError;
      }
      background: rgba($statusError, 0.1);
      &:after,
      &:before {
        background: $statusError;
        opacity: 1;
      }
    }
  }
  &.noPlaceholder {
    .dropdown__single-value {
      top: 50%;
      left: 0;
    }
  }
  &--is-disabled {
    opacity: 0.6;
  }

  &-container {
    outline: none;
  }

  &__control {
    height: 52px;
    background: $grey50;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    &:hover,
    &--is-focused, &--menu-is-open {
      background: $default;
      border: 1px solid $secondary;
      outline: none;
      box-shadow: 0 0 0 4px $boxShadowDropdownFocused;
      transition: 100ms border;
    }
  }

  &__single-value {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16.9px;
    margin: 0;
    color: $darkBlue;
    font-size: 15px;
    line-height: 21px;
    font-weight: normal;
    text-transform: capitalize;
    .dropdown__value-icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  &__floating-placeholder {
    margin: 0;
    padding: 0;
    position: absolute;
    color: $darkBlue;
    transition: 250ms;
  }

  &__value-container {
    padding: 0;
    margin: 0;
    overflow: visible;
    left: 16px;
  }

  &__value-container,
  &__control {
    &--is-focused,
    &--has-value {
      .dropdown__floating-placeholder {
        font-size: 11px;
        line-height: 16px;
        transform: translateY(-11px);
        transition: 250ms;
      }
    }
    &--is-focused &--has-value,
    &--is-focused {
      .dropdown__floating-placeholder {
        color: $darkBlue;
      }
    }
  }

  &__indicators {
    margin: 0 10px;
  }

  &__clear-indicator {
    padding: 4px;
  }

  .dropdown__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {

    .dropdown-arrow {
      transition: 250ms;
    }
    .flipped {
      transition: 250ms;
      transform: rotate(180deg);
    }
  }

  &__menu {
    padding: 15px;
    background: $default;
    box-shadow: 0 12px 30px rgba(28, 51, 56, 0.1);
    border-radius: 12px;
    max-height: 200px;
    overflow: hidden;
    z-index: 2;
  }
  &__menu-list {
    max-height: 170px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }

  &__option {
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    color: $darkBlue;
    text-transform: capitalize;
    &--is-focused {
      background: $secondary10;
    }
    &--is-selected {
      background: $secondary20;
    }
    .dropdown__option-icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  &__error {
    font-size: 13px;
    line-height: 16px;
    color: $statusError;
    padding: 4.5px 0 0 16px;
  }
}
