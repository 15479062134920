.thematic-categories-list {
  display: flex;
  margin: 0;
  padding: 0;

  &__item {
    list-style: none;
    margin-right: 9px;
  }

  @include maxWidth($L) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include maxWidth($M) {
    grid-template-columns: repeat(2, 1fr);

    .casino-sliders & {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(10, calc(100% - 60px));
      grid-auto-flow: column;
      grid-gap: 13px;
      padding-right: 20px;
    }
  }

  @include maxWidth($S) {
    grid-template-columns: repeat(1, 1fr);

    .casino-sliders & {
      padding-right: 13px;
    }
  }
}

.thematic-category-slider {
  .slick-list {
    overflow: visible;
  }
  .slick-slide {
    margin-right: 9px;
    &:first-child {
      margin-left: 6px;
    }
  }
  &.slick-slider {
    &:hover {
      .slick-arrow {
        opacity: 1;
        transition: opacity 200ms;
      }
    }
    .slick-arrow {
      @include bigSliderArrows();
      display: block;
      transition: opacity 200ms;
      top: 30px;
      width: 80px;
      height: 215px;
      opacity: 0;
      &:before {
        top: 85px;
      }
    }
    .slick-prev {
      left: -20px;
      border-radius: 0 17px 17px 0;
    }
    .slick-next {
      right: -20px;
      border-radius: 17px 0 0 17px;
      &:before {
        transform: rotate(180deg);
      }
      &:hover {
        &:before {
          transform: rotate(180deg) scale(1.1);
        }
      }
    }
  }
  @include maxWidth($M) {
    &.slick-slider {
      .slick-prev {
        left: -16px;
      }
      .slick-next {
        right: -16px;
      }
    }
  }
  @include maxWidth($S) {
    .slick-slide {
      margin-right: 8px;
    }
  }
}
