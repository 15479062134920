@include modal-custom("notification-modal") {
  .modal {
    &-body {
      margin: 0;
    }
    &-content {
      padding: 40px 30px;
    }
    &-footer {
      display: none;
    }
  }
  @include maxWidth($M) {
    .modal-content {
      padding: 30px 15px;
    }
    .notification-modal {
      &__icon-wrap {
        img {
          width: 72px;
          height: 72px;
        }
      }
      &__title {
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 12px;
      }
      &__btn {
        font-size: 15px;
        margin: 12px 0 0;
      }
    }
  }
  @include maxWidth($S) {
    .modal__content {
      max-width: 100vw;
    }
  }
}
