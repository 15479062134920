$fontPrimary: "Inter", sans-serif;
$border-radius: 17px;
$tickerDuration: 20s;

$main: #3ccc87;
$mainBgGradient: linear-gradient(180deg, #1da5c5 0%, #3cc4e9 100%);
$errorPageGradient: linear-gradient(180deg, #12404F 0%, rgba(26, 89, 104, 0.99) 29.56%, rgba(34, 121, 134, 0.94) 57.45%, #35DAD0 100%);
$gameCardGradient: linear-gradient(
  180deg,
  rgba(17, 172, 209, 0.51) 0%,
  #11acd1 100%
);
$primary: #FF9E2C;
$primary50: #ff9e2c;
$primaryActive: #f79420;
$primaryHover: #ffe669;
$primarySvgFill: #ffeeda;
$primaryBgGradient: linear-gradient(180deg, #ff9e2c 0%, #ffb72c 100%), #ff9e2c;
$primaryBgGradientHover: linear-gradient(125deg, #ff9e2c 0%, #ffb72c 100%);
$whiteFadeGradient: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 0%,
  rgba(255, 255, 255, 1) 20%,
  rgba(255, 255, 255, 1) 100%
);
$primaryOpacity50: rgba(255, 214, 14, 0.5);
$primaryOpacity40: rgba(255, 214, 14, 0.4);
$default: #ffffff;
$default90: rgba($default, 0.9);
$default70: rgba($default, 0.7);
$default50: rgba($default, 0.5);
$default40: #767a85;
$black: #000;
$secondary: #1095b5;
$secondaryOpacity90: rgba(29, 34, 47, 0.9);
$secondary100: #232939;
$secondary60: #353d52;
$secondary60Transparent: rgba(35, 41, 57, 0.6);
$secondary50: #1ba3c3;
$secondary20: #e1f3f7;
$secondary20Transparent: rgba(#e1f3f7, 0.7);
$secondary10: #f0f9fb;
$secondary40: #3d455a;
$secondary30: #5c6682;
$secondary0: #97a4c3;
$secondaryLight: #b9c5e1;
$secondaryLight30: #485167;
$secondaryLight20: #4f586f;
$secondaryDark: #505970;
$darkBackground: #1d222f;
$darkBackground10: rgba(29, 34, 47, 0.1);
$secondaryLight50: #79839c;
$secondaryBgGradient: linear-gradient(
  136.18deg,
  #292f41 28.25%,
  #464f66 98.65%
);
$dark150: #1d222f;
$grey50: #dfeaf0;

// Bahsegel specific
$dark100: #2f6775;
$darkBlue: #1c4f5b;
$darkBlueOpacity15: rgba(#1c4f5b, .15);
$grey: #77959d;
$grey20: #f0f5f8;
$greyEC: #ecf2f4;
$teal: #5cbdd4;
$lightTeal: #bce5ee;
$teal2: #1ba3c4;
$radioBoxGrey: #bbcace;
$searchBoxGrey: rgba(220, 229, 234, 0.6);
$gameSliderBtnBG: rgba(240, 245, 248, 0.8);
$checkboxBorder: rgba(119, 149, 157, 0.5);

$boxShadowDropdownFocused: #1ba3c426;
$boxShadowBonusCardFocused: 0px 48px 64px rgba(87, 127, 137, 0.4);
$boxShadowWhiteCards: 0px 12px 28px rgba(28, 51, 56, 0.04);
$boxShadowCardWithImage: 0px 12px 30px rgba(28, 51, 56, 0.1);
$boxShadowBtnGroup:  0px 4px 8px rgba(26, 60, 79, 0.09);
//

$actionColor: $primary;
$footerBg: $secondary100;
$transparentColor: transparent;
$seoBackground: $secondary100;
$afterLoginModalBg: linear-gradient(0deg, #343b4f, #343b4f),
  linear-gradient(136.18deg, #292f41 28.25%, #464f66 98.65%);
$tournamentTableGradient: linear-gradient(
  180deg,
  rgba(53, 61, 82, 0) -40.32%,
  #353d52 78.23%
);
$tournamentBannerBg: #05d9ee;
$sidebarOverlay: #555555;
$afterFooterGray: #2e3547;
$bonusBorderGray: #4f576a;
$footerNavPanelBg: #171a24;

$profileTemplateBg: rgba(52, 59, 79, 0.4);
$profileTemplateBgMob: #242a3b;
$profileNavLinksBg: #1d2330;
$promoItemOverlayBg: linear-gradient(
  0deg,
  rgba(0, 0, 0, 0.5) 0%,
  rgba(0, 0, 0, 0) 100%
);
$mainBannerOverlay: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.56) 0%,
  rgba(0, 0, 0, 0.1) 50.08%,
  rgba(0, 0, 0, 0) 100%
);

$statusSuccess: #3ccc87;
$statusError: #ea0028;
$statusError30: #623641;

$lightPurple: #d0d2ff;
$fadedOrange: #ffbf79;
$liveRed: #df2e4c;

$navIconFillPath: #919cb6;

$winnersBgGradient: linear-gradient(146.6deg, #2b3141 22.92%, #464f66 80.06%),
  #3c455c;
$thematicCategoryItemBgGradient: $winnersBgGradient;
$headerGradient: linear-gradient(0deg, #1d2230, #1d2230);
$cardSliderBg: linear-gradient(
  357.74deg,
  #121829 7.51%,
  rgba(0, 0, 0, 0) 94.69%
); // legacy
$gameCardContentBg: $cardSliderBg;
$cardHoverBg: rgba(0, 0, 0, 0.65);
$demoBgGradient: rgba(225, 243, 247, 0.4);
$demoBgGradientHover: rgba(225, 243, 247, 0.3);

$primaryShadow: 0px 12px 28px rgba(28, 51, 56, 0.04);
$secondaryShadow: 0px 12px 30px rgba(28, 51, 56, 0.1);

//screen size breakpoints
$XS: 320px;
$S: 480px;
$M: 812px; // ipone x landscape
$L: 1024px;
$XL: 1200px;
$XXL: 1920px;
