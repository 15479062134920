.complated-modal {
  &__payment-id {
    margin: 0 0 12px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: $darkBlue;
  }
  .notification-block {
    &__text {
      color: $dark100;
    }
    &__title {
      color: $teal;
    }
  }
  &__description {
    margin: 0 0 25px;
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: $dark100;
  }

}