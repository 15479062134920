.thematic-category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0 0 0;
  width: 228px;
  height: 230px;
  text-decoration: none;
  border-radius: 17px;

  &__img {
    position: absolute;
    display: block;
  }

  &__name {
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    color: $default;
    z-index: 1;

    &:after {
      content: "";
      display: none;
      width: 24px;
      height: 24px;
      margin-right: 1px;
      background-image: url("../../../images/icons/arrow-right.svg");
      opacity: 0;
      transform: translateX(-3px);
      transition: transform 0.2s ease;
    }
  }

  &:hover &__name {
    opacity: 0.75;

    &:after {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.mobile-thematic-category-slide {
  .thematic-category-item {
    width: 140px;
    height: 131px;
    &:first-child {
      margin-top: 0;
    }

    &__img {
      max-width: 140px;
    }
  }
}
