.dropdown-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 28px 0 0;
  &:after {
    content: " ";
    top: 17px;
    right: -1px;
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    background: url(../../../images/icons/arrow-down-rounded.svg) no-repeat;
  }

  &__clicked-section {
    display: flex;
    cursor: pointer;
    margin-right: 8px;
    & > svg {
      width: 36px;
      height: 36px;
      @include maxWidth($M) {
        width: 22px;
        height: 22px;
      }
    }
    @include maxWidth($M) {
      margin: 0 6px 0 0;
      padding: 0;
      transform: scale(1);
      &:after {
        display: none;
      }
    }
  }

  &__dropdown-info {
    position: absolute;
    transform: translate(-62px, 265px);
    width: 290px;
    margin-top: 15px;
    padding: 10px;
    border-radius: 17px;
    border: 1px solid $grey50;
    background: $default;
    box-shadow: $boxShadowCardWithImage;
    z-index: 11;
    cursor: auto;

    @include maxHeight(655px) {
      top: 5px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    @include maxWidth($M) {
      transition: 1s transform;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 5px;
      margin: 0 5px;
      padding: 20px 20px 15px;
      width: calc(100% - 10px);
      z-index: 111111;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .navigation-block {
        .nav-bar.navigation-block__links {
          .nav-item {
            &__title {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  &.is-active {
    z-index: 99;
    &:after {
      top: 13px;
      transform: rotateX(180deg);
    }
    div.dropdown-wrapper__dropdown-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include maxWidth($M) {
        transform: translateY(0) !important;
      }
    }
  }
  &.inactive {
    div.dropdown-wrapper__dropdown-info {
      display: none;
      @include maxWidth($M) {
        display: block;
        transform: translateY(1000px) !important;
      }
    }
  }
  &__close {
    transform: scale(0.6);
    top: 0;
    right: 0;
    @extend .btn-close-circle;
    @include minWidth($M) {
      display: none;
    }
  }
}

.dropdown-wrapper-additional {
  margin: 0;
}

@include maxWidth($S) {
  .dropdown-wrapper {
    margin: 0 5px 0 0;

    &:after {
      top: 14px;
      right: 1px;
    }
    &.is-active {
      &:after {
        top: 10px;
      }
    }
  }
}
