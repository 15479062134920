.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 68px;
  background: $default;

  &__public-chat,
  &__support,
  &__install-app {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
    &--logged {
      padding: 0 45px 0 0;
      &:after {
        position: absolute;
        top: 9px;
        right: 21px;
        content: "";
        height: 28px;
        width: 0;
        @include dividerDottedLine(left);
      }
    }

    svg {
      margin: 0 0 3px;
    }
    .btn__label {
      text-align: center;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      color: $darkBlue;
    }

    @include maxWidth($L) {
      &--logged {
        margin: 0 0 0 10px;
        padding: 0 25px 0 0;
        &:after {
          right: 10px;
        }
      }
    }
  }

  .main-logo {
    width: 185px;
    @include maxWidth(920px) {
      width: 120px;
    }
    @include maxWidth(335px) {
      width: 100px;
    }
  }
  .hamburger-menu {
    position: relative;
    height: fit-content;
    margin: 0 10px 0 0;
    transform: scale(1.1);
  }

  &__not-logged {
    position: absolute;
    right: 20px;
    top: 15px;
    display: flex;
    align-items: center;

    .tooltip,
    .btn-registration {
      margin-left: 10px;
    }

    .tooltip-additional {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .user-info .tooltip {
    margin-right: 10px;
  }

  @include maxWidth($L) {
    padding: 10px;
    &__not-logged {
      right: 10px;
      grid-column-gap: 6px;
    }
  }
  @include maxWidth($M) {
    height: 52px;
    align-items: center;
    padding: 0 3px 0 16px;
    box-shadow: none;

    .hamburger-menu {
      margin: 0 10px 0 0;
    }

    &__support,
    &__public-chat,
    &__install-app,
    &__divider
    {
      display: none;
    }

    &__not-logged {
      top: 8px;
      right: 6px;
      grid-column-gap: 6px;

      .btn-login {
        border: unset;
        background: transparent;

        &:hover {
          border: unset;
          background: transparent;
        }
      }

      .btn-login,
      .btn-registration {
        font-size: 13px;
        font-weight: bold;
        padding: 10px 12px;
      }
    }
  }
  @include maxWidth($S) {
    &__not-logged {
      grid-template-columns: auto auto;
    }
  }

  .main-template--pwa-landing & {
    padding-left: 20px;

    & .nav-bar,
    & .user-info,
    & .hamburger-menu,
    &__not-logged {
      display: none;
    }
  }

  &__divider {
    font-size: 13px;
    height: 25px;
    line-height: 127.5%;
    letter-spacing: 0.095em;
    color: $secondary;
    transform: rotate(-90deg);
    &-info {
      margin-left: -10px;
    }
  }
}
