.lazy-img {
  @keyframes animImg {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }

  }

  &--loaded {
    animation: animImg linear .2s;
  }
}