@include modal-custom("bonus-exchange-modal") {
  .modal {
    &-title {
      text-align: center;
    }

    &-content {
      padding: 28px 0 6px;
    }
  }

  .bonus-exchange-info {
    display: flex;
    align-items: flex-start;
    margin: 28px 0;

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__value {
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      white-space: nowrap;
      color: $primaryActive;
    }

    &__label {
      max-width: 120px;
      margin-top: 6px;
      font-size: 13px;
      text-align: center;
      text-align: center;
      color: $grey;
    }

    &__divider {
      display: block;
      flex-shrink: 0;
      width: 1px;
      height: 40px;
      margin: 0 20px;
      background-color: rgba($grey, 0.3);
    }
  }

  .bonus-exchange-footer {
    margin-top: 16px;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    color: $grey;
  }

  .bonus-exchange-form {
    display: flex;
    flex-direction: column;

    &__inputs {
      display: flex;
      align-items: flex-start;
    }

    &__input-wrapper {
      display: flex;
      width: 100%;
    }

    &__label {
      flex-shrink: 0;
      margin-right: 16px;
      font-weight: 600;
      font-size: 15px;
      line-height: 54px;
      color: $secondary;
    }

    &__input {
      width: 100%;

      .custom-input {
        &__input-field {
          padding-top: 9px;
        }

        &__error {
          margin: 5px -70px 0 -0px;
        }
      }
    }

    &__arrow {
      display: flex;
      flex-shrink: 0;
      width: 80px;
      height: 54px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../../images/elements/bonus-exchange-arrow.svg");
    }

    &__submit {
      margin-top: 28px;
    }
  }
}
