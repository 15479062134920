.collapsed-nav-menu {
  position: relative;
  height: 100%;
  &__title {
    margin: 0 20px;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    color: $secondary;
  }
  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 15px;
  }
  &__nav-items {
    display: inline-block;
    position: relative;
    float: left;
    width: 90px;
    height: 100%;
    background: $grey20;
    border-radius: 0 12px 12px 0;
    border: 1px solid $darkBlueOpacity15;
    &.collapsed {
      border-radius: 0;
      border-right: 0;
      .collapsed-nav-menu {
        &__item {
          &.active-item  {
            .collapsed-nav-menu__item-icon {
              background: $primaryBgGradient;
            }
            .collapsed-nav-menu__item-label {
              font-weight: 600;
            }
          }
        }
      }
    }
    .collapsed-nav-menu__item {
      &.fast_deposit {
        margin-top: 18px;
        .collapsed-nav-menu__item-icon {
          margin-top: inherit;
        }
        &:before {
          content: "";
          left: 15px;
          height: 1px;
          width: 60px;
          background: $darkBlueOpacity15;
          position: absolute;
        }
      }
      &.search {
        bottom: 18px;
        position: absolute;
        .collapsed-nav-menu__item-icon {
          padding: 10px 5px;
        }
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-icon {
      cursor: pointer;
      background: $default;
      padding: 5px;
      margin: 23px 23px 5px 23px;
      display: flex;
      border-radius: 16px;
      justify-content: center;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid $actionColor;
      }
    }
    &-label {
      display: block;
      font-size: 12px;
      text-align: center;
    }
  }
  &__content {
    visibility: visible;
    opacity: 1;
    height: 100%;
    width: 320px;
    display: inline-block;
    background: $grey20;
    border-radius: 0 17px 17px 0;
    border: 1px solid $darkBlueOpacity15;

    .nav-top-games,
    .recently-played-games,
    .nav-game-search {
      height: calc(100% - 65px);
      overflow: scroll;
      padding: 10px 15px 0;
      .game-list {
        grid-template-columns: repeat(2, 1fr);
      }
      .game-card {
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
          transition: 0.4s cubic-bezier(0.5, 0, 0.1, 1);
        }
        &__buttons {
          & > .btn-play {
            margin-bottom: 5px;
            padding: 0;
            width: 30px;
            height: 30px;
            .btn__icon {
              transform: scale(.5);
              margin-left: 3px;
            }
          }
          & > .game-card__name {
            flex-shrink: 0;
            font-size: 11px;
            margin-bottom: 5px;
          }
          & > .btn-demo {
            transform: scale(0.7);
            line-height: 15px;
            white-space: nowrap;
          }
        }
      }
    }

    .nav-game-search {
      .game-bar-header {
        &__search {
          &-input {
            width: 100%;
            .casino-search-input__input {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    &.transition-hide {
      visibility: hidden;
      opacity: 0;
      width: 0;
      transition: width 1s, opacity .4s, visibility .4s;
    }
  }
}