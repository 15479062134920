.security-settings-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: flex-start;

  @include maxWidth($M) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.security-settings-section {
  padding: 20px;
  background-color: rgba($secondary20, 0.6);
  border-radius: $border-radius;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: $darkBlue;
  }

  &__body {
    margin-top: 16px;
  }
}

.two-factor-auth-message {
  margin-top: -6px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 21px;
  color: $darkBlue;

  b {
    font-weight: 600;
    color: $darkBlue;
  }
}

.two-factor-auth-form {
  &__item {
    border-top: 1px solid rgba($grey50, 0.15);
    padding: 12px 0;

    &:last-child {
      margin-bottom: -12px;
    }
  }
}

.two-factor-auth-form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__input {
  }
}

@include maxWidth($M) {
  .security-settings-section {
    background-color: $default;
    box-shadow: $boxShadowWhiteCards;
    padding: 15px 15px 20px;

    &__title {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

