.reg-section {
  max-width: 1326px;
  width: 100%;
  padding: 0 20px;
  margin: 50px auto 20px;

  &__form-background {
    position: relative;
    max-width: 831px;
    width: 100%;
    padding: 50px 50px 54px 50px;
    margin: 0 102px 0 auto;
    border-radius: 32px;
    background: $default;
  }
  &__banner {
    position: absolute;
    top: 121px;
    left: -271px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 546px;
    max-height: 330px;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 32px;
    color: $default;
    text-align: center;

    &-title {
      margin: 0 0 10px;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
    }
    &-value {
      margin: 0 0 32px;
      font-weight: 800;
      font-size: 44px;
      line-height: 53px;
    }
    .btn {
      min-width: 172px;
      height: 50px;
    }
  }
  &__form-wrap {
    margin: 0 0 0 auto;
    max-width: 420px;
    width: 100%;
  }
  &__sub-title {
    margin: 0 0 7px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
    text-align: center;
  }
  &__title {
    margin: 0 0 21px;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: $secondary;
    text-align: center;
  }

  @include maxWidth(1250px) {
    &__form-background {
      max-width: 770px;
      margin: 0 20px 0 auto;
    }
    &__banner {
      top: 100px;
      left: -240px;
      transform: scale(0.8);
    }
  }
  @include maxWidth($L) {
    margin: 30px auto 0;

    &__form-background {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__banner {
      position: initial;
      margin: 0 0 25px;
      padding: 32px 20px 35px;
      max-width: 420px;
      transform: none;

      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
      &-value {
        margin: 0 0 18px;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
      }
      .btn {
        height: 44px;
      }
    }
    &__form-wrap {
      margin: 0;
    }
  }
  @include maxWidth($M){
    &__form-background {
      padding: 20px;
    }
    .recaptcha {
      transform: scale(1);
    }
    &__banner {
      height: 128px;
    }
  }
  @include maxWidth($S) {
    padding: 0;
    &__form-background {
      padding: 16px 16px 32px;
      width: 100vw;
      margin: 0 -16px;
    }
    &__banner {
      padding: 32px 10px 35px;
    }
    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }
}