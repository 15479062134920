.game-list {

  &__item {
    width: 100%;
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 9px;
  }

  &--row {
    display: flex;
  }

  &--row &__item {
    flex-shrink: 0;
    max-width: 228px;
  }

  @include maxWidth($S) {

    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
    }
  }
}