@include modal-custom("pwa-promo-modal") {
  .modal {
    &__content {
      width: auto;
      max-width: none;
    }
  }

  .modal-header,
  .modal-body,
  .modal-content {
    margin: 0;
    padding: 0;
  }

  .modal-content {
    overflow: hidden;
  }

  .pwa-promo-modal-body {
    position: relative;
    display: flex;
    justify-content: center;

    &__bg-img {
      width: 100%;
      max-width: 460px;
      &-grp {
        position: absolute;
        bottom: 0;
      }
    }

    &__text {
      position: absolute;
      top: 50px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__label {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: $default;
    }

    &__title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      color: $default;
    }

    &__desc {
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      color: $default;
      opacity: 0.9;
    }

    &__btn {
      margin-top: 15px;
    }
  }
}
