.loader {
  display: grid;
  justify-items: center;
  grid-row-gap: 15px;

  &__animation {
      margin: 20px;
      position: relative;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      display: grid;
      justify-content: center;
      align-items: center;
      img {
        padding-left: 5px;
        width: 36px;
      }

    &:before, &:after {
      content: '';
      border: 2px solid $primary;
      border-radius: 50%;
      width: 55px;
      height: 55px;
      position: absolute;
      left: 0px;
    }

    &:before {
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
      -webkit-animation: spWaveBe 0.6s infinite linear;
      animation: spWaveBe 0.6s infinite linear;
    }

    &:after {
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      transform: scale(0, 0);
      opacity: 0;
      -webkit-animation: spWaveAf 0.6s infinite linear;
      animation: spWaveAf 0.6s infinite linear;
    }
  img {
    transform: scale(1.1);
    -webkit-animation: loader-img-keyframes 1.2s linear infinite;
    animation: loader-img-keyframes 1.2s linear infinite;
  }

  &__text {
    color: $default;
  }
}

  &--overflow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    align-content: center;
    background: $secondaryOpacity90;
  }

  &--center {
    align-content: center;
    align-items: center;
  }

  &--full-height {
    height: 100%;
  }

  &--lg {
    .loader {
      &__animation {
        height: 100px;
        width: 100px;
        img {
          width: 60px;
        }
        &:before, &:after {
          height: 100px;
          width: 100px;
        }
      }
    }
  }


  @-webkit-keyframes spWaveAf {
    from {
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes spWaveAf {
    from {
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @-webkit-keyframes spWaveBe {
    from {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5, 1.5);
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }
  @keyframes spWaveBe {
    from {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5, 1.5);
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes loader-img-keyframes {
    0%   { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.1); }
    100% { -webkit-transform: scale(1); }
  }

  @keyframes loader-img-keyframes  {
    0%   { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
}