.status-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  &__icon {
    display: flex;
    margin-right: 4px;
  }

  &__text {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.2;
  }

  &--filled {
    height: 28px;
    padding: 0 10px;
    background-color: $default;
    box-shadow: $boxShadowCardWithImage;
    border-radius: $border-radius;
  }

  &--filled &__icon {
    margin-left: -7px;
  }

  &--filled &__text {
    color: $grey;
  }

  &--transparent &__text {
    color: $darkBlue;
  }
}