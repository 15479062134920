.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  //noinspection CssInvalidFunction
  &.footer-navigation-panel {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba($teal2, 0.5);
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    background: $default;
    padding-bottom: calc(env(safe-area-inset-bottom) + 2px);

    .nav-item {
      flex-basis: 25%;
      padding: 0 10px;
      margin: 0;
      height: 44px;
      flex-grow: 1;
      justify-content: flex-end;
      &:first-child {
      }
      &:last-child {
      }
      &__icon {
        &-img {
          margin: auto 0;
          &-active {
            display: none;
          }
        }
      }
      &__title {
        color: $darkBlue;
        line-height: 15px;
        font-size: 11px;
        font-weight: 500;
      }
      &__deposit-icon {
        position: absolute;
        top: -6px;
        width: 36px;
        height: 36px;
        background: url("../../../images/icons/navbar/deposit-icon-hollow-cicle.svg")
          no-repeat center;
        &-inner {
          position: absolute;
          top: 6px;
          left: -2px;
          width: 40px;
          height: 30px;
          background: $default;
          &:before {
            position: absolute;
            top: -3px;
            left: 6px;
            content: "";
            width: 28px;
            height: 28px;
            background: url("../../../images/icons/navbar/deposit-icon-bg.svg")
              no-repeat center;
          }
          &:after {
            position: absolute;
            content: "";
            top: 3px;
            left: 12px;
            width: 16px;
            height: 16px;
            background: url("../../../images/icons/navbar/plus.svg") no-repeat
              center;
          }
        }
      }
      &:hover,
      &:focus,
      &:active,
      &--link_active {
        .nav-item__title {
          color: $primaryActive;
        }
        .nav-item__icon-img {
          display: none;
          &-active {
            display: block;
          }
        }
      }
      &--chat-btn {
        display: flex;
        background: transparent;
        border: none;
        .btn__icon {
          margin: auto 0;
          max-height: 24px;
        }
        .btn__label {
          color: $darkBlue;
          font-size: 11px;
          line-height: 15px;
          font-weight: 500;
        }
      }
      @include maxWidth($M) {
        padding: 0 5px 2px;
      }
    }
    .user-info {
      margin: 0;
      .btn-deposit {
        display: none;
      }
    }
    &.hidden {
      display: none;
    }
  }

  &.header-navigation-panel {
    margin-left: 50px;

    .nav-item {
      font-size: 14px;
      line-height: 20px;
      flex-direction: row;
      .lazy-img {
        width: 22px;
        height: 22px;
        margin: 0 11px 0 0;
      }
    }

    @include maxWidth(1300px) {
      margin-left: 10px;
      .nav-item {
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;
      }
    }
    @include maxWidth($XL) {
      margin-left: 10px;
      .lazy-img {
        display: none;
      }
    }
  }

  &.tabs-navigation {
    margin: 10px 0 20px;
    border-bottom: 1px solid rgba($secondary30, 0.15);
    .nav-item {
      height: 35px;
      font-weight: 600;
      font-size: 15px;

      &__title {
        white-space: nowrap;
      }

      &--link_active {
        .nav-item__title {
          color: $primaryActive;
        }
        &:after {
          position: absolute;
          content: "";
          bottom: -2px;
          display: block;
          border-radius: 6px;
          min-height: 3px;
          width: 100%;
          background: $primary50;
        }
      }

      &--link_active.full-underscore:after,
      &.full-underscore:hover:after {
        bottom: -2px;
      }
    }
  }

  &.pill-nav-bar {
    margin: 0;
    padding: 4px;
    background: $grey50;
    border-radius: calc(#{$border-radius} + 1px);
    overflow: hidden;

    .nav-item {
      min-width: 144px;
      margin: 0;
      padding: 0 10px;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
      background-color: transparent;
      border-radius: $border-radius;

      &:after {
        content: none;
      }

      &--default {
        height: 36px;
      }

      &--link_active {
        font-weight: 600;
        color: $dark150;
        background-color: $default;
        box-shadow: 0 4px 8px rgba(26, 60, 79, 0.09),
          0 4px 8px rgba(26, 60, 79, 0.09);
      }
    }

    @include maxWidth($M) {
      .nav-item {
        min-width: 120px;
        padding: 0 7px;

        &--default {
          height: 30px;
        }
      }
    }
  }
}
