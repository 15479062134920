.cashbox-bonus {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 225px;
  max-width: 300px;
  min-height: 116px;
  width: 100%;
  padding: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-origin: border-box;
  border-radius: 17px;
  cursor: pointer;
  &--selected, &--selected.cashbox-bonus--no-bonus {
    border: 2px solid $primary;
    padding: 14px;
    .cashbox-bonus__checkbox {
      top: 4px;
      left: 11px;
      background-origin: border-box;
      background: $default no-repeat center;
      border: none;
      &:before {
        position: absolute;
        top: 7.7px;
        left: 6px;
        content: "";
        display: block;
        width: 13px;
        height: 9px;
        background: url("../../../images/icons/checkmark_orange.svg") no-repeat center;
      }
    }
  }
  &--in-slider {}
  &--no-bonus {
    background: $secondary60;
    border: 1px solid $bonusBorderGray;
    justify-content: center;
    align-items: center;
    .cashbox-bonus__checkbox {
      top: 12px;
      right: 12px;
    }
    .cashbox-bonus__type {
      color: $default;
      font-size: 13px;
      line-height: 17px;
    }
    .cashbox-bonus__title {
      color: $grey50;
      font-size: 11px;
      line-height: 14px;
    }
  }

  &__checkbox {
    position: absolute;
    top: 6px;
    left: 13px;
    width: 24px;
    height: 24px;
    border: 2px solid $default;
    border-radius: 100%;
  }
  &__type {
    margin: 0 0 2px;
    color: $grey50;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
  }
  &__title {
    color: $default;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
  }

  &__description {
    position: absolute;
    bottom: 15px;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    color: $default;
    max-height: 43px;
    overflow: hidden;
  }
}