.cashbox-fields {
  width: 100%;
  margin-bottom: 20px;

  &__top {
    color: $teal2;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__step-btn {
    margin-top: 20px;
  }

  &-wrap {
    border: 1px solid $secondary10;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    height: 100%;
    max-width: 570px;
    min-height: calc(100vh - 250px);
    padding: 24px 46px;
    @include maxWidth($L) {
      padding: 20px 15px;
    }
    @include maxWidth($M) {
      border-radius: 17px;
      background: $default;
      margin-top: 10px;
    }
  }

  .method-info-container {
    color: $grey;
    font-size: 13px;
    line-height: 16.5px;
  }

  .btn {
    &__label {
      font-size: 13px;
    }
  }
}

.deposit-modal {
  .cashbox-fields {
    margin: 0 auto;
  }
}

.cashbox-fields-wrap--wd {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .cashbox-fields {
    @include maxWidth($S) {
      order: 2;
      margin-bottom: 0;
    }
  }
}
