.kyc-card {
  position: relative;
  overflow: hidden;
  color: $darkBlue;
  background-color: rgba($secondary20, 0.6);
  border-radius: $border-radius;

  &__header {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  &__icon {
    display: inline-flex;
    margin-left: -3px;
    margin-right: 10px;
  }

  &__title {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__label {
    margin-left: auto;
    margin-right: -5px;
  }

  &__body {
    padding: 5px 15px 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
  }

  @include maxWidth($M) {
    background-color: $default;
    box-shadow: $boxShadowWhiteCards;
    &__body {
      padding: 5px 15px 20px;
    }
    &__title {
      font-size: 18px;
      line-height: 24px;
    }
    &__icon {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}