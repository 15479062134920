.platform-tile {
  cursor: pointer;

  &__inner-wrap {
    display: flex;
    align-items: center;
    max-height: 65px;
    min-width: 173px;
    width: fit-content;
    padding: 14px 18px 18px;
    background: $black;
    border-radius: 11px;

    img {
      max-height: 33px;
      margin-right: 16px;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__get-it {
    margin: 0 0 2px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $default70;
  }

  &__platformName {
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    color: $default;
  }

  &__condition {
    margin-top: 9px;
    font-size: 12px;
    line-height: 13px;
    color: rgba(#004152, 0.6);
  }
}
