.winners {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__title {
    margin-bottom: 14px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.24;
    color: rgba($darkBlue, 0.5)
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    .winners-item__text {
      padding-bottom: 5px;
      border-bottom: 2px dotted rgba($teal2, 0.4);

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &--line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 12px 0;
    background-color: $secondary20;
  }

  &--line &__title {
    display: none;
  }

  &--line &__list {
    display: flex;
  }

  &--line &__list-item {
    flex-shrink: 0;
    padding-right: 56px;
    margin-right: 56px;
    height: 27px;
    @include dividerDottedLine(right);
    @include maxWidth($M) {
      padding-right: 25px;
      margin-right: 25px;
    }

    .winners-item__img {
      width: 27px;
      min-height: 20px;
      height: 20px;
      min-width: 27px;
    }

    .winners-item__text {
      margin: auto 0;
      padding-bottom: 0;
      border-bottom: 0;
      line-height: 17px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .winners-item__link {
      position: relative;
      display: inline-block;
      font-size: 13px;
      line-height: 17px;
      overflow: visible;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
