.casino-games {
  &__header {
    display: flex;
    align-items: center;
  }

  &__back-button {
    margin-right: 18px;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $secondary;
  }

  &__list {
  }

  &__not-found {
    margin: 40px 0 80px;
  }

  &__load-more {
    width: 250px;
    margin: 30px auto 0;
  }

  &.casino-game-tag-games &__header {
    margin-bottom: 16px;
  }

  &.casino-game-tag-games &__title {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 27px;
  }
}
