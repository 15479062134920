.main-template {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  &__header {
    width: 100%;
  }

  &__container {
    @include maxContainerSize();
    padding: 0 20px;
    width: 100%;
    min-height: 100%;

    @import "../pages/user-profile";
  }

  &--user {
    height: 100%;
    min-height: 100%;
  }

  &--sport &__container,
  &--game &__container,
  &--landing &__container,
  &--euro2020 &__container,
  &--pwa-landing &__container,
  &--casino &__container {
    padding: 0;
  }

  &--sport {
    .main-template__container {
      max-width: unset;
      iframe {
        position: fixed;
        width: 100%;
        height: calc(100% - 70px);
        top: 68px;

        @include maxWidth($M) {
          height: calc(100% - 52px);
          top: 52px;
        }
      }
    }
  }

  &--fixed-header &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }

  @include maxWidth($M) {
    &__footer,
    &__container {
      padding: 0 0 50px;
    }

    &--user &__container {
      min-height: auto;
    }
  }
}
