.landing-page {
  padding: 20px 20px 20px;
  overflow: hidden;

  .slider-section {
    margin: -20px -20px 20px -20px;
  }

  .sport-links {
    &__icon {
      display: none;
    }
  }

  &__play-tiles-wrap {
    @include maxContainerSize(1326px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(316px, 1fr));
    grid-gap: 20px;
    place-items: center center;
  }

  &__winners {
    display: none;
  }
}

@include maxWidth($L) {
  .landing-page {
    &__play-tiles-wrap {
      margin: 0 0 20px;
    }

    &__winners {
      display: block;
    }
  }
}

@include maxWidth($M) {
  .landing-page {
    padding: 0 16px 0;
    &__play-tiles-wrap {
      grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    }
    &__slider {
      min-height: 230px;
      ul.slick-dots {
        bottom: 16px;
      }
      .slick-arrow {
        display: none;
      }
    }
    &__slider-item {
      border-radius: 0;
    }
    .slider-section {
      margin: 0 -16px 20px;
    }
  }
}
