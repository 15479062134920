.slick-arrow.custom-prev-arrow, .slick-arrow.custom-next-arrow{
  display: block;
  position: absolute;
  z-index: 10;
  top: 50%;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: none;
  &:focus {
    outline: none;
  }
  &:before {
    display: none;
  }
}

.slick-arrow.custom-prev-arrow {
  background: url("../../../images/elements/slider_arrow_left.svg") no-repeat center;
  left: 10px;
  &:hover, &:focus {
    transform: rotate(180deg) translateY(18px) translateX(-1px);
    background: url("../../../images/elements/slider_arrow_right_yellow.svg") no-repeat center;
  }
}

.slick-arrow.custom-next-arrow {
  background: url("../../../images/elements/slider_arrow_left.svg") no-repeat center;
  transform: rotate(180deg) translateY(17px);
  right: 10px;
  &:hover, &:focus {
    transform: translateY(-17px) translateX(-1px);
    background: url("../../../images/elements/slider_arrow_right_yellow.svg") no-repeat center;
  }
}