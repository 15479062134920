.cashbox-method {
  background: $default;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  display: grid;
  grid-template-columns: 84px calc(100% - 84px);
  border-bottom: 1px solid $darkBackground10;
  align-items: center;
  box-shadow: $boxShadowWhiteCards;

  &__collapsed {
    grid-template-columns: 84px calc(100% - 105px) 21px;
  }

  &__arrow {
    display: block;
    transition: all 0.15s;
    width: 18px;
    height: 18px;
    path {
      stroke: $grey;
    }
  }

  &.box {
    &__default {
      width: 100%;
      height: 56px;
      font-size: 11px;
    }

    &__lg {
      width: 210px;
      height: 153px;
      font-size: 13px;
      padding: 30px 30px 20px;
    }

    &__md {
      width: 143px;
      height: 103px;
      font-size: 11px;
      padding: 20px;
    }
  }

  &__logo {
    justify-self: center;
    height: 40px;
    width: 68px;
    background: $secondary10;
    border-radius: 12px;
    svg,
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding: 5px;
    }
  }

  @include minWidth($M) {
    &:hover {
      border: 1px solid $primary;
    }
  }

  &__info {
    &__method-name {
      color: $darkBlue;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__selected {
    .cashbox-method {
      &__arrow {
        transform: rotate(90deg);
      }
    }
  }

  &__selected:not(&__collapsed) {
    @include minWidth($M) {
      background: $primary50;
      border-bottom: 1px solid $primary50;
      .cashbox-method {
        &__info__method-name {
          color: $default;
        }
      }
      .limits-info {
        &__value {
          color: $default;
        }
        .label {
          color: $default;
        }
      }
    }
  }
}

.deposit-modal {
  .cashbox-methods-list__item {
    &.box {
      &__default {
        width: 100%;
        height: 56px;
        font-size: 11px;
      }
    }
  }
}
