@include modal-custom("login-modal") {
  .modal-desc {
    display: flex;
    align-items: center;
    position: relative;
    &:after {
      flex-shrink: 0;
      display: inline-block;
      margin: 0 0 0 13px;
      content: '';
      width: 22px;
      height: 23px;
      background: url("../../../../images/icons/waving-hand.svg") no-repeat;
    }
  }

  @include maxWidth($M) {
    @include mobile-modal-fullscreen;
  }
}