.custom-list {
  // Default list styles //
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: tourney_list;
  &__list-item, li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 30px;
    margin: 0;
    padding: 0 0 16px 46px;
    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      font-weight: 600;
      font-size: 13px;
      line-height: 28px;
      color: $default;
      text-align: center;
      counter-increment: tourney_list;
      content: counter(tourney_list);
      border: 1px solid rgba(#B9C5E1, 0.4);
      border-radius: 100%;
      box-sizing: border-box;
    }
  }
  &__list-item-title, li {
    margin: 0 0 8px;
    color: $default;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
  }
  &__list-item-text, li {
    color: $secondaryLight;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
  }

  // Green list styles //
  &__green {
    .custom-list__list-item {
      padding: 0 0 24px 58px;
      min-height: 42px;
      &:last-child {
        padding-bottom: 0;
      }
      &:before {
        width: 42px;
        height: 42px;
        font-size: 15px;
        line-height: 42px;
        border: none;
        background: $mainBgGradient;
        filter: drop-shadow(3.10203px 3.10203px 0px rgba(36, 91, 102, 0.21));
      }
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 20px;
          bottom: 0;
          border-left: 2px dashed rgba($main, 0.4);
          z-index: -1;
        }
      }
    }
    .custom-list__title {

    }
    .custom-list__text {
      font-size: 13px;
      line-height: 19px;
    }
  }
  &__yellow {
    .custom-list__list-item, li {
      padding-left: 35px;
      min-height: 21px;
      &:before {
        top: 3px;
        left: 1px;
        width: 6px;
        height: 6px;
        background: $primary;
        border: 2.5px solid transparent;
        box-shadow: 0 0 0 1px $primary;
        background-clip: content-box;
        box-sizing: content-box;
        border-radius: 100%;
        content: '';
      }
    }
    .custom-list__list-item-text, li {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $secondary0;
    }
  }
}

@include maxWidth($S) {
  .custom-list {
    &__list-item-text {
      font-size: 13px;
      line-height: 19px;
    }
  }
}
