.casino-provider-item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;

  &__icon {
    width: auto;
    height: 65px;
    margin-top: -4px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin-bottom: 2px;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: $darkBlue;
    text-align: center;
  }

  &__count {
    font-size: 11px;
    line-height: 1.2;
    color: $secondaryLight;
  }

  &--active {
    border-color: $secondary;
    border-radius: $border-radius;
  }

  .casino-providers-desktop &--all-button &__name,
  .casino-providers-desktop &--less-button &__name {
    padding: 10px 20px;
  }

  .casino-providers-desktop &--less-button &__name {
    font-weight: 600;
    color: $secondary;
  }

  .casino-providers-desktop & {
    flex-direction: row;
    width: auto;
    height: 36px;
    background-color: $default;
    border-radius: 12px;
  }

  .casino-providers-desktop &__icon {
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-right: -8px;
    margin-left: 2px;
  }

  .casino-providers-desktop &__name {
    padding: 10px;
  }

  @include maxWidth($M) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 30px;
    padding: 2px 8px 2px 6px;
    border: none;

    &__icon {
      height: 36px;
      width: 36px;
      margin-top: 0;
    }

    &__text {
      margin-right: auto;
    }

    &__name {
      margin-left: 5px;
      font-size: 15px;
      line-height: 21px;
    }

    &__count {
      margin-left: 5px;
      text-align: left;
    }

    &:hover &__name,
    &--active &__name {
      font-weight: bold;
    }

    &--active {
      background-color: rgba(225, 243, 247, 0.5);

      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("../../../images/icons/providers-check.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
