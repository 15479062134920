.casino-categories-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 6px;
  list-style: none;

  &__item {
    max-width: 110px;
    margin: 0 10px;

    &:last-child {
      padding-right: 30px;
    }
  }

  &__devider {
    height: 44px;
    width: 1px;
    margin: 0 4px;
    background-color: rgba($secondaryLight, 0.2);
    flex-shrink: 0;
  }

  @include maxWidth($M) {
    &__devider ~ &__item {
      padding-right: 20px;
    }
  }
}
