.recaptcha {
  @include maxWidth($M) {
    transform: scale(.8);
  }

  &__error {
    padding-left: 16px;
    font-size: 13px;
    line-height: 17px;
    color: $statusError;
  }
}