@include modal-custom("tfa-process-modal") {
  .tfa-process-form {
    &__timer {
      margin-top: 10px;
      color: $secondaryLight;

      b {
        font-weight: 500;
        color: $default;
      }
    }

    &__resend-button {
      margin-top: 20px;
    }
  }
}
