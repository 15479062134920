.game-bar-tab-item {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__arrow {
    fill: $secondary;
    stroke: $secondary;
  }

  &__icon {
    display: flex;
    margin-right: 10px;
  }

  &__name {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__arrow {
    display: flex;
    margin-left: 4px;
    transform: translateY(2px);
    transition: transform 0.2s ease-in;
  }

  &--active {
    &.game-bar-tabs__top-games {
      svg {
        path:first-child {
          fill: $primarySvgFill;
        };
        path:nth-child(2) {
          stroke: $primary50;
          fill: $primary50;
        }
      }
    }
    &.game-bar-tabs__recent {
      svg {
        circle {
          fill: $primarySvgFill;
        }
        path:nth-child(2) {
          fill: $primary50;
        };
        path:nth-child(3) {
          stroke: $primary50;
        }
      }
    }
    &.game-bar-tabs__favorite {
      svg {
        path:first-child {
          fill: $primarySvgFill;
        };
        path:nth-child(2) {
          fill: $primary50;
        }
      }
    }
  }

  &--active &__name {
    color: $primary50;
  }

  &--active &__arrow {
    margin-top: 0;
    transform: rotate(-180deg) translateY(-1px);
  }
}

.game-bar-tabs {
  display: flex;

  &__top-games, &__recent, &__favorite {
    margin-right: 40px;
  }
}
