@include modal-custom("after-login-modal") {
  .modal__content {
    overflow: unset;
    max-width: 940px;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    margin: 0;
    padding: 0;
  }
  .user-after-login-top-wrap {
    max-width: 380px;
    width: 100%;
    flex-shrink: 0;
    padding: 40px 32px;
    align-self: stretch;
    border-radius: 17px 0 0 17px;
    background: $secondary10;
  }
  .user-after-login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 50px 0 0 0;

    .user-welcome {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      &__username {
        color: #1095b5;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      &__welcome-back {
        font-size: 15px;
        line-height: 21px;
        color: $darkBlue;
      }
      @include maxWidth($M) {
        &__username {
          font-size: 18px;
          line-height: 24px;
        }
        &__welcome-back {
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
    .user-current-progress {
      margin: 50px 0 0 0;
      .account-progress-info {
        display: flex;
        flex-direction: column;
        margin: 0;
        &__container {
          width: 100%;
          .progress-bar--user-level {
            display: none;
          }
          .account-progress-info__completed {
            &-value {
              color: $darkBlue;
            }
            color: $darkBlue;
          }
        }
        &__nickname {
          margin: 0 0 6px;
          color: $darkBlue;
        }
        .level-container {
          margin: 0 0 50px;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &__text-block {
            padding: 0;
            margin: 18px 0 0;
          }
        }
      }
    }
    .circular-progress-bar {
      width: 104px;
      height: 104px;
    }
    .go-play-games {
      margin-top: 10px;
      display: block;
      width: calc(100% - 380px);
      overflow: hidden;

      .recently-played__block-title,
      .recommends__block-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $darkBlue;
        @include maxWidth($M) {
          font-size: 18px;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 28px;
          height: 28px;
          margin-right: 12px;
          position: relative;
        }
      }
      &__recently-played,
      &__recommends {
        margin: 16px 16px 0;
        display: inline-block;
        width: calc(100% - 32px);
        .slider__recently-played,
        .slider__recommends {
          justify-content: space-between;
        }
        .custom-slider-container {
          margin-top: 19px;
          width: calc(100% + 16px);

          .slick-slide {
            padding-right: 11px;
          }
        }
      }
      .recently-played__block-title:before {
        top: 7px;
        transform: scale(1.2);
        background: url("../../../../images/elements/clock.svg") no-repeat;
      }
      .recommends__block-title:before {
        top: 6px;
        background: url("../../../../images/elements/fire.svg") no-repeat;
      }
      .btn-link-block {
        width: 100%;
        display: inline-block;
        .btn-action {
          display: block;
          margin: 34px auto 40px;
        }
      }
    }
  }

  .sport-links {
    margin: 28px 16px 0;

    &__header {
      margin-bottom: 16px;
    }

    &__title {
      color: $darkBlue;
    }
  }

  .slider-control {
    &__label {
      &:after {
        display: none;
      }
    }
  }
  .welcome-modal-slider {
    &.slick-slider {
      &:hover {
        .slick-arrow {
          opacity: 1;
          transition: opacity 200ms;
        }
      }
      .slick-arrow {
        @include bigSliderArrows();
        display: block;
        transition: opacity 200ms;
        top: 0px;
        width: 60px;
        height: 140px;
        opacity: 0;
        &:before {
          top: 46px;
          left: 12px;
          width: 37px;
          height: 37px;
          border-radius: 37px;
        }
      }
      .slick-prev {
        left: -16px;
        border-radius: 0 17px 17px 0;
      }
      .slick-next {
        right: 0;
        border-radius: 17px 0 0 17px;
        &:before {
          transform: rotate(180deg);
        }
        &:hover {
          &:before {
            transform: rotate(180deg) scale(1.1);
          }
        }
      }
    }
  }

  @include maxWidth($M) {
    @include mobile-modal-fullscreen;
    .modal__content {
    }
    .user-after-login-top-wrap {
      max-width: unset;
    }
    .user-after-login-container {
      .circular-progress-bar {
        width: 70px;
        height: 70px;
      }
      flex-direction: column;
      margin: 0;
      .go-play-games {
        width: 100%;
      }
    }
  }
  @include maxWidth($S) {
    .user-after-login-top-wrap {
      padding: 24px 15px;
    }
    .user-after-login-container {
      .user-welcome {
        &__username {
          margin-bottom: 6px;
        }
      }
      .user-current-progress {
        margin: 19px 0 0 0;
        .account-progress-info {
          .level-container {
            margin: 0 0 14px;
          }
          .total-balance {
            &__other-methods {
              margin: 12px 0 0;
              font-size: 13px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
