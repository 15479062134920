.footer-info {
  display: flex;
  flex-basis: 50%;
  @include maxWidth($M) {
    padding-bottom: 30px;
  }

  &__license-wrap {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    > div {
      margin-bottom: 10px;
    }
  }
  &__text-wrap {
    h4 {
      color: $primary50;
      margin-bottom: 6px;
    }
    span,
    p {
      color: $default;
      font-size: 13px;
      line-height: 18px;
      margin-top: 20px;
    }
  }
  .read-more-state {
    display: none;
  }
  .read-more-dots {
    display: none;
  }
  .read-more-trigger {
    display: none;
    color: $primary;
    cursor: pointer;
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .license-badge {
    width: 64px;
  }
}

@include maxWidth($S) {
  .footer-info {
    .read-more-dots {
      display: inline;
    }
    .read-more-trigger {
      display: inline;
    }
    .read-more-target {
      font-size: 0;
      max-height: 0;
      opacity: 0;
      transition: 250ms ease;
    }
    input.read-more-state:checked {
      & ~ .read-more-target {
        font-size: 13px;
        line-height: 18px;
        opacity: 1;
        max-height: 999em;
      }
      & ~ .read-more-trigger,
      & ~ .read-more-dots {
        display: none;
      }
    }
    .license-badge {
      width: 50px;
    }
  }
}
