.cryptopay-btc {

  &__list-head {
    font-size: 13px;
    color: $grey;
    margin: 15px 0 10px 0;
  }

  &__list-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__list-item {
    display: flex;
    font-size: 13px;
    align-items: center;
    margin-bottom: 7px;
  }

  &__list-item-title {
    margin-left: 8px;
    margin-right: 8px;
  }

}