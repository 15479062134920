@include modal-custom("verify-phone-modal") {
  .modal {
    &-content {
      .form {
        &__resend-code-timer {
          display: block;
          color: $secondaryLight;
          font-weight: 500;
          font-size: 15px;
          margin: 11px 0 0;
          position: relative;
          width: 100%;
          text-align: center;
          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 40%;
            height: 1px;
            background: $secondaryLight20;
            top: 9px;
          }
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
        &__content {
          text-align: center;
          color: $secondaryLight;
          max-width: 325px;
          font-size: 15px;
          margin-bottom: 14px;
        }
        &--in-modal {
          .btn {
            width: 100%;
            &-primary {
              .btn__label {
                font-weight: bold;
              }
            }
            &__label {
              font-size: 15px;
            }
          }
          .text-link {
            margin-top: 10px;
          }
        }
      }
    }
    &-body {
      margin: 16px 0 10px;
    }
  }
}
