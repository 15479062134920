.tournament-table {
  position: relative;
  max-width: 441px;
  margin: 0 0 37px auto;
  flex-shrink: 0;

  &.wide {
    max-width: unset;
    margin: 0 auto;
    .tournament-table__header {
      background: $darkBackground;
      border-radius: 12px 12px 0 0;
      margin: 0;
    }
    .tournament-table__row {
      background: $secondary60;
      color: $default;
      border-radius: 0;
      height: 53px;
      &:last-child {
        border-radius: 0 0 12px 12px;
      }
      &:not(:last-child) {
        margin: 0 0 1px;
      }
      &:nth-child(1) {
        .tournament-table__place {
          &:after {
            background: url("../../../images/elements/1st-place.svg") no-repeat center;
            display: block;
          }
        }
      }
      &:nth-child(2) {
        .tournament-table__place {
          &:after {
            background: url("../../../images/elements/2nd-place.svg") no-repeat center;
            display: block;
          }
        }
      }
      &:nth-child(3) {
        .tournament-table__place {
          &:after {
            background: url("../../../images/elements/3rd-place.svg") no-repeat center;
            display: block;
          }
        }
      }
    }
    .tournament-table__place {
      $cellHeight: 26px;
      flex-basis: unset;
      min-height: $cellHeight;
      width: $cellHeight;
      line-height: $cellHeight;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: $cellHeight;
        height: $cellHeight;
        display: none;
      }
    }
    .tournament-table__player {
      flex-basis: 15%;
    }
    .tournament-table__gamePlayed, .tournament-table__prize {
      flex-basis: 35%;
    }
    .tournament-table__points {
      flex-basis: 10%;
    }
  }

  &__header, &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 5px 17px 6px 14px;
    height: 32px;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    border-radius: 12px;
    &:not(:last-child) {
      margin: 0 0 2px;
    }
  }
  &__header {
    background: $secondary;
    color: $secondaryLight;
    text-transform: capitalize;
  }
  &__row-wrap {
    overflow-y: scroll;
    overflow-y: overlay;
    border-radius: 0 0 12px 12px;
  }
  &__row {
    background: $secondary30;
    color: $default90;
  }
  &__place {
    position: relative;
    flex-basis: 10%;
    text-align: center;
  }
  &__player {
    flex-basis: 20%;
    text-align: center;
    overflow: hidden;
  }
  &__gamePlayed {
    flex-basis: 30%;
    text-align: center;
    overflow: hidden;
  }
  &__points {
    flex-basis: 20%;
    text-align: center;
    overflow: hidden;
  }
  &__prize {
    flex-basis: 50%;
    text-align: end;
    overflow: hidden;
  }

  &__bottom-shading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    height: 69px;
    width: 100%;
    background: $tournamentTableGradient;
    z-index: 2;
    border-radius: 0 0 12px 12px;
  }

  &__show-more-btn {
    position: relative;
    width: 150px;
    height: 39px;
    color: $primary;
    font-size: 15px;
    line-height: 39px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    &:after {
      position: absolute;
      display: block;
      top: 15px;
      right: 18px;
      content: "";
      background: url("../../../images/icons/yellow-arrow-down.svg") no-repeat center;
      width: 8px;
      height: 12px;
    }
  }
}


@include maxWidth($M) {
  .tournament-table {
    max-width: unset;
    margin: 0 0 26px;
    &.wide {
      .tournament-table__player {
        flex-basis: 15%;
      }
      .tournament-table__gamePlayed {
        display: none;
      }
      .tournament-table__points {
        flex-basis: 15%;
      }
      .tournament-table__prize {
        flex-basis: 50%;
      }
    }
  }
}

@include maxWidth($S) {
  .tournament-table {
    margin: 0 0 23px;
    &.wide {
      .tournament-table__header, .tournament-table__row {
        padding: 5px 10px 5px 6px;
      }
      .tournament-table__place {
        margin-right: 7px;
      }
      .tournament-table__player {
        display: block;
        flex-basis: 22%;
        margin-right: 5px;
      }
      .tournament-table__points {
        display: block;
        flex-basis: 22%;
      };
      .tournament-table__prize {
        flex-basis: 46%;
      }
    }
    &__points, &__player {
      display: none;
    }
    &__prize {
      flex-basis: 90%;
    }
  }
}
