.euro2020-page {
  overflow: hidden;
}

.euro2020-header {
  position: relative;
  display: flex;
  background-color: $tournamentBannerBg;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-bottom: -140px;

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 800px;
    width: 50%;
  }

  &__body-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
    margin-bottom: 70px;
  }

  &__logo {
    margin-bottom: 8px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 90px;
    line-height: 1.2;
    color: $default;
    transform: translateX(-3px);
  }

  &__desc {
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    color: rgba($default, 0.9);
  }

  &__buttons {
    margin-bottom: 40px;

    .btn {
      margin-bottom: 10px;
      margin-right: 24px;
    }

    .btn-lg {
      padding: 20px 40px;
    }

    .btn-outline {
      border-color: $default;

      .btn__label {
        color: $default;
      }
    }
  }

  @include maxWidth($M) {
    display: flex;
    flex-direction: column;
    padding: 40px 0 50px;
    margin-bottom: -50px;
    background-size: auto 100%;
    background-position: 20% center;

    &__body {
      width: 100%;
      height: auto;
    }

    &__body-inner {
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 0;
    }

    &__logo {
      height: 85px;
      width: auto;
    }

    &__title {
      font-size: 54px;
      line-height: 65px;
      text-align: center;
    }

    &__desc {
      font-size: 15px;
      line-height: 21px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .btn {
        margin: 0 10px 10px;
      }

      .btn-lg {
        padding: 15px 22px;
      }
    }
  }
}

.euro2020-how-to-join,
.euro2020-leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  background: $default;
  border-radius: 96px;
  max-width: 1170px;
  margin: 0 auto 64px;

  &__title {
    max-width: 500px;
    margin-bottom: 48px;
    font-weight: 800;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    color: $secondary;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @include maxWidth($M) {
    padding: 48px 0;
    border-radius: 40px;

    &__title {
      margin-bottom: 40px;
      max-width: 300px;
      font-size: 24px;
      line-height: 29px;
    }

    &__body {
      padding: 0 20px;
    }
  }
}

.euro2020-how-to-join {
  background-image: url("../../images/euro2020/how-to-join-bg.svg");
  background-position: 10% 60%;
  background-repeat: no-repeat;
}

.euro2020-how-to-join-list {
  margin: 0;
  padding: 0;

  @include maxWidth($M) {
  }
}

.euro2020-how-to-join-list-item {
  position: relative;
  list-style: none;
  margin-bottom: 60px;
  padding: 0 0 0 152px;

  &__icon {
    position: absolute;
    left: 0;
    width: 132px;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: $darkBlue;
  }

  &__desc {
    max-width: 480px;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  @include maxWidth($M) {
    margin-bottom: 24px;
    padding: 0 0 0 80px;

    &__icon {
      width: 64px;
    }

    &__title {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.euro2020-leaderboard {
  position: relative;
  padding: 0;
  overflow: hidden;

  &__title {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 36px;
    background-color: $default;
    border-radius: 27px;
    color: $primaryActive;
    z-index: 1;
  }

  @include maxWidth($M) {
    margin-bottom: 20px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

    &__title {
      top: 40px;
    }

    &__body {
      padding: 0;
    }
  }
}

.euro2020-leaderboard-table {
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;

  &__header,
  &__item {
    display: flex;
    align-items: center;
    padding: 13px 20px 12px;
  }

  &__header-place,
  &__item-place {
    width: 15%;
  }

  &__header-username,
  &__item-username {
    width: 30%;
  }

  &__header-points,
  &__item-points {
    width: 20%;
  }

  &__header-username-points,
  &__item-username-points {
    width: 50%;
    display: none;
  }

  &__header-prize,
  &__item-prize {
    width: 35%;
    font-weight: 600;
    text-align: right;
  }

  &__header {
  }

  &__item {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    border-bottom: 1px solid $grey50;
  }

  &__item-place {
    padding-left: 20px;
  }

  &__show-more {
    display: flex;
    justify-content: center;
    padding: 32px 0 0;
  }

  @include maxWidth($M) {
    margin-bottom: 24px;

    &__header {
      font-size: 13px;
      line-height: 21px;
    }

    &__item {
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 13px;
      line-height: 21px;
    }

    &__header-place,
    &__item-place {
      width: 20%;
    }

    &__header-username-points,
    &__item-username-points {
      width: 30%;
      display: none;
    }

    &__header-prize,
    &__item-prize {
      width: 50%;
      text-align: right;
    }

    &__header-points,
    &__item-points,
    &__header-username,
    &__item-username {
      display: none;
    }

    &__header-username-points,
    &__item-username-points {
      display: block;
    }

    &__item-place {
      padding-left: 10px;
    }

    &__show-more {
      padding-top: 24px;
    }
  }
}

.euro2020-leaderboard-table-stone {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  padding-bottom: calc(769 / 1170 * 100%);
  background-image: url("../../images/euro2020/leaderboard-stone-bg-desktop.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 500px) {
    padding-bottom: calc(651 / 353 * 100%);
    background-image: url("../../images/euro2020/leaderboard-stone-bg-mobile.svg");
  }
}

.euro2020-leaderboard-table-stone-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:nth-child(1) {
    width: 21%;
    bottom: 30%;
    right: calc(50% - 21% / 2);
  }

  &:nth-child(2) {
    width: 21%;
    bottom: 17%;
    left: 16%;
  }

  &:nth-child(3) {
    width: 21%;
    bottom: 10%;
    right: 16%;
  }

  &:nth-child(1)::before {
    content: "";
    width: 34px;
    height: 34px;
    background-image: url("../../images/euro2020/leaderboard-stone-crown.svg");
    background-repeat: no-repeat;
  }

  &__pic {
    margin-bottom: 10px;
  }

  &__username {
    margin-bottom: 60px;
    padding: 8px 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
    background-color: $default;
    border-radius: 40px;
  }

  &:nth-child(1) &__username {
    margin-bottom: 40px;
  }

  &__prize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $darkBlue;

    &::before {
      content: "";
      display: block;
      margin-bottom: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &:nth-child(1) &__prize::before {
    width: 64px;
    height: 64px;
    background-image: url("../../images/euro2020/leaderboard-stone-star-1.svg");
  }

  &:nth-child(2) &__prize::before {
    width: 48px;
    height: 48px;
    background-image: url("../../images/euro2020/leaderboard-stone-star-2.svg");
  }

  &:nth-child(3) &__prize::before {
    width: 48px;
    height: 48px;
    background-image: url("../../images/euro2020/leaderboard-stone-star-3.svg");
  }

  &__points {
    font-size: 13px;
    color: $darkBlue;
  }

  @include maxWidth($M) {
    &:nth-child(2) {
      bottom: 38%;
    }

    &:nth-child(2) {
      bottom: 24%;
      left: 6%;
    }

    &:nth-child(3) {
      bottom: 14%;
      right: 6%;
    }

    &__pic {
      width: 80px;
      height: auto;
    }

    &__username {
      padding: 6px 8px;
      font-size: 13px;
      line-height: 16px;
    }

    &:nth-child(1) &__username {
      margin-bottom: 60px;
    }

    &__prize {
      font-size: 13px;
      line-height: 16px;
    }

    &:nth-child(1) &__prize::before {
      width: 44px;
      height: 44px;
    }

    &:nth-child(2) &__prize::before {
      width: 32px;
      height: 32px;
    }

    &:nth-child(3) &__prize::before {
      width: 32px;
      height: 32px;
    }

    &__points {
      font-size: 11px;
      line-height: 1.3;
    }
  }
}
