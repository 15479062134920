.game-mode {
  display: flex;

  &__item {
    position: relative;
    height: 100%;
    min-width: 130px;
    line-height: 26px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      display: none;
      height: 3px;
      border-radius: 4px;
      background: $primaryBgGradientHover;
    }

    &:hover {
      color: $primary;
    }

    &--active {
      color: $primary;

      &:after {
        display: block;
      }
    }
  }

  @include maxWidth($M) {
    flex-direction: column;
    height: auto;
    padding: 20px 16px;
    background: $darkBackground;
    box-shadow: $secondaryShadow;
    border-radius: 17px;

    &__item {
      width: 100%;
      margin-bottom: 6px;
      color: $secondaryLight;
      border: 1px solid $secondaryLight;
      border-radius: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &--support {
        border: none;

        .btn__icon {
          margin-right: 8px;
        }
      }

      &--active {
        color: $default;
        border-color: $actionColor;
      }
    }
  }
}
