.icon-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 40px;

  &__game-providers {
    margin-bottom: 40px;
  }
  &__payment-providers {
    opacity: 0.86;
  }
}
