.promo-item {
  position: relative;
  transition: 200ms;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $default;
    border-radius: 17px;
    box-shadow: $boxShadowWhiteCards;
  }

  &__grid-buffer {
    display: none;
    width: 100%;
    z-index: 0;
  }

  &__wrap {
    border-radius: 17px;
    position: relative;
  }

  &-img-block {
    position: relative;
    height: auto;
    width: 100%;
    padding: 32px 32px 0;
    background: transparent;
    border-radius: 17px 17px 0 0;
    .promo-item__img-container {
      overflow: hidden;
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
    }
    .promo-item__img {
      height: 100%;
      width: 100%;
      display: grid;
      border-radius: 17px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 20px 32px 10px;
    border-radius: 0 0 17px 17px;
    background: transparent;

    * {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
    margin: 0 0 16px;
    * {
      font-size: 18px;
    }
  }

  &__teaser {
    margin: 0 0 30px 0;
    color: $darkBlue;
    font-size: 15px;
    line-height: 21px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    * {
      font-size: 15px;
      line-height: 21px;
      font-style: normal;
      font-weight: normal;
      text-align: left !important;
    }
  }

  &__btn {
    margin: auto 0 0 0;
    opacity: 0;
    width: 100%;
    justify-self: end;
    height: 0;
    border: none;
    padding: 0;
    .btn__label {
      font-weight: bold;
    }
  }

  @include minWidth($M + 1) {
    &:hover {
      .promo-item__background {
        height: calc(100% + 64px);
        z-index: 1;
        transition: 200ms;
        transform: scale(1.05);
        box-shadow: $boxShadowBonusCardFocused;
      }
      .promo-item__grid-buffer {
        display: block;
      }
      .promo-item__wrap {
        z-index: 2;
        position: absolute;
        top: 0;
      }
      .promo-item__btn {
        transition: 200ms;
        opacity: 1;
        height: auto;
        padding: 12px 19px;
        border: 1px solid transparent;
      }
      .promo-item__text {
        padding-bottom: 30px;
        transition: 200ms;
      }
    }
  }

  @include maxWidth($M) {
    overflow: hidden;
    &-img-block {
      padding: 16px 16px 0;
      .promo-item {
        &__img {
          width: 100%;
          height: 230px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    &__title {
      margin: 0 0 12px;
    }
    &__text {
      padding: 14px 16px 14px;
    }
    &__teaser {
      margin: 0 0 18px;
      font-size: 13px;
      line-height: 19px;
    }
    &__btn {
      opacity: 1;
      height: auto;
      border: 1px solid transparent;
      padding: inherit;
    }
  }
}
