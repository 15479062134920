.install-app-panel {
  display: flex;
  align-items: center;
  height: 0;
  padding: 0 28px;
  background: $mainBgGradient;
  color: $default;
  transition: height 0.5s ease-in;
  overflow: hidden;

  &--visible {
    height: 55px;
  }

  &__btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    background: $teal;
    border-color: $teal;

    &-group {
      display: flex;
    }
  }

  &__hide-icon {
    height: 15px;
    width: 15px;
    cursor: pointer;
    path {
      fill: $darkBlue;
    }
    &:hover {
      path {
        fill: $dark100;
      }
    }
  }

  &__content-wrap {
    margin: 0 48px 0 0;
    display: flex;
    flex-shrink: 0;
  }

  &__icon {
    margin: 0 15px 0 0;
  }

  &__text-wrap {
   display: flex;
  }

  &__title {
    position: relative;
    padding: 0 25px 0 0;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;

    &:after {
      position: absolute;
      right: 11.5px;
      content: "";
      height: 22px;
      width: 0;
      border-left: 2px dotted rgba($default, 0.4);
    }
  }

  &__text {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
  }

  @include maxWidth($M) {
    padding: 0 6px;
    flex-direction: column;

    &--visible {
      height: 94px;
    }

    &__content-wrap {
      margin: 10px 0 2px 10px;
      align-self: flex-start;
    }

    &__text-wrap {
      flex-direction: column;
    }

    &__title {
      padding: 0;
      &:after {
        display: none;
      }
    }

    &__btn-group {
      align-self: flex-end;
      justify-content: flex-end;
    }

    &__text {
      font-size: 11px;
      line-height: 14px;
      &-wrap {
        margin-right: 0;
      }
    }

    &__hide-btn {
      margin: 0 5px 0 0;
      padding: 0 10px;
      order: -1;
    }
  }
}
