.description-modal {
  position: relative;
  display: flex;
  max-height: 600px;

  &__title {
    display: none;
    margin: 0 30px 25px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
  }

  &__img-wrap {
    max-width: 365px;
    flex-shrink: 0;
  }

  &__content-wrap {
    overflow: auto;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 17px;
  }

  &__text {
    padding: 0 32px;
    overflow: auto;
    margin-right: 4px;

    &::-webkit-scrollbar-thumb {
      border-top: 3px transparent solid;
      border-bottom: 3px transparent solid;
      background-clip: padding-box;
    }

    h3 {
      color: $darkBlue;
      font-size: 26px;
      line-height: 31px;
      font-weight: bold;
      margin: 0 0 16px;
    }

    a {
      color: unset;
      text-decoration: unset;
    }

    ol {
      max-width: 350px;
      counter-reset: list-counter;
      list-style: none;
    }

    ul {
      list-style-type: none;
    }

    ol li {
      margin: 0 0 0.5rem 0;
      counter-increment: list-counter;
      position: relative;

      &:before {
        content: counter(list-counter);
        color: $default;
        position: absolute;
        left: -38px;
        line-height: 25px;
        width: 25px;
        height: 25px;
        border: 1px solid #646d83;
        border-radius: 50%;
        text-align: center;
        font-size: 13px;
      }
    }

    table {
      width: 100%;
      margin: 20px 0;
      border-collapse: collapse;

      &,
      th,
      td {
        border: 1px solid rgba($secondaryLight, 0.3);
      }

      th {
        font-weight: bold;
      }

      td {
        padding: 5px 6px;
      }
    }
  }

  &__btn-desktop {
    margin: 32px auto 0;
  }
  &__btn-mobile {
    display: none;
  }

  @include maxWidth($L) {
    &__text {
      padding: 20px 20px 0;
    }

    &__btn {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @include maxWidth($M) {
    max-height: unset;
    flex-direction: column;
    &__title {
      display: block;
    }

    &__content-wrap {
      flex-shrink: 0;
      overflow: unset;
    }

    &__text {
      padding: 0;
    }
    &__btn-mobile {
      padding: 15px 0;
      background: $whiteFadeGradient;
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      display: block;
    }
    &__btn-desktop {
      display: none;
    }
  }
}

.old-site-info-modal {
  .modal__content {
    width: clamp(320px, 95%, 420px)
  }
  .modal-content {
    padding: 48px 0 40px;
    border-radius: 17px;
  }
  .modal-body {
    margin: 0;
  }
  .description-modal {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    &__title {
      margin: 0 0 16px;
      display: block;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      color: $secondary;
    }
    &__text {
      margin: 0 0 24px;
      padding: 0;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
      white-space: pre-wrap;
    }
    &__img-wrap {
      width: 100%;
      max-width: 339px;
      order: 2;
      align-self: center;
    }
    &__btn-mobile {
      display: none;
    }
    &__btn-desktop {
      display: block;
      margin: 24px auto 0;
    }
  }

  @include maxWidth($S) {
    .modal-content {
      padding: 36px 0 32px;
      border-radius: 17px;
    }
    .description-modal {
      &__title {
        margin: 0 0 12px;
        font-size: 18px;
        line-height: 24px;
      }
      &__btn-desktop {
        display: block;
      }
      &__btn-mobile {
        display: none;
      }
    }
  }
}
