.date-of-birth-select {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6px;
  .dropdown {
    flex: 1.2 1;
    &__menu {
      padding: 5px;
      z-index: 3;
    }
    &:first-child {
      flex: 1.3;
    }
    &:nth-child(2) {
      flex: 1.5;
    }
    &__single-value {
      justify-content: flex-start;
    }
  }
}