@mixin userInfoSidebar {
  display: flex;
  flex-direction: column;
  width: inherit;
  position: relative;
  padding: 0;
  border: none;
  border-radius: 0 17px 0 0;
  transform: translate(0, 0);
  height: calc(100% - 20px);
  margin-top: 20px;
  top: 0;
  box-shadow: none;
  .account-info {
    padding: 20px 20px 24px;
    width: 100%;
  }
  .navigation-block {
    padding: 24px 20px 20px 20px;
    background: $mainBgGradient;
    border-radius: 17px 17px 0 0;
    flex-grow: 1;
    width: 100%;
    .nav-bar.navigation-block {
      &__links {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        border: none;
        padding: 0;
        width: 100%;

        .nav-item {
          height: 85px;
          margin: 0 0 10px;
          width: calc(50% - 5px);
          &.nav-item--with-description {
            margin: 0 !important;
            width: 100%;
          }
          &:nth-child(even) {
            margin-right: 10px;
          }
        }
      }
    }
    .btn {
      margin: 14px 0 0;
      height: 44px;
      color: $default;
      border-color: $default;
      &__label {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
}
@mixin navItemSVG {
  svg:first-child {
    path {
      fill: $teal2;
    }
    rect {
      stroke: $teal2;
    }
  }
  &:nth-child(-n + 2) {
    svg:first-child path {
      fill: none;
      stroke: $teal2;
    }
  }
  &--link_active,
  &:hover {
    &:first-child,
    &:nth-child(2) {
      svg:first-child {
        fill: none;
        path {
          fill: none;
          stroke: $primary;
        }
      }
    }

    svg:first-child path {
      fill: $primary50;
    }

    &:last-child {
      svg {
        rect {
          stroke: $primary;
        }
        path {
          fill: $primary;
        }
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  &#user-info--as-sidebar {
    display: block;
    width: 320px;
    height: inherit;
    @include maxWidth($M) {
      display: none;
    }
    .dropdown-wrapper__dropdown-info {
      @include userInfoSidebar;
    }
  }
  &#user-info--as-dropdown {
    .header-additional {
      margin-left: 10px;
      margin-right: 0;
    }
    .dropdown-wrapper {
      &__close {
        transform: none;
        top: 12px;
        right: 12px;
      }
      &__dropdown-info {
        .account-info {
          width: 100%;
          padding: 12px 14px 10px;
          .btn-deposit {
            display: none;
          }
          &__balance {
            margin: 0 0 17px;
            position: relative;
            &:after {
              @include dividerDottedLine(bottom);
              content: '';
              position: absolute;
              bottom: -17px;
              width: 239px;
              height: 0;
            }
          }
        }
        .navigation-block {
          width: 100%;
          &__links {
            position: relative;
            margin: 0 0 10px;
            &:after {
              @include dividerDottedLine(bottom);
              content: '';
              position: absolute;
              bottom: -8px;
              left: 14px;
              width: 239px;
              height: 0;
            }
            .nav-item {
              margin: 2px 0;
              flex-direction: row;
              justify-content: flex-start;
              padding: 11px 19px 12px;
              width: 100%;
              border: none;
              background: $default;
              &--with-description {
                flex-wrap: wrap;
                .nav-item__title {
                  flex: 0 0 calc(100% - 40px);
                  letter-spacing: -0.2px;
                  text-align: left;
                }
                .nav-item__description {
                  margin: 4px 0 0;
                  padding: 0 0 0 40px;
                  width: 100%;
                  text-align: left;
                  font-size: 13px;
                  line-height: 17px;
                  color: $grey;
                }
              }
              &:last-child {
                margin: 2px 0 4px;
              }

              svg:first-child {
                width: 21px;
                height: 21px;
                margin: 0 19px 0 0;
              }
              &:hover {
                background: $secondary10;
                &:nth-child(2){
                  svg:first-child {
                    path {
                      fill: none;
                      stroke: $teal2;
                    }
                  }
                }
                svg:first-child {
                  path {
                    fill: $teal2;
                  }
                  rect {
                    stroke: $teal2;
                  }
                }
              }
              &--link_active {
                background: $default;
                &:nth-child(2){
                  svg:first-child {
                    path {
                      stroke: $teal2;
                    }
                  }
                }
              }
            }
          }
          .btn {
            height: 44px;
            justify-content: flex-start;
            &__icon {
              margin: 0 16px 0 0;
            }
            &__label {
              color: $darkBlue;
              font-weight: 600;
              font-size: 15px;
            }
            &:hover {
              background: $secondary10;
            }
          }
        }

        @include maxWidth($M) {
          @include userInfoSidebar;
          position: fixed;
          transform: translateY(0);
          align-items: center;
          height: unset;
          border-radius: $border-radius $border-radius 0 0;
          margin: 0;
          padding: 38px 0 0;
          box-shadow: $boxShadowCardWithImage;
          overflow-y: auto;
          top: unset;
          bottom: 0;
          max-height: 100vh;

          .account-info {
            padding: 0 20px 20px;
            &__balance {
              margin: 0 0 20px;
              &:after {
                display: none;
              }
            }
            .btn-deposit {
              height: 44px;
              display: inline-block;
            }
          }
          .navigation-block {
            padding-bottom: 62px;
            flex-grow: 0;
            &__links {
              &:after {
                display: none;
              }
              .nav-item {
                padding: 10px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                &.nav-item--with-description {
                  position: relative;
                  flex-direction: row;
                  justify-content: flex-end;
                  text-align: left;
                  svg:first-child {
                    position: absolute;
                    margin: 0;
                    left: 16px;
                    top: 28.5px;
                  }
                  .nav-item__title, .nav-item__description {
                    flex-grow: 1;
                    padding: 0 0 0 50px;
                  }
                }
                svg:first-child {
                  width: 28px;
                  height: 28px;
                  margin: 0 0 9px;
                }
              }
            }
            .btn {
              justify-content: center;
              &:hover {
                background: transparent;
              }
              &__label {
                color: $default;
              }
            }
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    &__dropdown-info {
      .account-info {
        &__balance {
          margin-bottom: 20px;
          &-block {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            line-height: 21px;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &-text {
              color: $grey;
            }
            &-value {
              color: $primaryActive;
              font-weight: bold;
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
      }
      &__progress-bar {
        margin: 10px auto 12px;
        @include maxWidth($M) {
          text-align: center;
        }
      }
      .btn-deposit {
        width: 100%;
        text-align: center;
        display: inline-block;
        margin: 0;
        font-weight: bold;
        font-size: 15px;
      }
    }
    @include maxWidth($L) {
      margin: 0 12px 0 0;
    }
  }
  .dropdown-wrapper-additional {
    @include maxWidth($L) {
      margin: 0;
    }
  }

  .active-session {
    position: relative;
    margin-right: 32px;

    &__value {
      color: $grey50;
    }

    &:after {
      content: "";
      top: -2px;
      right: -12px;
      height: 28px;
      border-left: 1px solid $secondary30;
      position: absolute;
    }
  }

  .user-balance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $default;
    margin: 0 6px 0 0;

    &__label {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: $secondary;
      text-align: right;
    }
    &__value {
      display: block;
      color: $primaryActive;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: right;
    }
    &__icon {
      margin: 0 20px 0 0;
    }
    @include maxWidth($M) {
      &__label {
        font-size: 11px;
        line-height: 13px;
      }
      &__value {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .btn-deposit {
    display: inline-flex;
    align-items: center;
    height: fit-content;
    padding: 13px 20px;
    min-width: 97px;
    font-size: 15px;
    line-height: 18px;
    border: none;

    svg {
      position: relative;
      left: -5px;
    }

    @include maxWidth($S) {
      padding: 10px 12px;
      min-width: unset;
      font-size: 13px;
      line-height: 16px;
      svg {
        display: none;
      }
    }
  }
}

.user-navigation-mobile {
  .navigation-block {
    position: relative;
    &__links {
      background: $default;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      margin: 0;
      align-items: unset;
      text-align: center;
      &:after {
        position: relative;
        display: block;
        content: "";
        right: 0;
        min-width: 5px;
        height: auto;
      }
      &::-webkit-scrollbar {
        height: 0;
      }
      .nav-item {
        @include navItemSVG;
        height: 36px;
        flex-direction: row;
        border: unset;
        border-radius: 12px;
        padding: 9.5px 14px;
        margin: 4px 5px 10px;
        background:  rgba($secondary10, 0.5);
        &:first-child {
          display: none;
        }
        svg:first-child {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        &__title {
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          color: $darkBlue;
        }
        &__arrow {
          display: none;
        }
        &--link_active {
          background: transparent;
          position: relative;
          .nav-item__title {
            color: $primary;
          }
          &:after {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 5px;
            top: 41px;
            bottom: unset;
            right: unset;
            margin: unset;
            left: 50%;
            transform: rotate(45deg);
            position: absolute;
            background: $greyEC;
          }
        }
        &__description {
          display: none;
        }
      }
    }
  }
}

.user-info,
.user-profile-balance-container {
  .navigation-block {
    .nav-bar.navigation-block {
      &__links {
        display: block;
        margin: 0;
        flex-direction: unset;
        align-items: unset;
        text-align: center;

        .nav-item {
          @include navItemSVG;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: $lightTeal;
          padding: 10px;
          margin: 10px 0;
          font-size: 15px;
          border-radius: 12px;
          text-decoration: unset;
          border: 1px solid transparent;
          position: unset;
          font-weight: unset;
          text-decoration: none;
          text-align: unset;
          line-height: unset;
          min-height: unset;
          text-decoration: unset;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__title {
            color: $darkBlue;
            font-weight: 600;
            font-size: 15px;
            line-height: 21px;
          }

          &__arrow {
            display: none;
            position: relative;
            margin-left: auto;
            margin-right: 0;
            transform: rotate(270deg);
            path {
              fill: $teal2;
            }
          }

          svg {
            margin-bottom: 9px;
          }

          svg:first-child {
            height: 28px;
            width: 28px;
          }

          &--link_active,
          &:hover {
            background: $default;
            border: 1px solid $primary50;

            .nav-item__title {
              color: $darkBlue;
            }

            svg.nav-item__arrow path {
              fill: $primary;
            }

            &:after {
              content: unset;
            }
          }

          &:hover {
            border: none;
          }

          &--default {
            height: unset;
            width: unset;
          }

          @include maxWidth($M) {
            border-radius: 0;
            margin: 0;
            &:first-child {
              display: none; // PROJ-4611
              border-radius: 12px;
              margin-bottom: 20px;
            }
            &:nth-child(2) {
              border-radius: 12px 12px 0 0;
            }
            &:last-child {
              border-radius: 0 0 12px 12px;
            }
          }
          @include minWidth($M) {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }

    .btn-logout {
      background: transparent;
      margin: 15px;
      width: calc(100% - 30px);
      font-size: 15px;
      @include maxWidth($M) {
        width: 100%;
        margin: 10px 0 0 0;
        font-size: 13px;
      }
    }
  }
}

