@include modal-custom("sign-up-modal") {
  .modal__content {
    display: flex;
    max-width: 803px;
  }

  .modal {
    &-banner {
      position: static;
    }
    &__banner {
      position: relative;
      overflow: hidden;
      border-radius: 17px 0 0 17px;
      width: 380px;

      &--bottom {
        display: none;
      }
      &--left {
        order: -1;
      }
      &--right {
        order: 1;
      }
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 423px;
    padding: 40px 0;
    border-radius: 0 17px 17px 0;
  }

  .modal-body {
    margin: 20px auto 35px;
    max-width: 440px;
    width: 100%;
  }

  .modal-after-footer {
    margin-top: auto;
    min-height: 124px;
    padding-top: 62px;
    border-radius: 0;
  }

  @include maxWidth($M) {
    @include mobile-modal-fullscreen;
    .modal__banner {
      order: -1;
      overflow: initial;
      min-height: 147px;
      width: 100%;
    }

    .modal-content {
      width: 100%;
      padding: 16px 0 20px;
      border-radius: 0;
    }

    .modal-body {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
