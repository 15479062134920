.section-header-wrap {
  position: relative;
  width: 100%;
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px; height: 29px;
  margin: 26px 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: $secondaryLight;
  border: 2px solid $secondary60;
  border-radius: 12.5px;
  &:after, &:before {
    position: absolute;
    top: 14px;
    left: 121px;
    content: '';
    display: block;
    width: calc(100% - 121px);
    height: 0;
    border: 1px solid rgba($secondary60, 0.5);
    border-right: none;
    border-left: none;
    z-index: 1;
  }
  &:before {
    display: none;
  }
}

@include maxWidth($S) {
  .section-header {
    margin: 20px auto;
    &:after {
      width: calc(50% - 60px);
      right: 0;
      left: unset;
    }
    &:before {
      position: absolute;
      left: 0;
      width: calc(50% - 60px);
      height: 0;
      display: block;
    }
  }
}
