.limits-info {
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 10px;
  &__value {
      color: $secondary;
      .label {
        color: $secondary;
        margin-right: 3px;
        text-transform: capitalize;
      }
  }
}