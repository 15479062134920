.sport-links {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__icon {
    display: flex;
    margin-right: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $secondary;
  }

  &__body {
    position: relative;
  }

  .slick-slide {
    padding-right: 8px;
  }

  .slick-slider {
    &:hover {
      .slick-arrow {
        opacity: 1;
        transition: opacity 200ms;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    @include bigSliderArrows();
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 200ms;
    top: 0px;
    width: 60px;
    height: 100%;
    bottom: 0;
    opacity: 0;

    &:focus {
      transform: none;
      background: none;
    }

    &:before {
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .slick-prev {
    left: -20px;
    border-radius: 0 $border-radius $border-radius 0;
  }

  .slick-next {
    right: -20px;
    border-radius: $border-radius 0 0 $border-radius;

    &:before {
      transform: rotate(180deg);
    }

    &:hover:before {
      transform: rotate(180deg) scale(1.1);
    }
  }
}
