.social-media-badge {
  display: flex;
  align-items: center;
  &:not(:last-child){
    margin: 0 16px 0 0;
  }
  img {
    width: 42px;
    height: 42px;
  }
}