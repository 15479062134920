@mixin datePickerSelectedStyle {
  color: $default;
  background: $primary50;
  border-radius: 12px;
}

.date-picker {
  &--disabled {
    .react-datepicker-wrapper {
      &:hover {
        .custom-input {
          &__wrap {
            border: 1px solid $grey50;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
  &:hover {
    .custom-input {
      &__wrap {
        border: 1px solid $secondary;
        box-shadow: 0 0 0 4px $boxShadowDropdownFocused;
        transition: 100ms border;
      }
    }
  }
}

.react-datepicker {
  background-color: $darkBlue;
  border: 1px solid $secondary60;
  border-radius: 10px;
  box-shadow: $secondaryShadow;

  &-popper {
    z-index: 5;
  }

  &__triangle {
    display: none;
  }

  &__header {
    background-color: transparent;
    border-bottom: 1px solid rgba($default, 0.07);
  }

  &__navigation {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0;
    background: url("../../../images/icons/arrow-lg-down.svg") no-repeat center;
    outline: none;

    &--previous {
      transform: rotate(90deg);
    }

    &--next {
      transform: rotate(-90deg);
    }
  }

  &__current-month {
    display: none;
  }

  &__month-select,
  &__year-select {
    appearance: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: $black;
    background-color: $transparentColor;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__day,
  &__day-name,
  &__time-name {
    width: 2.2rem;
    line-height: 2.2rem;
  }

  &__day-name {
    font-weight: 500;
    font-size: 13px;
    color: $grey50;
  }

  &__day {
    color: $default;

    &--outside-month {
      color: rgba($grey50, 0.5);
    }

    &--keyboard-selected {
      background: transparent;
      border-radius: 50%;
    }

    &:hover,
    &--selected,
    &--in-selecting-range,
    &--in-range {
      outline: none;
      @include datePickerSelectedStyle;
    }
  }
}
