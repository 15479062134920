@include modal-custom("process-modal") {
  .modal {
    &__overlay {
      z-index: 999;
    }
    &__content {
      @include maxWidth($L) {
        width: 100%;
        max-width: initial;
      }
      width: 850px;
    }
    &-content {
      @include maxWidth($S) {
        padding: 0;
      }
    }
    &-header {
      @include maxWidth($S) {
        padding: 0;
        height: 40px;
        .modal-title {
          text-align: left;
          padding-left: 5px;
          font-size: 22px;
          line-height: 40px;
        }
      }
    }
    &-body {
      height: calc(100vh - 200px);
      @include maxWidth($M) {
        padding: 0 10px;
      }
      @include maxWidth($S) {
        padding: 0;
        margin: 0;
        height: calc(100vh - 40px);
      }

      iframe {
        border: none;
      }
    }
  }
}
