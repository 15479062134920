.history-page {
  display: flex;
  flex-direction: column;

  &__filter {
    margin-bottom: 20px;
  }

  &__table {
  }

  &__loader {
    margin: 20px 0;
  }

  &__not-found {
    padding: 20px 0 10px;
    background-color: $default;
    border-radius: 0 0 $border-radius $border-radius;
  }

  @include maxWidth($M) {
    &__not-found {
      background-color: transparent;
    }
  }
}
