.withdrawal-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 27px 20px;
  background: $default;
  border-radius: 17px;
  text-align: center;
  border: 1px solid $grey50;
  width: 100%;

  &__title {
    margin: 0 0 16px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }
  &__sum {
    margin: 0 0 16px;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: $primary;
  }
  &__lowest-amount {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: $grey;
  }
}

@include maxWidth($S) {
  .withdrawal-info {
    width: 100%;
    max-width: unset;
    order: 1;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid rgba($grey50, 0.15);

    &__title {
      font-size: 15px;
    }
  }
}