.error-page {
  display: grid;
  height: 100vh;
  justify-items: center;
  grid-auto-rows: auto;
  align-content: center;
  z-index: 1;
  position: relative;
  color: $default;
  background: $errorPageGradient;

  @include maxWidth($M) {
    justify-content: center;
    grid-template-columns: 80%;
    text-align: center;
  }

  &__img {
    width: 100%;
    max-width: 998px;
    height: calc(100% - 50px);
    display: block;
    background: url(../../images/error-pages/ufo.png) no-repeat;
    background-size: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 20px auto 0;
    z-index: -1;

    @include maxWidth($M) {
      background: url(../../images/error-pages/ufo_mob.png) no-repeat;
      background-size: 100%;
      margin: 50px auto 0;
    }
  }

  &__status {
    font-size: 120px;
    font-weight: 800;
    @include maxWidth($M) {
      font-size: 80px;
    }

    &__text {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 10px;
      @include maxWidth($M) {
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__description {
      font-weight: normal;
      font-size: 15px;
      margin-bottom: 30px;
      color: $grey50;
    }
  }

}

.maintenance-page, .country-error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 50px;
  background: $errorPageGradient;
  text-align: center;

  &__img {
    margin: 0 0 33px;
  }
  &__title {
    margin: 0 0 8px;
    color: $default;
  }
  &__text {
    font-size: 15px;
    line-height: 21px;
    color: $grey50;
  }
  &__mail-to {
    text-decoration: none;
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }

  @include maxWidth($S) {
    padding: 15px;
    &__img {
      width: 203px;
      margin: 0 0 25px;
    }
    &__title {
      font-size: 24px;
      line-height: 29px;
    }
    &__text {
      font-size: 15px;
      line-height: 21px;
    }
  }
}