@mixin minWidth($val) {
  @media screen and (min-width: $val) { @content; }
}

@mixin maxWidth($val) {
  @media (max-width: $val) { @content; }
}

@mixin rangeWidth($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) { @content; }
}

@mixin maxHeight($val) {
  @media screen and (max-height: $val) { @content; }
}

@mixin retinaDisplayLandscape {
  @media
    only screen and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (orientation:landscape) and (min-resolution: 120dpi) { @content; }
}