.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    margin-top: -10px;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    color: $secondary;
    text-align: center;
  }

  &__desc {
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
    text-align: center;
  }
}