.casino-filter {
  position: relative;

  &__panel {

  }

  &__providers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: 104px;
    animation: 0.2s ease-in-out show-providers-desktop;
  }

  @include maxWidth($M) {
    
    &__providers {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      animation: 0.2s ease-in-out show-providers-mobile;
      z-index: 9999;
    }
  }
}

@keyframes show-providers-desktop {

  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-providers-mobile {

  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}