.personal-info-page {
}

.personal-info-form {
  display: flex;
  flex-direction: column;

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 20px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  @include maxWidth($M) {
    &__footer {
      flex-direction: column;
      margin-top: 20px;
    }

    &__submit,
    &__change-password {
      width: 100%;
      height: 44px;
    }

    &__submit {
      margin-bottom: 10px;
    }
  }
}
