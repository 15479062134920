$topSectionHeight: 572px;
$topSectionHeightMob: 564px;
$botSectionHeight: 588px;
$botSectionHeightMob: 544px;
$midSectionMobHeight: 376px;
$midSectionSideMargin: 20px;

.pwa-landing-page {
  position: relative;
  // margin-bottom: -100px;
  // z-index: 1;
}

.pwa-landing-top-section {
  position: relative;
  min-height: $topSectionHeight;
  margin: 0 auto;
  padding: 115px 98px;
  background-image: url("../../images/bg/landing_top_section_bg.jpg");
  background-repeat: no-repeat;
  background-position: center 10%;
  background-size: cover;

  &__title {
    max-width: 300px;
    margin: 0 0 16px;
    font-weight: 900;
    font-size: 54px;
    line-height: 1.16;
    color: $default;
    text-transform: uppercase;
  }

  &__desc {
    margin: 0 0 60px;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: $default;
  }

  .platform-tile {
    &__condition {
      color: rgba($default, 0.6);
    }
  }

  @include maxWidth($M) {
    padding: 45px;

    &__content {
      text-align: center;
    }
  }

  @include maxWidth($S) {
    min-height: $topSectionHeightMob;
    background-position: center;
    background-image: url("../../images/bg/landing_top_section_bg_mob.jpg");

    &__title {
      font-weight: 800;
      font-size: 32px;
      line-height: 37px;
    }

    &__desc {
      margin: 0 0 28px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.pwa-landing-advantages {
  position: absolute;
  top: calc(#{$topSectionHeight} - 46px);
  left: $midSectionSideMargin;
  right: $midSectionSideMargin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 78px;
  background: $default;
  border-radius: 20px;

  &__title {
    color: $secondary;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-transform: uppercase;
    max-width: 330px;
  }

  &__content-wrap {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 35px 0 0;
    }

    img {
      max-width: 100px;
      max-height: 100px;
      margin: 0 24px 0 0;
    }

    div {
      max-width: 132px;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #004152;
    }
  }

  @include maxWidth($XL) {
    top: calc(#{$topSectionHeight} - 110px);
    flex-direction: column;
    align-items: center;
    padding: 40px 16px;

    &__title {
      max-width: unset;
      margin: 0 0 30px;
    }
  }

  @include maxWidth($M) {
    position: absolute;
    top: $topSectionHeightMob;
    left: 0;
    padding: 40px 16px 26px;
    width: 100%;
    border-radius: 0;

    &__title {
      text-align: center;
      max-width: 300px;
      margin: 0 0 22px;
      font-size: 22px;
      line-height: 28px;
    }

    &__item {
      &:not(:last-child) {
        margin: 0 5px 0 0;
      }

      img {
        width: 64px;
        height: 64px;
        margin: 0 12px 0 0;
      }

      div {
        line-height: 20px;
      }
    }
  }

  @include maxWidth($S) {
    position: absolute;
    top: $topSectionHeightMob;
    left: 0;
    padding: 40px 16px 26px;
    width: 100%;
    border-radius: 0;
    height: $midSectionMobHeight;

    &__title {
      margin: 0 0 22px;
      font-size: 22px;
      line-height: 28px;
    }

    &__content-wrap {
      flex-direction: column;
    }

    &__item {
      &:not(:last-child) {
        margin: 0 0 20px;
      }

      img {
        width: 64px;
        height: 64px;
        margin: 0 12px 0 0;
      }

      div {
        line-height: 20px;
      }
    }
  }
}

.pwa-landing-bot-section {
  min-height: $botSectionHeight;
  max-width: 1366px;
  margin: 0 auto;
  padding: 270px 8% 140px;
  background-image: url("../../images/bg/landing_bot_section_bg.png");
  background-size: 511px auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 50px) 220px;

  &__title {
    margin: 0 0 32px;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.2;
    color: $secondary;
  }

  &__desc {
    display: block;
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #004152;
  }

  @include maxWidth($XL) {
    padding: 266px 114px 140px;
  }

  @include maxWidth($M) {
    text-align: center;
    padding-top: 295px;
    min-height: $botSectionHeightMob;

    &__title {
      margin: 0 0 20px;
    }

    &__buttons {
      margin-bottom: 20px;
    }
  }

  @include maxWidth($S) {
    margin-top: $midSectionMobHeight;
    min-height: $botSectionHeightMob;
    padding: 40px 24px;
    background-image: url("../../images/bg/landing_bot_section_bg.png");
    background-position: center calc(100% + 100px);

    &__title {
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 20px;
    }

    &__desc {
      letter-spacing: -0.2px;
    }
  }
}

.pwa-landing-buttons {
  &__btn {
    display: none;

    .btn {
      width: 230px;

      &__icon {
        margin: 0 10px 0 0;

        img {
          height: 24px;
        }
      }
    }
  }

  &__platforms {
    display: flex;
    margin-bottom: 10px;
  }

  &__platform-tile {
    margin-right: 20px;
  }

  @include maxWidth($M) {
    &__btn {
      display: block;
    }

    &__platforms {
      display: none;
    }
  }
}
