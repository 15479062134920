.cashbox-method-wrap {
  margin-top: 16px;
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 0 6px;
    @include maxWidth($M) {
      grid-template-columns: 1fr 3fr;
    }

    &__title {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: $darkBlue;
    }
    &__info {
      justify-self: end;
    }
  }

  .limits-info {
    justify-content: flex-end;
    font-size: 13px;
    &__value {
      &+.limits-info__value {
        margin-left: 5px;
      }
    }
    @include maxWidth($M) {
      font-size: 12px;
      gap: 2px;
      .label {
        margin-right: 1px;
      }
    }
  }
}
