.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "top-left top-right"
    "mid mid"
    "bot bot";
  margin: 0 auto;
  max-width: 1366px;
  padding: 20px 20px 25px;
  div.dropdown {
    grid-area: bot;
    width: 180px;
    justify-self: center;
    &__control {
      height: 40px;
      background: $default;
      box-shadow: none;
      border: 1px solid $default;
      &--is-focused, &--menu-is-open {
        border: 1px solid $secondary;
        background: $grey20;
        box-shadow: none;
      }
      &:hover {
        border: 1px solid $secondary;
      }
    }
    &__menu-list {
      max-height: 120px;
    }
    &__single-value {
      font-weight: bold;
    }
  }
  &__menu-wrap {
    grid-area: top-left;
    margin-bottom: 30px;
    display: flex;
    flex-basis: 50%;
    > div {
      &:not(:last-child) {
        margin: 0 70px 0 0;
      }
    }
  }
  .footer-info {
    grid-area: top-right;
  }
  &__icon-block-container {
    grid-area: mid;
    margin-top: 30px;
  }
  &__socialm-wrap {
    display: flex;
    align-items: center;
    grid-area: bot;
    justify-self: end;
    word-break: break-word;
  }

  &__pwa-button-container {
    display: flex;
    justify-content: center;
    grid-area: mid;
    margin-top: 30px;

    .platform-tile {
      &__platformName {
        font-size: 15px;
      }

      &__get-it {
        font-size: 10px;
      }

      &__inner-wrap {
        padding: 10px;
        min-width: 128px;

        img {
          max-height: 28px;
        }
      }
    }

    .pwa-landing-buttons {
      @include maxWidth($M) {
        &__btn {
          display: none;
        }

        &__platform-tile {
          &__desktop {
            display: none;
          }
        }

        &__platforms {
          display: flex;
        }
      }
    }
  }

  &__cryptopay {
    margin: 0 15px;
    padding: 6px 0;
  }

  @include maxWidth($M) {
    &__icon-block-container {
      grid-area: initial;
    }
  }

  .main-template--pwa-landing & {
    &__menu-wrap {
      display: none;
    }

    &__socialm-wrap {
      padding: 20px 0;
    }
  }
}

@include maxWidth(860px) {
  .footer-menu__wrap {
    > div {
      &:not(:last-child) {
        margin: 0 35px 0 0;
      }
    }
  }
  .footer-menu__list {
    column-count: 1 !important;
    max-height: unset;
  }
}

@include maxWidth(680px) {
  .footer {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "locale"
      "menus"
      "buttons"
      "social"
      "info";

    div.lang-select-container {
      grid-area: locale;
      margin-bottom: 24px;
    }
    &__menu-wrap {
      grid-area: menus;
      justify-self: center;
    }
    .footer-menu__list {
      max-height: inherit;
    }
    &__socialm-wrap {
      grid-area: social;
      justify-self: center;
      margin: 50px 0 0;
    }
    .footer-info {
      grid-area: info;
      margin-top: 30px;
    }
    .icon-block {
      display: none;
    }

    &__pwa-button-container {
      margin-top: 0;
      grid-area: buttons;
    }
  }
}

@include maxWidth($S) {
  .footer {
    padding: 20px 15px;
    .dropdown {
      width: 100%;
    }
    .footer-menu__list-item a {
      font-size: 13px;
    }
    &__socialm-wrap {
      position: relative;
      width: 100%;
      justify-content: center;
      &:before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        left: 15px;
        bottom: 1px;
        background-image: url("../../../images/icons/18plus.svg");
      }
    }
    .footer-info {
      &__text-wrap {
        h4 {
          display: none;
        }
      }
    }
  }
}

.footer-wrap {
  background: $secondary;
}
