.notification-block {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__icon-wrap {
    margin: 0 0 15px;
  }
  &__title {
    margin: 0 0 15px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $main;
    &--yellow {
      color: $primary;
    }
  }
  &__text {
    margin: 0 0 12px;
    text-align: center;
    color: $default;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    &--dim {
      color: $secondaryLight;
    }
  }
  &__description {
    margin: 0 0 12px;
    text-align: center;
    color: $secondaryLight;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
  }
  &__italic {
    text-align: center;
    font-style: italic;
    font-weight: normal;
    color: $secondaryLight;
    font-size: 13px;
    line-height: 127.5%;
  }
  &__btn {
    margin: 20px 0 0;
    min-width: 113px;
    .btn__label {
      font-weight: 600;
    }
  }
}