@include modal-custom("how2play-modal") {
  .modal {
    &-body {
      margin: 23px 0 0;
    }
    &-title {
      text-align: left;
    }
    &__content {
      max-width: 500px;
    }
    &-content {
      padding: 29px 30px 40px;
    }
  }

  @include maxWidth($M) {
    .modal-content {
      padding: 15px 15px 27px;
    }
  }
}
