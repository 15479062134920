$logoHeight: 46px;

.provider-icon {
  display: block;
  padding: 6px 0;
  margin: 0 10px;
  max-width: 100px;
  height: $logoHeight;
  img {
    max-width: 100px;
    height: 100%;
  }
}