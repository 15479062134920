.casino-link {
  width: 100%;
  height: 96px;
  border-radius: 17px;
  padding: 12px 25px 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  text-decoration: none;
  background-repeat: no-repeat;
  &:not(:last-child) {
    margin-right: 20px;
  }
  &--placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $secondaryBgGradient;
    border: 2px solid $secondaryLight50;
  }

  &__title {
    color: $default;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
  }
  &__text {
    margin: 3px 0 8px;
    color: $default;
    font-size: 13px;
    line-height: 17px;
  }
  &__accented-text {
    color: $primary;
    font-size: 15px;
    line-height: 21px;
    text-decoration: none;
  }
}
