@include modal-custom("pwa-qr-modal") {
  .pwa-qr-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__qr {
      margin-bottom: 20px;
    }

    &__footer-text {
      font-size: 15px;
      line-height: 21px;
      color: $secondaryLight;
      text-align: center;
    }
  }
}
