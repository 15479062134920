.cashbox-panel {
  &__title {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 12px;
  }
  &__methods {
    &--desk {
      display: grid;
      grid-template-columns: 350px 1fr;
      align-items: start;
    }
  }
}
