.game-card {
  &__inner {
    position: relative;
    overflow: hidden;
    border-radius: 17px;
  }

  &__bg {
    padding-bottom: calc(3 / 4 * 95%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__labels,
  &__buttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__labels,
  &__buttons {
    transition: opacity 0.1s ease-in;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
  }

  &__desc {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    color: $secondary;
  }

  &__title {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $darkBlue;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 3px;
  }

  &__status-label,
  &__hot-label,
  &__jackpot-label {
    display: inline-flex;
    position: relative;
    z-index: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      z-index: -1;
    }
  }

  &__status-label {
    padding: 3px 7px 4px 8px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-transform: lowercase;
    color: $default;

    &:before {
      background: $mainBgGradient;
      transform: skew(6deg);
    }
  }

  &__hot-label {
    justify-self: flex-end;
    padding: 2px 9px;

    &:before {
      background: $default;
      transform: skew(-6deg);
    }
  }

  &__jackpot-label {
    justify-self: flex-end;
    margin-left: auto;
    padding: 3px 7px 4px 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $default;

    &:before {
      background: $mainBgGradient;
      transform: skew(6deg);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__play-for-real {
    width: 72px;
    height: 72px;
    margin-bottom: 10px;

    .btn__icon {
      margin-left: 6px;
      svg {
        width: 26px;
        height: 33px;
      }
    }
  }

  &__play-for-fun {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 7px 20px;
  }

  &__name {
    margin-bottom: 10px;
    padding: 0 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $default;
  }

  &__favorite {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:not(&--touch):hover &__bg,
  &--active &__bg {
    //filter: blur(4px);
  }

  &:not(&--touch):hover &__labels,
  &--active &__labels,
  &__buttons {
    opacity: 0;
  }

  &:not(&--touch):hover &__buttons,
  &--active &__buttons {
    opacity: 1;
    background: $gameCardGradient;
  }

  &--touch:not(&--active) &__buttons {
    pointer-events: none;
  }

  &--small {
    .game-card {
      &__buttons {
        padding: 5px 0;
        justify-content: space-between;
      }
      &__play-for-real {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        margin: 0;
        .btn__icon {
          transform: scale(0.8);
          margin: 2px 0 0 5px;
        }
      }
      &__name {
        margin: 0;
        &:last-child {
          margin: 11px 0 auto;
        }
      }
      &__play-for-fun {
        margin: 0;
        padding: 5px 15px;
      }
    }
  }

  &--with-content {
    padding: 12px;
    background-color: $default;
    border-radius: 19px;
  }

  &--with-content &__name {
    display: none;
  }

  @include maxWidth($M) {
    &__bg {
      padding-bottom: calc(3 / 4 * 95%);
    }

    &__buttons {
      padding: 5px;
    }
    &__name {
      margin-bottom: 5px;
    }
    &__play-for-real {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      margin-bottom: 6px;

      .btn__icon {
        margin-left: 5px;
        svg {
          width: 20px;
          height: 28px;
        }
      }
    }

    &--small {
      .game-card__play-for-real {
        .btn__icon {
          transform: scale(1);
        }
      }
    }
  }

  @include maxWidth($S) {
    &__play-for-fun {
      padding: 3px 10px;
      margin-bottom: -2px;
      font-size: 12px;
    }
    &__jackpot-label {
      font-size: 11px;
    }
  }

  @include maxWidth($XS) {
    &__jackpot-label,
    &__status-label{
      padding: 2px 5px 3px 6px;
      font-size: 9px;
    }
  }
}
