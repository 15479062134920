.slider-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__label {
    color: $primary;
    // margin-right: 26px;
    position: relative;
    padding: 0 17px 0 0;
    text-decoration: none;
    .text-link {
      color: $darkBlue;
      font-size: 14px;
      line-height: 17px;
    }
    @include maxWidth(480px) {
      margin-right: 15px;
    }
    &:after {
      position: absolute;
      top: 4px;
      right: 0;
      display: block;
      content: "";
      width: 6px;
      height: 10px;
      background: url("../../../images/icons/more-vector.svg");
    }
  }
  &__button-wrap {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    @include maxWidth(480px) {
      display: none;
    }
  }
  &__button-prev,
  &__button-next {
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    background: url("../../../images/elements/slider-circled-arrow.svg")
      no-repeat center;
    &:hover,
    &:focus {
      outline: none;
      background: url("../../../images/elements/slider-circled-arrow-green.svg")
        no-repeat center;
    }
  }
  &__button-prev {
    transform: rotate(180deg);
    margin-right: 6px;
  }
}
