html, body, #root {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $fontPrimary;
  background: $grey20;
  color: $darkBlue;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
 margin: 0;
}
h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 53px;
}
h2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
}
h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
h5 {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
}
h6 {
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
}
p {
  font-size: 15px;
  font-weight: normal;
  line-height: 21px;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: $primaryBgGradient;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
