.circular-progress-bar {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: $transparentColor;
  z-index: 0;

  svg {
    transform: rotate(-90deg);
    position: relative;
  }
  &__inner-circle {
    stroke: $grey50;
  }
  &__outer-circle {
    stroke-linecap: round;
  }

  &__values {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: -0.3px;
    overflow: hidden;
  }
  &__current-value {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: $darkBlue;
  }
  &__total-value {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $grey;
    white-space: nowrap;
  }
}
