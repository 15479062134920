.promo-page {
  padding: 32px 0;

  &__title {
    margin: 30px 0 16px;
    font-weight: bold;
    font-size: 24px;
    color: $default;
    display: inline-block;
  }
  @include maxWidth($M) {
    margin: 0 15px;
    padding: 20px 0 0;
    &__title {
      margin: 20px 0 16px;
    }
  }
}