.casino-sidebar {
  background-image: linear-gradient(
    to top,
    rgba($default, 0) 0,
    $default 100px
  );
  height: 100%;

  &__categories {
    padding: 12px;

    .casino-categories-list {
      flex-direction: column;

      &__item {
        margin: 0;
        width: 100%;
        max-width: none;
      }
    }

    .casino-category-item {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 5px;
      padding: 0;

      &::after {
        content: none;
      }

      &__icon {
        width: 44px;
        height: 38px;
        margin-bottom: 0;
      }

      &__name {
        margin-left: 7px;
      }
    }
  }

  &__winners {
    padding: 10px 16px 10px 18px;

    .winners-item {
      position: relative;
      margin-bottom: 13px;
      &:after {
        position: absolute;
        left: 40px;
        bottom: -9px;
        content: "";
        width: calc(100% - 40px);
        height: 0;

        @include dividerDottedLine(top);
      }
      .lazy-img {
        width: 27px;
      }

      &__text {
        line-height: 19px;
      }
    }
  }
}
